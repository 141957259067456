import { Avatar, Typography, Button, Grid, TextField, CircularProgress } from "@material-ui/core";
import React from "react";
import { createTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Navbar from '../layout/Navbar.js';
import analytics from '../analytics/Analytics';
import cloudFunctionsFirebase from "../CloudFunctionsFirebase.jsx";
import firebase from 'firebase/app';

const db = firebase.firestore();
// if (window.location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }


const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'serif'
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      }
    },
  }
});

const useStyles = theme => ({
  paper: {
    outline: 0,
    width: '100%',
    marginTop: '100px',
    height: '600px',
    overflowY:'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
  },
  root: {
    outline: 0,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    borderRadius: '10px',
    paddingBottom: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#FFF",
  },
  largeAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    color: 'black',
    backgroundColor: 'pink',
    fontSize: 24,
  }
});


const withMediaQuery = () => Component => props => {
  const isTabletOrMobile = useMediaQuery('(max-width: 480px)');
  return <Component isMobile={isTabletOrMobile} {...props} />;
};


const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    borderRadius: "10px",
    boxShadow: "none",
    backgroundColor: "#9250FF",
    '&:hover': {
      backgroundColor: "#8473EE",
    }
  },
}))(Button);


class UserProfile extends React.Component {
  state = {};

  constructor(props) {
    super(props);
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
  }

  // handle authentication state changed
  onAuthStateChanged = (user) => {
    if (user) {
      this.setState({user});
      
      db.collection("users/").doc(user.uid).onSnapshot(async (doc) => {
        const userData = doc.data();
        if (userData.profilePic) {
          const profilePicUrl = await cloudFunctionsFirebase.getImageSignedUrl(userData.profilePic);
          userData.profilePicUrl = profilePicUrl.url;
        }

        this.setState({
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          userName: userData.userName,
          description: userData.description,
          profilePicUrl: userData.profilePicUrl, 
        });
      });
    }
    else {
      console.log("logged out");
    }
  };

  handleUploadClick = (event) => {
    if (event.target.files.empty) {
      return;
    }

    let file = event.target.files[0];

    const reader = new FileReader();
    this.setState({loadingNewProfilePic: true});
    reader.addEventListener("load", function () {
      // convert image file to base64 string
      cloudFunctionsFirebase.uploadProfilePhoto(reader.result).then(async (profilePic) => {
        console.log("profile photo uploaded!",  profilePic)
        const profilePicUrl = await cloudFunctionsFirebase.getImageSignedUrl(profilePic);
        this.setState({profilePicUrl: profilePicUrl.url, loadingNewProfilePic: false});
      });
    }.bind(this), false);

    reader.readAsDataURL(file);
  };

  render() {
    const { classes } = this.props;

    if (!this.state.email) {
      return null;
    }
    
    let avatar = !this.state.profilePicUrl
      ? <Avatar className={classes.largeAvatar} src={"/img/default_avatar.jpg"}/>
      : <Avatar className={classes.largeAvatar} src={this.state.profilePicUrl}/>;
  
    if (this.state.loadingNewProfilePic) {
      avatar = 
        <Avatar className={classes.largeAvatar}>
          <CircularProgress disableShrink />
        </Avatar>
    }
    
    const formWidth = this.props.isMobile ? "300px" : "400px"

    return(
      <ThemeProvider theme={theme}>
        <Navbar/>
        <form noValidate autoComplete="off" className={classes.paper}
          onSubmit={(e) => {
            e.preventDefault();
            const userData = {
              userId: this.state.user.uid,
            };

            console.log(this.state)
            if (this.state.newFirstName !== undefined) {
              userData.firstName = this.state.newFirstName;
            }

            if (this.state.newLastName !== undefined) {
              userData.lastName = this.state.newLastName;
            }

            if (this.state.newUserName !== undefined) {
              userData.userName = this.state.newUserName;
            }

            if (this.state.newDescription !== undefined) {
              userData.description = this.state.newDescription;
            }
            console.log("new userdata", userData)

            this.setState({saving: true});
            cloudFunctionsFirebase.updateUserData(userData, (result) => {
              if (result) {
                //create the user in analytics
                let userProfile = {
                  "$email": userData.email,
                  "$first_name": userData.firstName,
                  "$last_name": userData.lastName,
                  "USER_ID": userData.userId
                };

                analytics.identify(String(userData.userId));
                analytics.people.set(userProfile);
                analytics.track('Updating user data: webapp');

                console.debug('Updating user data');
              } else {
                console.debug('Failed to create user data');
              }
              this.setState({saving: false});
            });
          }}>
          <div style={{display: "flex", width: formWidth, paddingLeft: "10px", flexDirection: "column", justifyContent: "space-between"}}>
            <Grid container spacing={1}>
              <Grid item xs={12} key={"profile-photo-desc"}>
                <Typography gutterBottom variant="caption">Profile Photo</Typography>
              </Grid>
              <Grid item xs={3} key={"profile-photo"} style={{display: "flex", alignItems: "center", marginBottom: 20}}>
                {avatar}
              </Grid>
              <Grid item xs={9} key={"profile-change-button"} style={{display: "flex", alignItems: "center", marginBottom: 20, paddingLeft: 20}}>
                <input
                  hidden
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={this.handleUploadClick}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" component="span" style={{borderRadius: 10, boxShadow: "unset"}}>
                    Change
                  </Button>
                </label>
              </Grid>
              <Grid item xs={6} key={"first-name"}>
                <TextField style={{height: "100%"}}
                  id="first-name-input" variant="outlined" label="First name" margin="dense"
                  fullWidth
                  defaultValue={this.state.firstName}
                  onChange={(e) => {
                    this.setState({newFirstName: e.target.value});
                  }}/>
              </Grid>
              <Grid item xs={6} key={"last-name"}>
                <TextField style={{height: "100%"}}
                  id="last-name-input" variant="outlined" label="Last name" margin="dense"
                  fullWidth
                  defaultValue={this.state.lastName}
                  onChange={(e) => {
                    this.setState({newLastName: e.target.value});
                  }}/>
              </Grid>
              <Grid item xs={12} key={"email"}>
                <TextField style={{height: "100%"}}
                  id="email" variant="outlined" label="Email"
                  fullWidth margin="dense" disabled
                  defaultValue={this.state.user.email}
                />
              </Grid>
              <Grid item xs={12} key={"username"}>
                <TextField style={{height: "100%"}}
                  id="user-name" variant="outlined" label="Username"
                  fullWidth margin="dense"
                  error={Boolean(this.state.userNameError)}
                  helperText={this.state.userNameError}
                  defaultValue={this.state.userName}
                  onChange={(e) => {
                    const usersRef = db.collection('/users');
                    const newUserName = e.target.value;
                    this.setState({newUserName, checkingUserName: true});
                    usersRef.where("userName", "==", newUserName).get().then((usersSnapshot) => {
                      if (usersSnapshot.empty || newUserName === this.state.userName) {
                        this.setState({userNameError: null});
                      }
                      else {
                        this.setState({userNameError: "Username already in use, please choose a different one"});
                      }
                      this.setState({checkingUserName: false});
                    });
                  }}/>
              </Grid>
              <Grid item xs={12} key={"description"}>
                <TextField
                  id="description-input" variant="outlined" label="About me"
                  fullWidth margin="dense"
                  multiline
                  rows={7}
                  rowsMax={7}
                  helperText={"For example: from Austin, TX and in love with industrial decor..."}
                  defaultValue={this.state.description}
                  InputProps={{style: {alignItems: "flex-start"}}}
                  onChange={(e) => {
                    this.setState({newDescription: e.target.value});
                  }}/>
              </Grid>
            </Grid>
          </div>
          <div style={{display: "flex", width: "100%", marginTop: "20px", flexDirection: "row", justifyContent: "center"}}>
            <ColorButton
              type="submit"
              label="Update"
              variant="contained"
              style={{width: "200px"}}
              disabled={this.state.saving || this.state.checkingUserName || this.state.userNameError}
              color="primary">
              {this.state.saving ? <CircularProgress style={{width: "20px", height: "20px"}}/> : null}
              {this.state.saving ? "Saving" : "Save Changes"}
            </ColorButton>
          </div>
        </form>
      </ThemeProvider>
    )
  }
}

export default withStyles(useStyles)(withMediaQuery()(UserProfile));