/* global gapi */
import React from 'react';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';
import { Button, TextField, Snackbar, IconButton, Slide, Dialog, CircularProgress }  from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import '../index.sass';
import firebase from "../firebase/firebaseConfig";
import compose from "recompose/compose";
import { createTheme, withStyles } from "@material-ui/core/styles";
import { Redirect } from 'react-router-dom';
import cloudFunctions from "../CloudFunctions";
import { ThemeProvider } from "@material-ui/styles";
import analytics from '../analytics/Analytics';

function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
}
const theme = createTheme({
  overrides: {
    MuiButton: {
      contained: {
        width: '100%',
        margin: '10px 0',
      }
    },
    MuiFormControl: {
      marginDense: {
        marginBottom: 10
      }
    }
  },
  typography: {
    heading1: {
      fontSize: 5,
    }
  }
});

const useStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 2%',
    backgroundColor: "#F9F6F4",
    backgroundImage: "url(/img/backdropimg.jpeg)",
    minHeight: 'calc(100vh)',
  },
  overlay: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pageContainer: {
    width: "1200px",
    paddingTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  pageContainerMobile: {
    width: "100%",
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginContainer: {
    flex: 0,
    backgroundColor: "#fff",
    borderRadius: "20px",
    flexGrow: 1,
    maxWidth: 300,
    padding: '20px 40px 20px 40px'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  textButton: {
    '&:focus': {
      outline: 'none'
    },
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  image: {
    width: 'calc(75vw - 300px)',
    maxHeight: 'calc(100vh - 16px)',
  },
  title: {
    margin: '5px 0',
    fontSize: '2em',
    paddingTop: '10px',
  },
  titleMobile: {
    margin: '5px 0',
    fontSize: '2.0em'
  },
  subheading: {
    fontSize: '0.95em',
    paddingBottom: 5,
    paddingTop: 0,
    marginTop: 8
  }
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      error: '',
      currTab: 0,
      isSnackbarOpen: false,
      signinLoaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('google-loaded', () => {
      this.setState({signinLoaded: true})
    });
    
    analytics.track('Page view', {
      "path": "/login"
    });

    console.log(this.props.location)
    if (this.props.location.state) {
      this.renderGoogleLoginButton();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.signinLoaded) {
      this.renderGoogleLoginButton();
    }
  }

  renderGoogleLoginButton = () => {
    // googles api which renders the custom Google sign in button
    gapi.load('signin2', () => {
      gapi.signin2.render('g-signin2', {
        'scope': 'profile email',
        'width': 300,
        'height': 50,
        'longtitle': true,
        'onsuccess': this.onGoogleSignIn,
        'onfailure': this.onGoogleSignInFailure,
      });
    })
  }

  onGoogleSignIn = (googleUser) => {
    // We need to register an Observer on Firebase Auth to make sure auth is initialized.
    var unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
      unsubscribe();
      // Check if we are already signed-in Firebase with the correct user.
      if (!isUserEqual(googleUser, firebaseUser)) {
        // Build Firebase credential with the Google ID token.
        var credential = firebase.auth.GoogleAuthProvider.credential(
          googleUser.getAuthResponse().id_token);
        // Sign in with credential from the Google user.
        firebase.auth().signInWithCredential(credential).then(result => {
          this.setState({userId: result.user.uid});
          const userInfo = result.additionalUserInfo;
          
          // if user is new, sign them up instead
          if (userInfo.isNewUser) {
            this.setState({settingUserData: true});
            result.user.getIdToken().then((idToken) => {
              window.sessionStorage.token = idToken;

              const creationData = {
                userId: result.user.uid,
                email: userInfo.profile.email,
                firstName: userInfo.profile.given_name,
                lastName: userInfo.profile.family_name,
              };

              console.log("setting onboarding to open");
              window.localStorage.needOnboarding = true;
              window.localStorage.removeItem("sampleBoardId");
              window.localStorage.removeItem("sampleRoomType");
              window.localStorage.removeItem("sampleItem1");
              window.localStorage.removeItem("sampleItem2");
              this.setState({userId: result.user.uid});
              cloudFunctions.setUserCreationData(creationData, (result) => {
                if (result) {
                  //create the user in analytics
                  let userProfile = {
                    "$email": creationData.email,
                    "$first_name": creationData.firstName,
                    "$last_name": creationData.lastName,
                    "USER_ID": creationData.userId
                  };

                  analytics.alias(String(creationData.userId));
                  analytics.identify(String(creationData.userId));
                  analytics.people.set(userProfile);
                  analytics.register_once({"source": "webapp"});
                  analytics.track('Successful signup: web app');
                  console.debug('Created user data');
                  window.localStorage.needOnboarding = true;
                  window.localStorage.removeItem("sampleBoardId");
                  window.localStorage.removeItem("sampleRoomType");
                  window.localStorage.removeItem("sampleItem1");
                  window.localStorage.removeItem("sampleItem2");
                  this.setState({settingUserData: false});
                } else {
                  console.debug('Failed to create user data');
                }
              });
            });
          }
          else {
            analytics.identify(String(result.user.uid));
            analytics.register_once({"source": "webapp"});
            analytics.track('Successful login: web app');
          }
        }).catch((error) => {
          console.log("error signing user in through google", error);
        });
      }
    });

    // compares googleuser and firebase user to check if firebase info exists and matches
    function isUserEqual(googleUser, firebaseUser) {
      if (firebaseUser) {
        var providerData = firebaseUser.providerData;
        for (var i = 0; i < providerData.length; i++) {
          if (providerData[i].providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID &&
            providerData[i].uid === googleUser.getBasicProfile().getId()) {
            // We don't need to reauth the Firebase connection.
            return true;
          }
        }
      }
      return false;
    }
  }

  onGoogleSignInFailure(error) {
    console.debug("Error when attempting to sign in through Google", error);
  }

  handleChange = (e) => {
      this.setState({
        [e.target.id]: e.target.value
      });
    };

  handleLoginSubmit = (e) => {
    e.preventDefault();
    if (!this.state.email) {
      this.setState({error: "Enter a valid email address"});
      return;
    } else if(!this.state.password) {
      this.setState({error: "Enter a valid password"});
      return;
    }

    firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((user) => {
      this.setState({userId: user.uid});
      analytics.identify(String(user.uid));
      analytics.register_once({"source": "webapp"});
      analytics.track('Successful login: web app');
    }).catch((err) => {
      let error = "error logging in. Please try again";
      if (err.coce) {
        const code = err.code.split("/")[1];
        if (code === "user-not-found") {
          error = "This user does not exist. Please sign up instead"
        } else if (code === "wrong-password") {
          error = "Your password and username do not match"
        }
      }
      this.setState({error: error});
    });
  };

  handleSignUpSubmit = (e) => {
    e.preventDefault();
    const {email, password, firstName, lastName} = this.state;
    if (!email) {
      this.setState({error: "Enter a valid email address"});
      return;
    } else if (!firstName) {
      this.setState({error: "Enter a first name"});
      return;
    } else if (!lastName) {
      this.setState({error: "Enter a last name"});
      return;
    } else if (!password || password.length < 6) {
      this.setState({error: "Password must be at least 6 characters long"});
      return;
    } 
    // create user in firebase auth
    this.setState({settingUserData: true});
    firebase.auth().createUserWithEmailAndPassword(
      this.state.email,
      this.state.password
    ).then((userCredential) => {
      const { user } = userCredential;
      user.sendEmailVerification();
      // set idToken and set user data in firestore
      user.getIdToken().then((idToken) => {
        window.sessionStorage.token = idToken;
        const creationData = {
          userId: user.uid,
          email,
          firstName,
          lastName
        };

        console.log("setting onboarding to open");
        this.setState({userId: user.uid});
        cloudFunctions.setUserCreationData(creationData, (result) => {
          if (result) {
            //create the user in analytics
            let userProfile = {
              "$email": creationData.email,
              "$first_name": creationData.firstName,
              "$last_name": creationData.lastName,
              "USER_ID": creationData.userId
            };

            analytics.alias(String(creationData.userId));
            analytics.identify(String(creationData.userId));
            analytics.people.set(userProfile);
            analytics.register_once({"source": "webapp"});
            analytics.track('Successful signup: web app');
            console.debug('Created user data');
            window.localStorage.needOnboarding = true;
            window.localStorage.removeItem("sampleBoardId");
            window.localStorage.removeItem("sampleRoomType");
            window.localStorage.removeItem("sampleItem1");
            window.localStorage.removeItem("sampleItem2");
            this.setState({settingUserData: false});
          } else {
            console.debug('Failed to create user data');
          }
        });
      })
    }).catch((err) => {
      let error = err.message;
      const code = err.code.split("/")[1];
      if (code === "email-already-in-use") {
        error = "This account already exists. Please login instead"
      }
      this.setState({error: error});
    });
  };

  handleForgotPasswordSubmit = (e) => {
    e.preventDefault();

    if (!this.state.email) {
      this.setState({ error: 'Enter a valid email address' });
    }
    firebase.auth().sendPasswordResetEmail(this.state.email).then(() => {
      this.setState({isSnackbarOpen: true});
    }).catch((err) => {
      let error = '';
      const code = err.code.split("/")[1];
      if (code === "invalid-email") {
        error = "Enter a valid email Address"
      } else if (code === "user-not-found") {
        error = "There is no user with this email address"
      }
      this.setState({error: error});
    })
  };

  handleTabSwitch = (e, newVal) => {
    e.preventDefault();
    this.setState({
      currTab: newVal,
      error: '',
    });
  };

  handleSnackbarClose = (e) => {
    this.setState({isSnackbarOpen: false});
  };

  render () {
    const { classes, auth } = this.props;

    if (!auth.isEmpty) {
      if (this.state.settingUserData) {
        return (
          <Dialog fullScreen open={this.state.settingUserData}>
            <div className={classes.overlay}>
              <h2>Creating your profile...</h2>
              <CircularProgress color="primary" />
            </div>
          </Dialog>
        );
      }

      let redirectPath = "/";
      if (this.props.location.state) {
        redirectPath = this.props.location.state.loginRedirectPath;
      }

      return (
        <Redirect to={{pathname: redirectPath}}/>
      );
    }

    let form;
    switch (this.state.currTab) {
      case 0:
        form = (
          <div className={classes.formContainer}>
            <div id="g-signin2" />
            <span className="sign-in-or">or</span>
            <form onSubmit={this.handleLoginSubmit}>
              <TextField
                variant="outlined"
                autoFocus
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                value={this.state.email}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="dense"
                id="password"
                label="Password"
                type="password"
                fullWidth
                value={this.state.password}
                onChange={this.handleChange}
              />
              <button type="button" id="forgot-password" onClick={(e) => {
                this.handleTabSwitch(e, 2);
              }}>Forgot Password?</button>
              <Button type="submit" variant='contained' style={{width: "100%", height: "50px", boxShadow: "none", color: "#FFFFFF", backgroundColor: "#741CE5"}} >log in</Button>
              <button className={classes.textButton} onClick={(e) => this.handleTabSwitch(e, 1)}>
                <b>New to Dabble? Sign up now</b>
              </button>
            </form>
          </div>
      );
        break;
      case 1:
        form = (
          <div className={classes.formContainer}>
            <div id="g-signin2"/>
            <span className="sign-in-or">or</span>
            <form onSubmit={this.handleSignUpSubmit}>
              <TextField
                variant="outlined"
                autoFocus
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                value={this.state.email}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="dense"
                id="firstName"
                label="First Name"
                fullWidth
                value={this.state.firstName}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="dense"
                id="lastName"
                label="Last Name"
                fullWidth
                value={this.state.lastName}
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="dense"
                id="password"
                label="Password"
                type="password"
                fullWidth
                value={this.state.password}
                onChange={this.handleChange}
              />
              <Button variant="contained" style={{width: "100%", height: "50px", boxShadow: "none", color: "#FFFFFF", backgroundColor: "#741CE5"}} type="submit">Sign up</Button>
              <button className={classes.textButton} onClick={(e) => this.handleTabSwitch(e, 0)}>
                Already have an account? Login
              </button>
            </form>
          </div>);
        break;
      case 2:
        form = (
          <form onSubmit={this.handleForgotPasswordSubmit}>
            <h4>Get a link to recover your password</h4>
            <TextField
              variant="outlined"
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              onChange={this.handleChange}
            />
            <Button variant="contained" style={{width: "100%", height: "50px", boxShadow: "none", color: "#FFFFFF", backgroundColor: "#741CE5"}}  type="submit">Submit</Button>
            <Button variant="contained" style={{width: "100%", height: "50px", boxShadow: "none", color: "#FFFFFF", backgroundColor: "#888888"}} onClick={(e) => this.handleTabSwitch(e, 0)}>Cancel</Button>
          </form>
        );
        break;
      default:
        break;
    }


    return (
      <ThemeProvider theme={theme}>

        <MediaQuery minDeviceWidth={1224}>
          <div className={classes.root}>
            <div className={classes.pageContainer}>

              <div className={classes.loginContainer}>
                <div style={{textAlign: "center"}}>
                  <img src="/img/dabble_logo.svg" alt="desktop_logo" style={{width: "50px"}}/>
                  <h1 className={classes.title}>Welcome to Dabble</h1>
                  <p className={classes.subheading}> Visualize your room design ideas</p>
                </div>
                {this.state.error ? <h5 className="error">{this.state.error}</h5> : null}
                {form}
              </div>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
                open={this.state.isSnackbarOpen}
                autoHideDuration={5000}
                onClose={this.handleSnackbarClose}
                message="Password reset email sent!"
                TransitionComponent={SlideTransition}
                action={
                  <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              />
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1224}>
          <div className={classes.root}>
            <div className={classes.pageContainerMobile}>
              <div className={classes.loginContainer}>
                  <div style={{textAlign: "center"}}>
                    <img src="/img/dabble_logo.svg" alt="desktop_logo" style={{width: "50px"}}/>
                    <h1 className={classes.title}>Welcome to Dabble</h1>
                    <p className={classes.subheading}> Visualize your room design ideas</p>
                  </div>
                  {this.state.error ? <h5 className="error">{this.state.error}</h5> : null}
                  {form}
              </div>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
                open={this.state.isSnackbarOpen}
                autoHideDuration={5000}
                onClose={this.handleSnackbarClose}
                message="Password reset email sent!"
                TransitionComponent={SlideTransition}
                action={
                  <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackbarClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              />
            </div>
          </div>
        </MediaQuery>
      </ ThemeProvider>
  )};
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
};

export default compose(connect(mapStateToProps), withStyles(useStyles))(Login);
