import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cloudFunctionsFirebase from "../CloudFunctionsFirebase.jsx";
import { GridList, GridListTile, CircularProgress, Dialog,
  Typography, Tab, Tabs, Avatar } from "@material-ui/core";
import { isWidthUp } from "@material-ui/core/withWidth";
import { createTheme, useTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import '../index.sass';
import firebase from 'firebase/app';
import Navbar from "../layout/Navbar";
import analytics from '../analytics/Analytics';

const db = firebase.firestore();

const theme = createTheme({
  overrides: {
    MuiGridListTile: {
      tile: {
        borderRadius: '10px',
        cursor: 'pointer',
        boxSizing: 'border-box',
      }
    },
    MuiGridListTileBar: {
      root: {
        backgroundColor: 'rgb(0, 0, 0, 0)',
      }
    },
    MuiTab: {
      root: {
        minWidth: 0,
        '@media (min-width: 600px)': {
          minWidth: 0,
        },
      },
      wrapper: {
        textTransform: 'none',
      },
    },
  }
});

const useStyles = theme => ({
  gridLabelDiv: {
    display: 'inline-flex',
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "10px",
  },
  overlay: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  favButton: {
    outline: 0,
  },
  icon: {
    color: 'rgba(255, 255, 255, 1.0)',
  },
  styleImg: {
    width: "100%",
  },
  styleButton: {
    cursor: "pointer",
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    flexDirection: 'column',
    alignItems: 'top',
    margin: 'auto',
    justifyContent: 'center',
    borderRadius: 10,
    width: '50%',
    maxWidth: '700px',
    minWidth: '600px'
  },
  modalButtonBar: {
    outline: 0,
    backgroundColor: "#fff",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  onboardDialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '30px',
    paddingLeft: '0px',
    paddingRight: '0px',
    width: "100%"
  },
  snackBar: {
    backgroundColor: "#CDCDCD",
    color: "#000"
  },
  paper: {
    outline: 0,
    padding: theme.spacing(0, 4, 2),
    overflowY:'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: '2px solid slategrey'
    }
  },
  addItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "220px",
    background: "#DDD",
    borderRadius: "10px",
    boxSizing: "border-box",
  },
  addCollection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    boxSizing: "border-box",
    background: "#DDD",
    borderRadius: "10px",
  },
  root: {
    outline: 0,
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '10px',
    paddingBottom: '10px',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: "#FFF",
  },
  chromeExtButton: {
    width: "100%", 
    height: "50px", 
    boxShadow: "none", 
    color: "#FFFFFF", 
    marginTop: "30px",
    backgroundColor: "#741CE5",
    '&:hover': {
      backgroundColor: '#5412A9'
    }
  },
  imgContainer: {
    flex: '1',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  img: {
    margin: 'auto',
    display: 'block',
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  itemTile: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0px',
    borderRadius: '12px',
    backgroundColor: "#FFFFFF",
    border: '1px solid',
    borderColor: '#BBB9B9',
    boxSizing: 'border-box',
  },
  itemInfo: {
    display:'flex',
    justifyContent: 'space-between',
    padding: "10px",
    fontSize: '12px',
  },
  line: {
    borderBottom: '1px solid',
    borderColor: '#BBB9B9',
    paddingTop: '5px',
  },
  tab: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  largeAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    color: 'black',
    backgroundColor: 'pink',
    fontSize: 24,
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const withMediaQuery = () => Component => props => {
  const isTabletOrMobile = useMediaQuery('(max-width: 480px)');

  const width = useWidth();
  return <Component isMobile={isTabletOrMobile} width={width} {...props} />;
};

class UserPage extends React.Component {
  state = {
    overlayOpen: true,
    showProductPanel: false,
    showAddItemPanel: false,
    user: null,
    tabIndex: 0,
    snackBarOpen: false,
  };
  
  constructor(props) {
    super(props);
    this.boardAspectRatio = 0.67;
    this.gridRef = React.createRef();
    this.snackBarText = "";
  }

  componentDidMount() {
    this.initialize(this.props.match.params.userId);
  }

  getColGridListCols = () => {
    if (this.props.isMobile) {
      return 1;
    }

    if (isWidthUp('xl', this.props.width)) {
      return 4;
    }

    if (isWidthUp('lg', this.props.width)) {
      return 3;
    }

    if (isWidthUp('md', this.props.width)) {
      return 3;
    }

    if (isWidthUp('sm', this.props.width)) {
      return 2;
    }

    return 1;
  }

  getItemGridListCols = () => {
    if (this.props.isMobile) {
      return 2;
    }

    if (isWidthUp('xl', this.props.width)) {
      return 4;
    }

    if (isWidthUp('lg', this.props.width)) {
      return 4;
    }

    if (isWidthUp('md', this.props.width)) {
      return 4;
    }

    if (isWidthUp('sm', this.props.width)) {
      return 3;
    }

    return 2;
  }

  // handle authentication state changed
  initialize(userId) {
    this.setState({overlayOpen: false});
    db.collection("users/").doc(userId).onSnapshot(async (doc) => {
      const userData = doc.data();
      if (!userData) {
        return;
      }

      if (userData.profilePic) {
        userData.profilePicUrl = "https://us-central1-mixandmatch-287616.cloudfunctions.net/app/profilepic/" + userId;
      }

      this.setState({
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        userName: userData.userName,
        description: userData.description,
        profilePicUrl: userData.profilePicUrl, 
      });

      if (!userData.bookmarks) {
        return;
      }

      // Adding user info to analytics to fix users who signed up before March 3, when the analytics fix was pushed.
      const bookmarks = userData.bookmarks;
      Promise.all(Object.keys(bookmarks.items).map(async (itemId) => {
        let bmItem = db.collection('ecommercelink').doc(itemId);
        const ind = this.state.items? this.state.items.findIndex(item => item.Id === itemId) : -1;
        let newItem = null;
        if (ind < 0) {
          // new item found! Add that to the state
          newItem = (await bmItem.get()).data();
          if (!newItem) {
            console.log("item", itemId, " is no longer available");
            cloudFunctionsFirebase.removeBookmarkItemFirestore(itemId, () => {})
            return undefined;
          }
          newItem.Id = itemId;
          if (bookmarks.itemBookmarkDates && newItem.Id in bookmarks.itemBookmarkDates) {
            newItem.BookmarkedStamp = bookmarks.itemBookmarkDates[newItem.Id]; 
          }
          else {
            newItem.BookmarkedStamp = newItem.Created; 
          }

          if (newItem.Vendor) {
            const vendorDoc = await newItem.Vendor.get();
            const vendorName = vendorDoc.data();
            if (vendorName && vendorName.Name !== "unsupported")  {
              newItem.vendorName = vendorName.Name;
            }
            else {
              newItem.vendorName = " ";
            }
          }
          else {
            newItem.vendorName = " ";
          }
        } else {
          newItem = this.state.items[ind];
        }

        if (!newItem.imgUrl) {
          const imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`ecommercelink/${newItem.Id}/image1.png`);
          if (!imgUrl.url) { //there's no bg removed image
            if (newItem.origImgUrl) { //if the original image exists, set it to that.
              newItem.imgUrl = newItem.origImgUrl;
            }
            else { //no images available. show error image.    
              //Check imageprocessing status. Need to update from firebase because of weird firebase bug
              const updatedItemListingRef = db.collection('ecommercelink').doc(itemId);
              const updatedItemListing = (await updatedItemListingRef.get()).data();

              if (updatedItemListing.ImageProcComplete === true) { //image processing is complete but no images are available. show error. 
                newItem.imgUrl = '/img/noimg.jpg'
                console.error ("No saved images found for id: " + newItem.Id + ". Please investigate!")
              }
            }
          }
          else {
            newItem.imgUrl = imgUrl.url;
          }
        }
        
        return newItem;
      })).then((newItems) => {
        const filteredItems = newItems.filter(function (el) {
          return el !== undefined;
        });
        this.setState({items: filteredItems});
      });

      const colIds = Object.keys(bookmarks.collections);
      Promise.all(colIds.map(async (colId) => {
        const collection = {
          id: colId,
          bookmarkId: userData.bookmarks.moodboards[colId],
        };

        if (collection.bookmarkId) {
          let imgUrl;
          if (collection.bookmarkId.includes('jpg')) {
            imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`bookmarks/${collection.bookmarkId}`);
          }
          else {
            imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`bookmarks/${collection.bookmarkId}.png`);
          }
          collection.imgUrl = imgUrl.url;
        }

        return collection;
      })).then((collections) => {
        this.setState({collections});
      });
    });
  };

  handleProductPanelClose = (showsnackbar = false) => {
    analytics.track ("Product Panel Closed")

    if (showsnackbar) {
      this.snackBarText = "Product changes saved!"
      this.setState({showProductPanel : false, snackBarOpen: true });
    }
    else {
      this.setState({showProductPanel : false});
    }
  };

  handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackBarOpen: false
    })
  };

  render() {
    const { classes } = this.props;
    if (this.state.overlayOpen) {
      return (
        <Dialog fullScreen open={this.state.overlayOpen}>
          <div className={classes.overlay}>
            <img src="/img/dabble_logo.svg" height="40px" alt="desktop_logo" style={{objectFit: "contain", marginBottom: "20px"}}/>
            <CircularProgress color="inherit" />
          </div>
        </Dialog>
      );
    }

    if (!this.state.email) {
      return null;
    }

    const { collections, items } = this.state;

    let avatar;
    if (this.state.profilePicUrl) {
      avatar = <Avatar className={classes.largeAvatar} src={this.state.profilePicUrl}/>;
    }
    else if (this.state.email) {
      avatar = <Avatar className={classes.largeAvatar} src={'/img/default_avatar.jpg'}/>;
    }
    else {
      avatar = <Avatar className={classes.largeAvatar}>
        <CircularProgress disableShrink />
      </Avatar>;
    }

    let favColDoms = []
    if (!collections) {
      favColDoms.push(
        <GridListTile key="nofavitem" cols={5} style={{ height: 'auto' }}>
          <div className={classes.gridLabelDiv}>
            <Typography gutterBottom variant="body1">
              Loading...
            </Typography>
          </div>
        </GridListTile>
      );
    }
    else if (collections.length >= 0) {
      collections.sort(function (a, b) {
        return ('' + a.id).localeCompare(b.id);
      });

      collections.forEach((collection, index) => {
        favColDoms.push(
          <GridListTile key={collection.id} className={classes.favButton}
            onClick={() => {
              window.location.replace("/design/" + collection.id);
              analytics.track('Collection opened from home page', {
                "collection id": collection.id,
              });
            }}>
            { !collection.imgUrl ?
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <CircularProgress disableShrink />
              </div> :
              <img src={collection.imgUrl} loading="lazy" alt="collection img"/>}
          </GridListTile>
        );
      })
    }

    let favItemsDoms = [];
    if (items) {
      items.sort(function (a, b) {
        return b.BookmarkedStamp - a.BookmarkedStamp;
      });

      items.forEach((item, index) => {
        favItemsDoms.push(
          <GridListTile key={item.Id} className={classes.favButton}
            onClick={() => {
              this.setState({itemSelected: item, showProductPanel: true});
              analytics.track_item_info_click('home page tab', item.URL, item.vendorName, item.Depthtype)
            }}
            onMouseEnter={(e) => {
              this.container.style.cursor = 'pointer';
            }}
            onMouseLeave={(e) => {
              this.container.style.cursor = 'default';
            }}>
            <div className={classes.itemTile}>
              <div className={classes.imgContainer}>
                { !item.imgUrl ?
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                    <CircularProgress disableShrink />
                  </div> :
                  <img className={classes.img} src={item.imgUrl} alt={item.Title} loading="lazy"/>
                }
              </div>
              <div className={classes.line}/>
              <div className={classes.itemInfo}>
                <div>{item.vendorName}</div>
                <div style={{fontWeight: 'bold'}}>${item.Price}</div>
              </div>
            </div>
          </GridListTile>
        );
      })
    } else {
      favItemsDoms.push(
        <GridListTile key="nofavitem" cols={6} style={{ height: 'auto' }}>
          <div className={classes.gridLabelDiv}>
            <Typography gutterBottom variant="body1">
              Loading...
            </Typography>
          </div>
        </GridListTile>
      );
    }

    const collectionGridCol = this.getColGridListCols();
    const itemGridCol = this.getItemGridListCols();
    const colCellWidth = this.props.isMobile ? window.screen.width * 0.7 : window.screen.width * 0.7 / 4;
    const colGridWidth = colCellWidth * collectionGridCol; 
    const colGridCellHeight = colCellWidth * this.boardAspectRatio;

    const itemCellWidth = this.props.isMobile ? window.screen.width * 0.4 : window.screen.width * 0.7 / 6;
    const itemGridWidth = itemCellWidth * itemGridCol; 
    return (
      <ThemeProvider theme={theme}>
        <div id="container" ref={node => {
          this.container = node;
        }}>
          <Navbar/>
          <div style={{width: "80%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
            <Dialog fullScreen open={this.state.overlayOpen}>
              <div className={classes.overlay}>
                <img src="/img/dabble_logo.svg" height="40px" alt="desktop_logo" style={{objectFit: "contain", marginBottom: "20px"}}/>
                <CircularProgress color="inherit" />
              </div>
            </Dialog>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 20, height: 130}}>
              {avatar}
              {
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    { this.state.userName
                      ? <Typography variant="body2" style={{color: "#858585"}}>{"@" + this.state.userName}</Typography>
                      : <Typography variant="body2" style={{color: "#858585"}}>{this.state.firstName}</Typography>
                    }
                  </div>
                </div>
              }
            </div>
            <Tabs
              value={this.state.tabIndex}
              onChange={(event, newValue) => {
                this.setState({tabIndex: newValue});
              }}
              indicatorColor="primary"
              centered
            >
              <Tab label="Wishlist"/>
              <Tab label="Designs"/>
            </Tabs>
            <TabPanel value={this.state.tabIndex} index={1} className={classes.tab}>
              <GridList style={{width: colGridWidth, overflowY: "unset", marginTop: "30px"}}
                cellHeight={colGridCellHeight} cols={collectionGridCol} spacing={15}>
                {favColDoms}
              </GridList>
            </TabPanel>
            <TabPanel value={this.state.tabIndex} index={0} className={classes.tab}>
              <GridList style={{width: itemGridWidth, overflowY: "unset", marginTop: "30px"}}
                cellHeight={220} cols={itemGridCol} spacing={15}>
                {favItemsDoms}
              </GridList>
            </TabPanel>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default withStyles(useStyles)(withMediaQuery()(UserPage));
