// import action types from actions.js
import {
  ALTERNATIVE_OPEN,
  ALTERNATIVE_CLOSE
} from "./dashActions";

// set initial state
const initState = {
  isAlternativePanelOpen: false,
};

// reducer. based on action type dispatched, change stores state
const dashReducer = (state = initState, action) => {
  switch (action.type) {
    case ALTERNATIVE_OPEN:
      return {
        ...state,
        isAlternativePanelOpen: true,
      };
    case ALTERNATIVE_CLOSE:
      return {
        ...state,
        isAlternativePanelOpen: false,
      };
    default:
      return state;
  }
};

export default dashReducer;