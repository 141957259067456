import React from 'react';
import { Image, Rect, Group, Transformer } from 'react-konva';

class URLImage extends React.Component {
  state = {
    image: null,
    tfImageParams: {
      scaleX: 1,
      scaleY: 1,
    }
  };

  componentDidMount() {
    if (!this.props.image) {
      this.loadImage();
    } else {
      this.image = this.props.image;
      this.setImageScale();
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
    if (oldProps.warpFunction !== this.props.warpFunction) {
      this.setImageScale();
    }

    if (this.trRef && this.imageRef) {
      this.trRef.nodes([this.imageRef]);
      this.trRef.getLayer().batchDraw();
    }
  }

  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad);
  }

  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.crossOrigin = "Anonymous";
    this.image.src = this.props.src;
    this.image.addEventListener('load', this.handleLoad);
  }

  setImageScale() {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed

    let scale, scaleX, scaleY;
    let { image } = this;
    if (image) {
      if (this.props.warpFunction) {
        image = this.props.warpFunction(image)
      }

      if (image.width >= image.height) {
        scale = this.props.width / image.width;
      }
      else {
        scale = this.props.height / image.height;
      }

      // scaling the image differently if it's a rug because rug images
      // often have the wrong aspect ratio from the vendor.
      if (this.props.ignoreRatio) {
        scaleX = this.props.width  / image.width;
        scaleY = this.props.height / image.height;
      }
      else {
        scaleX = scale;
        scaleY = scale;
      }
    }

    this.setState({
      image,
      tfImageParams: {
        scaleX: scaleX / this.props.screenScale,
        scaleY: scaleY / this.props.screenScale,
      }
    });
  }

  handleLoad = async () => {
    await this.setImageScale();

    if (this.props.onImageLoaded) {
      this.props.onImageLoaded(this.image)
    }
  };

  onBoxUpdate = (oldBox, newBox) => {
    // limit resize
    if (newBox.width < 20 || newBox.height < 20) {
      return oldBox;
    }

    return newBox;
  }

  render() {
    // set the shadow values
    // If the item is selected and alternative panel is open, highlight yellow.
    // Else, highlight based on state (the state is updated as you mouse over the item)

    let {x, y, width, height, flip, draggable} = this.props;
    let imageRect = (this.state.image) ?
      <Group
        x={x}
        y={y}
        draggable={draggable}
        onDragStart={(e) => {
          if (this.props.onDragStart) {
            this.props.onDragStart(e);
          }
        }}
        onDragEnd={(e) => {
          if (this.props.onDragEnd) {
            this.props.onDragEnd(e);
          }
        }}
        width={width}
        height={height}>
        { this.props.itemSelected ?
          <Transformer
            ref={ref => {
              this.trRef = ref;
            }}
            rotateEnabled={false}
            centeredScaling={true}
            enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
            boundBoxFunc={this.onBoxUpdate}
          /> : null}
        { this.props.groupSelected &&
          <Rect stroke='#6D28FF' strokeWidth={2} x={0} y={0} width={width} height={height}/> }
        <Image
          x={flip ? width : 0}
          y={0}
          scaleX={this.state.tfImageParams.scaleX * this.props.screenScale * (flip ? -1 : 1)}
          scaleY={this.state.tfImageParams.scaleY * this.props.screenScale}
          image={this.state.image}
          ref={node => {
            this.imageRef = node;
            if (this.props.onRef) {
              this.props.onRef(node);
            }
          }}
          onDblClick={(e) => {
            this.props.onDblClick(e);
          }}
          onClick={(e) => {
            this.props.onClick(e);
          }}
          onContextMenu={(e) => {
            this.props.onContextMenu(e)
          }}
          onTransformEnd={(e) => {
            // transformer is changing scale of the node
            // and NOT its width or height
            // but in the store we have only width and height
            // to match the data better we will reset scale on transform end
            const node = this.imageRef;
            const scaleX = node.scaleX();
            const scaleY = Math.abs(node.scaleY());
  
            // we will reset it back
            node.x(0);
            node.y(0);
            node.scaleX(1);
            node.scaleY(1);
            node.rotation(0);
            this.setState({
              tfImageParams: {
                scaleX: scaleX / this.props.screenScale,
                scaleY: scaleY / this.props.screenScale,
              }
            });
            if (this.props.onImageScaled) {
              let scale = 1;
              if (this.state.image.width >= this.state.image.height) {
                scale = this.state.image.width / width;
                scale = scaleX * scale;
              }
              else {
                scale = this.state.image.height / height;
                scale = scaleY * scale;
              }
              this.props.onImageScaled(scale);
            }
          }}
        />
      </Group> :
      <Group
        x={x}
        y={y}
        width={width}
        height={height}
        draggable
        onDragStart={(e) => {
          if (this.props.onDragStart) {
            this.props.onDragStart(e);
          }
        }}
        onDragEnd={(e) => {
          if (this.props.onDragEnd) {
            this.props.onDragEnd(e);
          }
        }}>
        <Rect
          x={0}
          y={0}
          width={width}
          height={height}
          cornerRadius={10}
          fill="#000000"
          opacity={0.5}
          onMouseEnter={(e) => {
            if (this.props.onMouseEnter) {
              this.props.onMouseEnter(e);
            }
          }}
          onMouseLeave={(e) => {
            if (this.props.onMouseLeave) {
              this.props.onMouseLeave(e);
            }
          }}
          onDblClick={(e) => {
            this.props.onDblClick(e);
          }}
          onClick={(e) => {
            this.props.onClick(e);
          }}
        />
      </Group>;
    return (imageRect);
  }
};

export default URLImage;
