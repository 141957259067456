import React, { Component } from "react";
import { 
  ImageList, ImageListItem, CircularProgress, Button, Icon, IconButton, List,
  ListItem, ListItemText, ListItemSecondaryAction, Popover, Collapse,
  TextField, InputAdornment, Typography
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete'
import FilterListIcon from '@material-ui/icons/FilterList';
import { withStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import cloudFunctionsFirebase from "../CloudFunctionsFirebase.jsx";
import analytics from '../analytics/Analytics';
import Swal from 'sweetalert2';
import '../index.sass';
import algoliasearch from 'algoliasearch/lite';

// Set up algoliaClient with search-only API key.
const algoliaClient = algoliasearch('XFU4WVQDCK', '468ba34dc6b499d93b733f63f705e8f3');

/**
 * props expects: item object
 * **/
const useStyles = theme => ({
  imgContainer: {
    flex: '1',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
  img: {
    margin: 'auto',
    display: 'block',
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  formControl: {
    minWidth: 120,
  },
  grid: {
    borderRadius: '8px',
  },
  itemTile: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0px',
    borderRadius: '10px',
    backgroundColor: "#FFFFFF",
    border: '0px solid #DDDDDD',
    borderColor: '#BBB9B9',
    boxSizing: 'border-box',
  },
  itemInfo: {
    display:'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: "10px 0px 10px 10px",
    fontSize: '10px',
    color: "#808080",
    maxWidth: "60%",
  },
  vendorInfo: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '60%'
  },
  itemTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: "#000000"
  },
  line: {
    borderBottom: '0px solid',
    borderColor: '#FFFFFF',
    paddingTop: '0px',
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F0F0F0",
    opacity: "0.9",
    borderRadius: "8px"
  },
  searchBar: {
    marginLeft: '5px',
    marginRight: '10px',
    width: '100%',
    borderRadius: '5px',
    whiteSpace: 'nowrap',
    fontSize: '15px',
  },
  filterButton: {
    marginLeft: '5px',
    marginRight: '5px',
    width: '35%',
    height: '35px',
    borderRadius: '5px',
    whiteSpace: 'nowrap',
    fontSize: '15px',
  },
  root: {
    outline: 0,
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '10px',
    paddingBottom: '10px',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: "#FFF",
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtonBar: {
    outline: 0,
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 4, 2),
  },
  paper: {
    outline: 0,
    padding: theme.spacing(0, 4, 2),
    overflowY:'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: '2px solid slategrey'
    }
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

const HorizontalCollapse = withStyles((theme) => ({
  root: {
    width: 0,
    transitionProperty: "width",
  },
  entered: {
    width: "100%",
  },
  hidden: {
    width: 0,
  },
}))(Collapse);

const searchTheme = createTheme({
  palette: {
    primary: {
      main: '#741CE5',
    }
  },
});

const buttonTheme = createTheme({
  palette: {
    primary: {
      main: '#F5F5F5',
    },
    secondary: {
      main: '#741CE5'
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'serif'
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      }
    },
  }
});

class ExplorePanel extends Component {
  constructor(props) {
    super(props);

    // Define the algolia client
    const algoliaIndex = algoliaClient.initIndex('dabble');
    const queryIndex = algoliaClient.initIndex('dabble_query_suggestions');

    this.state = {
      showAddItemPanel: false,
      algoliaIndex: algoliaIndex,
      queryIndex: queryIndex,
      queryOptions: [],
      inputValue: "",
      value: "",
      allowScroll: true,
      initialized: false
    }

    const typeDict = {
      tableLamps: {name: "Table Lamps", type: "tablelamp"},
      floorLamps: {name: "Floor Lamps", type: "floorlamp"},
      rugs: {name: "Rugs", type: "rug"},
      arts: {name: "Art", type: "wallart"},
      pillows: {name: "Pillows", type: "pillow"},
      sofas: {name: "Sofas & Sectionals", type: "sofa"},
      chairs: {name: "Chairs", type: "chairfaceleft"},
      coffeeTables: {name: "Coffee Tables", type: "coffeetable"},
      sideTables: {name: "Side Tables", type: "sidetable"},
      nightstands: {name: "Nightstands", type: "nightstand"},
      sideboards: {name: "Consoles & Sideboards", type: "sideboard"},
      benches: {name: "Benches", type: "bench"},
      desks: {name: "Desks", type: "desk"},
      ottomans: {name: "Ottomans & Poufs", type: "ottoman"},
      beds: {name: "Beds", type: "bed"},
      wallpaper: {name: "Wallpaper", type: "wallpaper"},
      plants: {name: "Plants", type: "floorplant"},
    };

    this.state.typeDict = typeDict;
    this.gridRef = React.createRef();
    this.buttonRef = React.createRef();
    this.addPanelRef = React.createRef();
    this.searchRef = React.createRef();
  }

  componentDidMount() {
    if (this.state.queryOptions.length === 0) {
      // Set up the queryOptions
      const queryIndex = this.state.queryIndex;
      queryIndex.search('',
      {
        hitsPerPage: 1000
      })
      .then(({ hits }) => {
        this.setState({queryOptions: hits});
      });
    }

    if (this.props.products) {
      this.setState({
        initialized: true
      });
    }

    this.gridRef.current.addEventListener('scroll', this.handleScroll);
    this.setState({prevTriggerHeight: this.addPanelRef.current.scrollTop});
  }

  handleItemTypeChange = (event) => {
    const itemType = event.target.value;
    this.setState({itemType});
  }

  addExploreItem = (tile) => {
    if (!tile) {
      return;
    }

    const { classes, onItemGettingDragged } = this.props;
    const color = "0px none #000";
    return (
      <ImageListItem key={tile.Id} draggable="true" 
        onDragStart={(e) => {
          analytics.track("Explore tab item getting dragged", {"itemId": tile.Id});
          onItemGettingDragged(tile);
        }}

        style={{border: color, cursor: "pointer"}} onClick={(e) => {
          if (this.props.onAdditionClicked) {
            this.props.onAdditionClicked(tile, true);
          }
        }}>
        <div className={classes.itemTile}>
          <div className={classes.imgContainer}>
            { !tile.imgUrl ?
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <ThemeProvider theme={searchTheme}>
                  <CircularProgress disableShrink />
                </ThemeProvider>
              </div> :
              <img className={classes.img} src={tile.imgUrl} alt={tile.Title} loading="lazy"/>
            }
            { tile.Depthtype === "rug" ?
              <img style={{display: "none"}} src={tile.skewedImgUrl} alt={tile.Title} loading="lazy"/> : null
            }
          </div>
          <div className={classes.line}/>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div className={classes.itemInfo}>
              <div className={classes.vendorInfo}>{tile.vendorName}</div>
              <div className={classes.itemTitle}>{tile.Title}</div>
              <div style={{fontWeight: 'bold'}}>${tile.Price}</div>
            </div>
            <IconButton aria-label="favItem" onClick={(e) =>  {
              e.stopPropagation();
              if (!(tile.Id in this.props.userData.bookmarks.items)) {
                analytics.track("Added product to wishlist", {
                  'clicksource': 'moodboard explore tab panel',
                  "URL": tile.URL,
                  "item vendor": tile.vendorName,
                  "item type": tile.Depthtype
                })
                cloudFunctionsFirebase.bookmarkItemFirestore(tile.Id, (result) => {
                  if (!result) {
                    Swal.fire('Failed to bookmark item', '', 'error');
                  }
                })
              } else {
                analytics.track("Removed product from wishlist", {
                  'clicksource': 'moodboard explore tab panel',
                  "URL": tile.URL,
                  "item vendor": tile.vendorName,
                  "item type": tile.Depthtype
                })
                cloudFunctionsFirebase.removeBookmarkItemFirestore(tile.Id, (result) => {
                  if (!result) {
                    Swal.fire('Failed to remove bookmark item', '', 'error');
                  }
                })
              }
            }}>
              {
                tile.Id in this.props.userData.bookmarks.items 
                  ? <Icon style={{color: "#B77EFF"}}>favorite</Icon>
                  : <Icon>favorite_outlined</Icon>
              }
            </IconButton>
          </div>
        </div>
      </ImageListItem>
    );
  };

  handleClick = (event) => {
    this.setState({anchorEl: this.buttonRef.current});
  };

  handleClose = () => {

    this.setState({anchorEl: null});
  };

  // load more books (scroll)
  handleScroll = () => {
    if (this.state.allowScroll) {
      let triggerHeight = this.gridRef.current.scrollTop + this.gridRef.current.offsetHeight;
      if (triggerHeight >= this.gridRef.current.scrollHeight && triggerHeight >= this.state.prevTriggerHeight) {
        // trigger fetching of next page
        if (this.props.fetchNewPage) {
          const key = this.state.selectedFilterKey;
          const prodType = key ? this.state.typeDict[key].type : null; 
          this.props.fetchNewPage(prodType); 
        }
      }
  
      this.setState({prevTriggerHeight: triggerHeight});
    }
  }

  render() {
    const { classes, products } = this.props;

    let productDoms = [];

    if ( products ) {
      productDoms = products.map(this.addExploreItem);
    }

    const listItems = Object.keys(this.state.typeDict).map(key => {
      return (
        <ListItem key={key} button onClick={() => {
          if (this.props.onNewCurateType) {
            this.props.onNewCurateType(this.state.typeDict[key].type)
          }
          const newTypeDict = {...this.state.typeDict}; 
          newTypeDict[key].selected = true;
          if (this.state.selectedFilterKey) {
            delete newTypeDict[this.state.selectedFilterKey]['selected'];
          }
          this.setState({selectedFilterKey: key, typeDict: newTypeDict, inputValue: '', value: '', allowScroll: true});
          this.handleClose();
        }}>
          <ListItemText primary={this.state.typeDict[key].name} />
          {
            this.state.typeDict[key].selected ?
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="comments" onClick={(e) =>{
                if (this.props.onNewCurateType) {
                  this.props.onNewCurateType(null)
                }
                const newTypeDict = {...this.state.typeDict}; 
                if (this.state.selectedFilterKey) {
                  delete newTypeDict[this.state.selectedFilterKey]['selected'];
                }
                this.setState({
                  selectedFilterKey: null,
                  typeDict: newTypeDict,
                  prevTriggerHeight: this.addPanelRef.current.scrollTop,
                });
                this.handleClose();
              }}>
                <Icon>clear</Icon>
              </IconButton>
            </ListItemSecondaryAction> : null
          }
        </ListItem>
      );
    })

    return (
      <div id="alternatives" style={{height: this.props.height}}>
        <div className="alternativesctrlpanel"
          style={{marginBottom: "10px", display: 'flex', justifyContent: "center", alignItems: "center"}}>
          <ThemeProvider theme={searchTheme}>
            <Autocomplete
              options={this.state.queryOptions}
              className={classes.searchBar}
              ref={this.searchRef}
              freeSolo
              inputValue={this.state.inputValue}
              value={this.state.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search"
                  variant="outlined"
                  margin="dense"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>
                          <img alt="algolia-logo" src="/img/icons/algolia-logo.svg" style={{height: "100%"}}/>
                        </Icon>
                      </InputAdornment>
                    ),
                    style: {
                      height: 35,
                      top: "-2px",
                    },
                    classes: {
                      root: classes.searchBar
                    },
                    onFocus: (e) => {
                      if (typeof this.props.deselectItem === 'function') {
                        this.props.deselectItem()
                      }
                    },
                  }}
                  onChange={(e) => {
                    const input = e.target.value;
                    this.setState({inputValue: input})

                    const key = this.state.selectedFilterKey;
                    const type = key ? this.state.typeDict[key].type : null; 

                    // If input is empty, reset all the products
                    if (input === '') {
                      this.setState({allowScroll: true});
                      this.props.onNewCurateType(type)
                    }
                  }}
                  >
                </TextField>
              )}
            getOptionLabel={(option) => {
              return option.query ? option.query : "";
            }}
            onChange={(event, value, reason) => {
              let input = '';
              if (reason !== "clear") {
                input = value.query ? value.query : value;
              }
              this.setState({inputValue: input, value: input});

              const key = this.state.selectedFilterKey;
              const prodType = key ? this.state.typeDict[key].type : null;

              // Call algolia.
              if (input === '') {
                this.setState({allowScroll: true});
                this.props.onNewCurateType(prodType)
              } else {
                this.setState({allowScroll: false});
                
                const algoliaIndex = this.state.algoliaIndex;
                let filter = 'curated:true'

                algoliaIndex.search(input, 
                {
                  filters: filter,
                  hitsPerPage: 1000  
                })
                .then(({ hits }) => {
                  // Get the list of object IDs from the hits.
                  const ecommercelinkIDs = hits.map(hit => {
                    return hit.objectID;
                  });

                  // Retrieve these pages from Firebase.
                  this.props.onSearchResults(ecommercelinkIDs);

                  // Clear the filter button.
                  const newTypeDict = {...this.state.typeDict}; 
                  if (this.state.selectedFilterKey) {
                    delete newTypeDict[this.state.selectedFilterKey]['selected'];
                  }
                  this.setState({
                    selectedFilterKey: null,
                    typeDict: newTypeDict,
                    prevTriggerHeight: this.addPanelRef.current.scrollTop,
                  }); 
                })
              }
            }}>
            </Autocomplete>
          </ThemeProvider>
          <ThemeProvider theme={buttonTheme}>
            <Button variant="contained"
              color={!this.state.selectedFilterKey ? "primary" : "secondary"}
              className={classes.filterButton}
              ref={this.buttonRef}
              onClick={this.handleClick}
              startIcon=
                { !this.state.selectedFilterKey ?
                  <FilterListIcon color="secondary"/>
                  : 
                  <FilterListIcon color="primary"/>
                }>
              Filters
            </Button>
          </ThemeProvider>
          <Popover
            open={Boolean(this.state.anchorEl)}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            TransitionComponent={
              HorizontalCollapse
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            <List>
              {listItems}
            </List>
          </Popover>
        </div>
        <div id="explorepanel" ref={this.addPanelRef}
          style={{overflowY: "auto", height: "100%", display: 'flex', justifyContent: "center", flexWrap: "wrap"}}>
          { productDoms.length === 0 && !this.props.fetchingNewPage && this.state.initialized
            ? <div style={{width: "80%", height: "150px", display: "flex", flexDirection: "row",
                alignItems: "center", justifyContent: "space-around"}}>
                <Typography variant="subtitle2">
                  No products found <span role="img">☹</span>
                </Typography>
              </div>
            : null
          }
          <div ref={this.gridRef} style={{overflowY: 'scroll', height: "100%"}}>
            <ImageList className={classes.grid} rowHeight={160} cols={2} gap={10}
              style={{margin: 0, paddingLeft: 5, paddingRight: 5}}>
              {productDoms}
              { this.props.fetchingNewPage 
                ? <ImageListItem cols={2}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%"}}>
                      <ThemeProvider theme={searchTheme}>
                        <CircularProgress disableShrink />
                      </ThemeProvider>
                    </div>
                  </ImageListItem>
                : null
              }
            </ImageList>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(useStyles)(ExplorePanel);
