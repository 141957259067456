/**
 * @file common.jsx
 * Provides common utlity functions for Dev Portal.
 *
 * Most functions here are interfacing functions for use with Cloud Functions.
 * ajaxPostJson is used to make ajax request to Placenote Cloud Functions.
 */
import $ from 'jquery'

const common = {
    getAjaxHeaders: () => {
        const headers = {};
        if (window.sessionStorage.token) {
            headers.Authorization = `Bearer ${window.sessionStorage.token}`;
        }
        return headers;
    },

    ajaxPostJson: (url, data) => $.ajax({
        type: 'POST',
        url, headers: common.getAjaxHeaders(),
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(data),
        cached: false
    }),

    // showSnackbar: (message, actionText, actionHandler) => {
    //     $('#snackbar').get(0).MaterialSnackbar.showSnackbar({ message, actionText, actionHandler });
    // },

    addDatabaseSubscription: (ref, eventType, callback) => {
        ref.on(eventType, callback);
        return () => {
            ref.off(eventType, callback);
        };
    },

    findInDataSnapshot: (dataSnapshot, predicate) => {
        let result;
        dataSnapshot.forEach((childDs) => {
            if (predicate(childDs)) {
                result = childDs;
                return true;
            }
            return false;
        });
        return result;
    },

    humanFileSize: (bytes, si) => {
        const thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
            return `${bytes} B`;
        }
        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

        let u = -1;
        let human = bytes;
        do {
            human /= thresh;
            u += 1;
        } while (Math.abs(human) >= thresh && u < units.length - 1);
        return `${human.toFixed(1)} ${units[u]}`;
    },

    validateEmail: email => /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email),

    getWindowLocationSeachValue(key, remove) {
        const uri = new window.URI(window.location);
        const search = uri.search(true);
        const value = search[key];
        if (remove) {
            delete search[key];
            uri.search(search);
            window.history.replaceState(document.title, document.title, uri.toString());
        }
        return value;
    },

    setWindowLocationSeachValue(key, value) {
        const uri = new window.URI(window.location);
        const search = uri.search(true);
        search[key] = value;
        uri.search(search);
        window.history.replaceState(document.title, document.title, uri.toString());
    },
};

export default common;
window.vcmsCom = common;
