import React from 'react';
import { Stage, Layer, Rect, Transformer, Group, Image } from 'react-konva';
import GroundImageRect from "./GroundImageRect";
import WallPaperRect from "./WallPaperRect";
import URLImage from "./URLImage";
import Konva from 'konva';
import Portal from "./Portal"
import ContextMenu from './ContextMenu';
import { Homography } from "homography";

class MoodBoard extends React.Component {
  state = {
    selectedContextMenu: null,
    groupX: 0,
    groupY: 0,
    mouseDragGroup: {},
  };
  moodboardRef = React.createRef()

  handleOptionSelected = (option) => {
    console.log(option);
    this.setState({ selectedContextMenu: null });
  };

  handleContextMenu = (e) => {
    const mousePosition = e.target.getStage().getPointerPosition();
    this.setState({
      selectedContextMenu: {
        position: mousePosition,
        target: e.currentTarget
      }
    });
  }

  constructor(props) {
    super(props);
    this.imageRefs = {};
    this.itemImages = {};
  }

  componentDidUpdate(oldProps) {
    if (oldProps.itemGroupSelected !== this.props.itemGroupSelected) {
      const { itemGroupSelected } = this.props;
      if (!itemGroupSelected) {
        this.setState({groupX: 0, groupY: 0});
      }

      if (!this.trRef) {
        return;
      }


      const nodes = [];
      Object.keys(itemGroupSelected).forEach(key => {
        if (this.imageRefs[key]) {
          nodes.push(this.imageRefs[key]);
        }
      });

      this.trRef.nodes(nodes);
      this.trRef.getLayer().batchDraw();
    }
  }

  rugWarper = (image) => {
    const srcPts = [[0, 0], [0, 1], [1, 0], [1, 1]];
    let dstPts = [[0.15, 0.2], [0, 0.8], [0.85, 0.2], [1, 0.8]];
    if (image.width < image.height) {
      dstPts = [[0, 0.8], [1, 0.8],  [0.15, 0.2], [0.85, 0.2]];
    }

    const flattenHomography = new Homography("projective");
    flattenHomography.setReferencePoints(srcPts, dstPts);

    const result = flattenHomography.warp(image);
    const uint8clamped = new Uint8ClampedArray(result.data.buffer);
    const imgData = new ImageData(uint8clamped, result.width);
    const warpedImg = flattenHomography.HTMLImageElementFromImageData(imgData, false);

    return warpedImg; 
  }

  render() {
    const { collection, floorColor, wallColor, stageWidth, stageHeight,
      wallPaperClicked, itemGroupSelected, screenScale } = this.props;
    const { groupX, groupY, selectedContextMenu } = this.state;
    if (!collection || !collection.items) {
      return null;
    }

    const floorGroundBoundary = 0.6 * stageHeight;

    let floorUrl;
    if (floorColor) {
      const floorColorTest = /.*_[0-9]/g;
      floorUrl = "/img/floors/" + floorColor + ".jpg";
      if (!floorColorTest.test(floorColor)) {
        floorUrl = "/img/floors/" + floorColor + ".png";
      }
    }

    const items = [];
    const groupedItems = [];
    const nodes = [];

    //console.log(collection)
    collection.items.forEach((item) => {
      if (itemGroupSelected && item.uuid in itemGroupSelected &&
        Object.keys(itemGroupSelected).length > 1 && item.uuid in this.itemImages) {
        groupedItems.push(item);
        return;
      }

      const groupSelected = item.uuid in this.state.mouseDragGroup &&
        this.state.mouseDragGroup[item.uuid];

      const selected = (this.props.itemSelected &&
        this.props.itemSelected.uuid === item.uuid) ||
        (itemGroupSelected && item.uuid in itemGroupSelected)
      const itemDom =
        <URLImage
          onRef={ref => {
            this.imageRefs[item.uuid] = ref;
          }}
          warpFunction={item.flattened ? this.rugWarper : null}
          key={item.Depthtype + "_" + item.Id + "_" + item.uuid}
          itemSelected={selected}
          groupSelected={groupSelected}
          src={item.imgUrl}
          ignoreRatio={item.flattened}
          draggable={true}
          x={item.x}
          y={item.y}
          screenScale={screenScale}
          onClick={(e) => {
            this.setState({ selectedContextMenu: null });
            if (this.props.onClickItem) {
              this.props.onClickItem(e, item);
            }
            e.cancelBubble = true;
          }}
          onDblClick={(e) => {
            this.setState({ selectedContextMenu: null });
            if (this.props.onDblClickItem) {
              this.props.onDblClickItem(item);
            }
          }}
          onImageScaled={(scale) => {
            this.setState({ selectedContextMenu: null });
            if (this.props.onScaledItem) {
              this.props.onScaledItem(item.uuid, scale);
            }
          }}
          onContextMenu={(e) => {
            e.evt.preventDefault()
            this.setState({ selectedContextMenu: null });
            if (this.props.onDeselectItem && this.props.itemSelected) {
              this.props.onDeselectItem();
            }
            this.props.onClickItem(e, item);
            this.handleContextMenu(e);
          }}
          onImageLoaded={(image) => {
            if (this.props.onItemImageLoaded) {
              this.props.onItemImageLoaded(item.uuid, image);
            }
            this.itemImages[item.uuid] = image;
          }}
          onDragStart={(e) => {
            this.setState({ selectedContextMenu: null });
            if (this.props.onClickItem) {
              this.props.onClickItem(e, item);
            }
          }}
          onDragEnd={(e) => {
            item.xFromAnchor = (e.target.x() + item.imageWidth  / 2 - this.props.stageWidth  / 2) / this.props.stageWidth;
            item.yFromAnchor = (e.target.y() + item.imageHeight / 2 - this.props.stageHeight / 2) / this.props.stageHeight;
            if (this.props.onDraggedItem) {
              this.props.onDraggedItem(item.uuid, item.xFromAnchor, item.yFromAnchor);
            }
          }}
          image={this.itemImages[item.uuid]}
          flip={item.flipped}
          width={item.imageWidth}
          height={item.flattened ? 0.314 * item.imageWidth : item.imageHeight}/>;

      // return the item label and sales label
      items.push(itemDom);
    });

    if (groupedItems.length > 0 && this.trRef) {
      groupedItems.forEach(item => {
        if (this.imageRefs[item.uuid]) {
          nodes.push(this.imageRefs[item.uuid]);
        }
      });

      this.trRef.nodes(nodes);
      this.trRef.getLayer().batchDraw();
    }
    const itemGroupDom = ( Boolean(itemGroupSelected && groupedItems.length > 0) &&
      <Group
        x={0}
        y={0}
        draggable
        onDragEnd={(e) => {
          const deltaX = e.target.x() - this.state.groupX;
          const deltaY = e.target.y() - this.state.groupY;
          if (this.props.onDraggedGroup) {
            const groupAnchored = groupedItems.map(item => {
              item.xFromAnchor = (deltaX + item.x + item.imageWidth  / 2 - this.props.stageWidth  / 2) /
                this.props.stageWidth;
              item.yFromAnchor = (deltaY + item.y + item.imageHeight / 2 - this.props.stageHeight / 2) /
                this.props.stageHeight;
              return item;
            });
            this.props.onDraggedGroup(groupAnchored);
            this.setState({groupX: e.target.x(), groupY: e.target.y()});
          }
        }}
        width={stageWidth}
        height={stageHeight}>
        <Transformer
          ref={ref => {
            this.trRef = ref;
          }}
          rotateEnabled={false}
          centeredScaling={true}
          enabledAnchors={[]}
        />
        { groupedItems.map(item => {
            let scaleX, scaleY, scale;
            if (!this.itemImages[item.uuid]) {
              return null;
            }

            let image = this.itemImages[item.uuid];
            const height = item.flattened ? 0.314 * item.imageWidth : item.imageHeight;
            if (item.flattened) {
              image = this.rugWarper(image);
            }

            if (image.width >= image.height) {
              scale = item.imageWidth / image.width;
            }
            else {
              scale = height / image.height;
            }

            if (item.flattened) {
              scaleX = item.imageWidth / image.width;
              scaleY = height / image.height;
            }
            else {
              scaleX = scale;
              scaleY = scale;
            }

            return (
              <>
                <Image
                  key={item.uuid + "_grouped_image"}
                  x={item.flipped ? item.x + item.imageWidth - groupX : item.x - groupX}
                  y={item.y - groupY}
                  scaleX={scaleX * (item.flipped ? -1 : 1)}
                  scaleY={scaleY}
                  image={image}
                  ref={node => {
                    this.imageRefs[item.uuid] = node;
                  }}
                />
                <Rect stroke='#6D28FF'
                  strokeWidth={2}
                  key={item.uuid + "_grouped_rect"}
                  x={item.x - groupX}
                  y={item.y - groupY}
                  width={item.imageWidth}
                  height={height}
                />
              </>);
          })
        }
      </Group>
    );

    const wallUrl = "/img/walltexture_white.jpg";
    const { wallPaper } = this.props;
    return (
      <div ref={this.moodboardRef} id={"moodboardstage"}>
        <Stage ref={ref => { this.stageRef = ref; }}
          width={stageWidth} height={stageHeight}
          style={{
            borderRadius: '10px',
            overflow: 'hidden',
            border: '1px solid #DDDDDD'
          }}
          onMouseLeave={(e) => {
            if (!this.props.onMouseDragSelect) {
              return;
            }
            this.setState({selectionRect: null, mouseDragGroup: {}})
          }}
          onMouseDown={(e) => {
            if (!this.props.onMouseDragSelect) {
              return;
            }

            // prevent drag selection from happening if not a left click
            if (e.evt.button !== 0) {
              return;
            }

            // prevent drag selection from happening if we're not clicking on the background refs
            if (e.target !== this.backgroundRef && e.target !== this.wallPaperFullRef &&
              e.target !== this.wallPaperLeftRef && e.target !== this.wallPaperRightRef &&
              e.target !== this.trimRef) {
              return;
            }

            this.setState({
              selectionRect: {
                x1: e.currentTarget.getPointerPosition().x,
                y1: e.currentTarget.getPointerPosition().y,
                x2: e.currentTarget.getPointerPosition().x,
                y2: e.currentTarget.getPointerPosition().y,
              }
            });
          }}
          onMouseMove={(e) => {
            if (!this.props.onMouseDragSelect) {
              return;
            }

            if (!this.state.selectionRect) {
              return;
            }

            const { x1, y1 } = this.state.selectionRect;
            const x2 = e.currentTarget.getPointerPosition().x;
            const y2 = e.currentTarget.getPointerPosition().y;
            const x = Math.min(x1, x2);
            const y = Math.min(y1, y2);
            const width = Math.abs(x2 - x1);
            const height = Math.abs(y2 - y1);
            const newRect = {...this.state.selectionRect, x2, y2, x, y, width, height};
            this.setState({selectionRect: newRect});

            if (this.imageRefs) {
              const { selectionRect } = this.state;
              const box = {
                x: selectionRect.x,
                y: selectionRect.y,
                width: selectionRect.width,
                height: selectionRect.height
              };

              const newMouseDragGroup = {...this.state.mouseDragGroup};
              Object.keys(this.imageRefs).forEach(key => {
                const shape = this.imageRefs[key];
                if (!shape) {
                  return;
                }

                const intersected = Konva.Util.haveIntersection(box, shape.getClientRect());
                newMouseDragGroup[key] = intersected;
              });
              this.setState({mouseDragGroup: newMouseDragGroup});
            }
          }}
          onMouseUp={(e) => {
            if (!this.props.onMouseDragSelect) {
              return;
            }

            const { selectionRect, mouseDragGroup } = this.state;
            if (!selectionRect) {
              return;
            }

            if (!selectionRect.x) {
              this.setState({selectionRect: null})
              return;
            }

            const groupedItems = [];
            Object.keys(mouseDragGroup).forEach(key => {
              if (mouseDragGroup[key]) {
                groupedItems.push(key);
              }
            })
            this.setState({selectionRect: null, mouseDragGroup: {}})

            this.props.onMouseDragSelect(groupedItems);
          }}
          onClick={(e) => {
            this.setState({ selectionRect: null, selectedContextMenu: null, groupX: 0, groupY: 0 });
            if (this.props.onDeselectItem && (this.props.itemSelected || itemGroupSelected)) {
              this.props.onDeselectItem();
            }
          }}>

          <Layer>
            <GroundImageRect
              rectType="wall" x={0} y={0} width={stageWidth}
              height={stageHeight} src={wallUrl}/>

            <Rect x={0} y={0} width={stageWidth / 2} height={stageHeight}
              fill={wallColor.left ? "#" + wallColor.left : "#" + wallColor} opacity={1}/>
            <Rect x={stageWidth / 2 - 1} y={0} width={stageWidth / 2} height={stageHeight}
              fill={wallColor.right ? "#" + wallColor.right : "#" + wallColor} opacity={1}/>

            { floorColor ?
              <GroundImageRect rectType="floor" x={0} y={floorGroundBoundary} width={stageWidth}
                height={stageHeight - floorGroundBoundary} src={floorUrl}/> : null
            }

            <Rect ref={node => {this.backgroundRef = node}} x={0} y={0} width={stageWidth} height={stageHeight}/>

            { wallPaper && wallPaper.left && !wallPaper.right ?
              <WallPaperRect onClick={wallPaperClicked}
                onRef={node => {
                  this.wallPaperLeftRef = node
                }}
                x={0} y={0} width={stageWidth / 2} scale={wallPaper.scale}
                height={floorGroundBoundary} src={wallPaper.item.imgUrl}/> : null
            }

            { wallPaper && wallPaper.right && !wallPaper.left ?
              <WallPaperRect onClick={wallPaperClicked}
                onRef={node => {
                  this.wallPaperRightRef = node
                }}
                x={stageWidth / 2} y={0} width={stageWidth / 2} scale={wallPaper.scale}
                height={floorGroundBoundary} src={wallPaper.item.imgUrl}/> : null
            }

            { wallPaper && wallPaper.right && wallPaper.left ?
              <WallPaperRect onClick={wallPaperClicked}
                onRef={node => {
                  this.wallPaperFullRef = node
                }}
                x={0} y={0} width={stageWidth} scale={wallPaper.scale}
                height={floorGroundBoundary} src={wallPaper.item.imgUrl}/> : null
            }

            { floorColor ?
              <GroundImageRect
                onRef={node => {
                  this.trimRef = node
                }}
                rectType="wall" x={0} y={floorGroundBoundary - stageHeight * 0.025}
                width={stageWidth} height={stageHeight * 0.025} src={"/img/trim.jpg"}/> : null
            }

            { items }
            { itemGroupDom }

            { Boolean(this.state.selectionRect && this.state.selectionRect.x) ?
              <Rect stroke='#6D28FF'
                strokeWidth={2}
                x={this.state.selectionRect.x}
                y={this.state.selectionRect.y}
                width={this.state.selectionRect.width}
                height={this.state.selectionRect.height}/> : null }
          </Layer>

          { selectedContextMenu && (
            <Portal>
              <ContextMenu
                {...selectedContextMenu}
                onClose={() => {
                  if (this.props.onDeselectItem && this.props.itemSelected) {
                    this.props.onDeselectItem();
                  }
                }}
                onOptionSelected={this.handleOptionSelected}
                moodboardRef={this.moodboardRef}
                onBringFront={this.props.onBringFront}
                onSendBack={this.props.onSendBack}
                onTileWallpaper={this.props.onTileWallpaper}
                onFlatten={this.props.onFlatten}
                itemFlattened={this.props.itemSelected ? this.props.itemSelected.flattened : false}
                itemId = {this.props.itemSelected ? this.props.itemSelected.Id : ''}
              />
            </Portal>
          )}
        </Stage>
      </div>
    )
  };
}


MoodBoard.defaultProps = { collection: [] };

export default MoodBoard;

