import React from 'react';
import { Rect } from 'react-konva';

class GroundImageRect extends React.Component {
  state = {image: null}

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }

  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad);
  }

  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.crossOrigin = "Anonymous";
    this.image.addEventListener('load', this.handleLoad);
  }

  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image
    });
  };

  render() {
    let scaleX = 1;
    let scaleY = 1;
    if (this.state.image && this.props.height) {
      scaleX = this.props.width / this.state.image.width;
      scaleY = this.props.height / this.state.image.height;
    }

    return (
      <Rect
        ref={node => {
          if (this.props.onRef) {
            this.props.onRef(node);
          }
        }}
        x={this.props.x} y={this.props.y}
        width={this.props.width}
        height={this.props.height}
        fillPatternScaleX={scaleX}
        fillPatternScaleY={scaleY}
        fillPatternImage={this.state.image}
      />
    );
  }
}

export default GroundImageRect;
