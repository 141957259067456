import React, { Component } from "react";
import { Icon, Button, Menu, MenuItem, Fade } from "@material-ui/core";
import ListItemText from '@material-ui/core/ListItemText';
import { createTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import analytics from '../analytics/Analytics';


const theme = createTheme({
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: 'unset',
        marginRight: '20px',
        textTransform: 'none',
      }
    }
  }
});

const styles = theme => ({
  button: {
    marginRight: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '5px',
    backgroundColor: '#F0F0F0',
    height: '100%',
  },
  textButton: {
    paddingTop: "12px",
    paddingBottom: "12px",
    height: '90%',
    borderRadius: '5px',
    backgroundColor: '#F0F2F5',
    whiteSpace: 'nowrap',
    color: '#000000',
    fontSize: 13,
    textTransform: 'None',
    '&:hover': {
      backgroundColor: '#DAD9D9'
    }
  },
});

class LayerPopover extends Component {
  state = {
    anchorEl: null,
  };

  componentDidMount () {
    if (this.props.rightClickActivated) {
      this.setState({anchorEl: this.props.extAnchor.current});
    }
  }
  
  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  findSimilarOnGoogle(itemId) {
    const endPointURL = "https://us-central1-mixandmatch-287616.cloudfunctions.net/app/itempublicimages/";
    const imagePath = endPointURL + itemId
    const googleSearchPath = "https://images.google.com/searchbyimage?image_url=" + imagePath;
    console.log("Image url = " + googleSearchPath);

    // launch search
    window.open(googleSearchPath, "_blank");
  }

  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        {!this.props.rightClickActivated && <Button aria-label="depth_layer"
          title={"Change Item Layer"}
          disabled={this.props.disabled}
          onClick={this.handleClick} className={classes.textButton}
          endIcon={<Icon>arrow_drop_down</Icon>}>
          <Icon><img alt="layer_logo" src="/img/icons/layers.svg"/></Icon>
        </Button>}

        {this.state.anchorEl && <Menu
          id="share-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl) || this.props.rightClickActivated}
          onClose={this.handleClose}
          TransitionComponent={Fade}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          { this.props.allTheWayFront?
            <MenuItem onClick={() => {
              this.handleClose();
              this.props.allTheWayFront();
              analytics.track("Item layer changed to all the way front");
            }}>
              <ListItemText primary="Bring to Front" />
            </MenuItem> : null }
          { this.props.allTheWayBack?
            <MenuItem onClick={() => {
              this.handleClose();
              this.props.allTheWayBack();
              analytics.track("Item layer changed to all the way back");
            }}>
              <ListItemText primary="Send to Back" />
            </MenuItem> : null }
          { this.props.flattenItem?
            <MenuItem onClick={() => {
              this.handleClose();
              this.props.flattenItem();
              analytics.track("Item flattened to floor");
            }}>
              <ListItemText primary="Flatten on floor (for Rugs)" />
              {this.props.itemFlattened ? <Icon>check</Icon> : null}
            </MenuItem> : null }
          { this.props.tileWallpaper?
            <MenuItem onClick={() => {
              this.handleClose();
              this.props.tileWallpaper();
              analytics.track("Item tiled as wallpaper");
            }}>
              <ListItemText primary="Tile across wall (For Wallpaper)" />
              {this.props.itemWallpapered ? <Icon>check</Icon> : null}
            </MenuItem> : null }
          { this.props.rightClickActivated &&
            <MenuItem onClick={() => {
              this.handleClose()
              this.findSimilarOnGoogle(this.props.itemId)
            }}>
              <ListItemText primary="Find Similar on Google" />
            </MenuItem> }
        </Menu>}
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(LayerPopover);
