import React from "react";
import LayerPopover from "./LayerPopover";


class ContextMenu extends React.Component {
  ctxRef = React.createRef()

  constructor (props) {
    super(props)
    this.state = {
      renderPopover: false,
      moodboardLeft: 0,
      moodboardTop: 0,
    }
  }

  componentDidMount () {
    let moodboardRect = this.props.moodboardRef.current.getBoundingClientRect()
    this.setState({
      renderPopover: true,
      moodboardTop: moodboardRect.top ? moodboardRect.top:0, 
      moodboardLeft: moodboardRect.left ? moodboardRect.left : 0
    })
  }

  render () {
    return (
      <div
        className="menu"
        style={{
          position: "absolute",
          left: this.props.position.x + this.state.moodboardLeft,
          top: this.props.position.y + this.state.moodboardTop,
        }}
        ref={this.ctxRef}
      >
        {this.state.renderPopover && <LayerPopover rightClickActivated={true} 
            extAnchor={this.ctxRef}
            onClose={this.props.onClose}
            itemFlattened={this.props.itemFlattened}
            allTheWayFront={this.props.onBringFront}
            allTheWayBack={this.props.onSendBack}
            flattenItem={this.props.onFlatten}
            tileWallpaper={this.props.onTileWallpaper}
            itemId={this.props.itemId}/>}
      </div>
    );
  }
};
export default ContextMenu;
