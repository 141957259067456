/**
 * @file cloudFunctions.jsx
 * Javascript file that interfaces Cloud Functions with the Dev Portal
 *
 * All Cloud Functions that the Dev Portal uses are listed here.
 * The Dev Portal can invoke Cloud Functions by calling the correct function here.
 * Each Cloud Function has a proper completion and error callback setup.
 * Cloud Function Ajax request are made using @file common.jsx
 */

import path from 'path';
import firebase from 'firebase/app';

const storage = firebase.storage();
const firebaseStorage = storage.ref();

const db = firebase.firestore();
// if (window.location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }

const cloudFunctionsFirebase = {

  baseUrl: 'https://us-central1-mixandmatch-287616.cloudfunctions.net/',
  // baseUrl: 'http://localhost:5001/mixandmatch-287616/us-central1/',  // test
  cvServiceURL: "https://cvservice-dot-mixandmatch-databasemanager.uc.r.appspot.com/",

  updateUserData: async (userData, updateUserDataCb) => {
    let user = firebase.auth().currentUser;
    if (!user) {
      console.error("Firebase not logged in");
      updateUserDataCb(false);
      return;
    }

    try {
      const usersRef = db.collection('/users');
      let updateDict = {};
      if (userData.firstName !== undefined) {
        updateDict.firstName = userData.firstName;
      }

      if (userData.lastName !== undefined) {
        updateDict.lastName = userData.lastName;
      }

      if (userData.userName !== undefined) {
        const usersSnapshot = await usersRef.where("userName", "==", userData.userName).get();
        if (usersSnapshot.empty) {
          updateDict.userName = userData.userName;
        }
      }

      if (userData.description !== undefined) {
        updateDict.description = userData.description;
      }

      if (updateDict.empty) {
        updateUserDataCb(true);
        return;
      }

      await db.collection('/users').doc(userData.userId).update(updateDict);
      console.log('Successfully updted data for user.', updateDict);
      updateUserDataCb(true);
    } catch (error) {
      console.log('Error updating data for user: ', error);
      updateUserDataCb(false);
    }
  },
  bookmarkCollectionFirestore: async (collectionId, bookmarkCollectionCallback) => {
    let user = firebase.auth().currentUser;
    if (!user) {
      console.error("Firebase not logged in");
      bookmarkCollectionCallback(false);
      return;
    }

    try {
      console.log(`bookmarking ${collectionId}`);
      const userDoc = await db.collection('users').doc(user.uid).get();
      const userData = userDoc.data();
      if (collectionId in userData.bookmarks.collections) {
        console.log("already bookmarked");
        bookmarkCollectionCallback(true);
        return;
      }

      const collectionRef = db.collection('collections').doc(collectionId);
      const updateDict = {};
      updateDict["bookmarks.collections." + collectionId] = collectionRef;
      await db.collection('users').doc(user.uid).update(updateDict);

      bookmarkCollectionCallback(true);
    } catch(err) {
      console.log("Error in creating bookmark", err);
      bookmarkCollectionCallback(false);
    }
  },
  bookmarkItemFirestore: async (itemId, bookmarkItemCallback) => {
    let user = firebase.auth().currentUser;
    if (!user) {
      console.error("Firebase not logged in");
      bookmarkItemCallback(false);
      return;
    }

    try {
      const itemRef = db.collection('ecommercelink').doc(itemId);
      const updateDict = {};
      updateDict["bookmarks.items." + itemId] = itemRef;
      updateDict["bookmarks.itemBookmarkDates." + itemId] = Date.now();
      await db.collection('users').doc(user.uid).update(updateDict);
      bookmarkItemCallback(true);
    } catch(err) {
      console.log("Error in bookmarkItem", err);
      bookmarkItemCallback(false);
    }
  },
  removeBookmarkCollectionFirestore: async (collectionId, removeBookmarkCollectionCallback) => {
    let user = firebase.auth().currentUser;
    if (!user) {
      console.error("Firebase not logged in");
      removeBookmarkCollectionCallback(false);
      return;
    }

    try {
      console.log(`deleting bookmark for ${collectionId}`);
      const updateDict = {};
      const FieldValue = firebase.firestore.FieldValue;
      updateDict["bookmarks.collections." + collectionId] = FieldValue.delete();
      updateDict["bookmarks.moodboards." + collectionId] = FieldValue.delete();
      await db.collection('users').doc(user.uid).update(updateDict);
      removeBookmarkCollectionCallback(true);
    } catch(err) {
      console.log("Error in removeBookmarkCollection", err);
      removeBookmarkCollectionCallback(false);
    }
  },
  removeBookmarkItemFirestore: async (itemId, removeBookmarkItemCallback) => {
    let user = firebase.auth().currentUser;
    if (!user) {
      console.error("Firebase not logged in");
      removeBookmarkItemCallback(false);
      return;
    }

    try {
      console.log(`deleting item for ${itemId}`);
      const updateDict = {};
      const FieldValue = firebase.firestore.FieldValue;
      updateDict["bookmarks.items." + itemId] = FieldValue.delete();
      await db.collection('users').doc(user.uid).update(updateDict);

      removeBookmarkItemCallback(true);
    } catch(err) {
      console.log("Error in removeBookmarkItem", err);
      removeBookmarkItemCallback(false);
    }
  },
  getCollectionEcommerceLinksFirestore: async (collectionId, collectionEcommerceLinksCallback) => {
    try {
      // get the record from the collection
      const colRef = db.collection('collections').doc(collectionId);
      const doc = await colRef.get();
      const collection = doc.data();

      const typeDoc = await collection.Type.get();
      const userDoc = await collection.User.get();
      let result = {
        name: collection.Name,
        type: typeDoc.id,
        owner: userDoc.id,
      };
      if (collection.colorParams) {
        result.colorParams = collection.colorParams;
      }

      let itemList = [];
      await Promise.all(collection.items.map(async (value, index) => {
        // getting product details
        const doc = await value.get();

        const itemData = doc.data();

        // getting vendor name
        if (itemData) {
          if (itemData.Vendor) {
            const vendorDoc = await itemData.Vendor.get();
            const vendorName = vendorDoc.data();
            if (vendorName && vendorName.Name !== "unsupported")  {
              itemData.vendorName = vendorName.Name;
            }
            else {
              itemData.vendorName = " ";
            }
          }
          else {
            itemData.vendorName = " ";
          }

          itemData.Id = doc.id;
          if (collection.itemParams && index in collection.itemParams) {
            if (collection.itemParams[index].xFromAnchor !== undefined) {
              itemData.xFromAnchor = collection.itemParams[index].xFromAnchor;
              itemData.yFromAnchor = collection.itemParams[index].yFromAnchor;
            }
            if (collection.itemParams[index].flipped) {
              itemData.flipped = collection.itemParams[index].flipped;
            }
            if (collection.itemParams[index].flattened !== undefined) {
              itemData.flattened = collection.itemParams[index].flattened;
            }
            if (collection.itemParams[index].scale !== undefined) {
              itemData.scale = collection.itemParams[index].scale;
            }
            if (collection.itemParams[index].z !== undefined) {
              itemData.z = collection.itemParams[index].z;
            }
          }


          return itemList.push(itemData);
        }
        const updateDict = {};
        updateDict["items"] = firebase.firestore.FieldValue.arrayRemove(value);
        return colRef.update(updateDict);
      }));
      result.items = itemList;
      collectionEcommerceLinksCallback(result);
    } catch(err) {
      console.log('Error getting collection ecommercelink', err);
      collectionEcommerceLinksCallback(false);
    }
  },
  getCuratedItems: async (itemType, startIdx, itemLimit, pageNum, useOrigImg, getCuratedItemsCallback) => {
    try {
      const itemsRef = db.collection('ecommercelink');
      const curatedQuery = itemType
        ? itemsRef.where('Curated', '==', true).where("Type", "==", itemType)
        : itemsRef.where('Curated', '==', true);
      
      let paginatedQuery;
      if (startIdx !== null) {
        paginatedQuery = curatedQuery.orderBy('Created', 'desc').startAfter(startIdx).limit(itemLimit);
      } else {
        paginatedQuery = curatedQuery.orderBy('Created', 'desc').limit(itemLimit);
      }

      paginatedQuery.get().then(async (querySnapshot) => {
        let itemsList = await Promise.all(querySnapshot.docs.map(async (doc) => {
          // getting product details
          const itemData = doc.data();

          if (itemData.CreatedBy) {
            const creatorDoc = await db.collection("users").doc(itemData.CreatedBy).get();
            itemData.creatorInfo = creatorDoc.data();
            if (itemData.creatorInfo.profilePic) {
              itemData.creatorInfo.profilePicUrl = "https://us-central1-mixandmatch-287616.cloudfunctions.net/app/profilepic/" + creatorDoc.id;
            }
          }

          // getting vendor name
          if (itemData.Vendor) {
            const vendorDoc = await itemData.Vendor.get();
            const vendorName = vendorDoc.data();
            if (vendorName && vendorName.Name !== "unsupported")  {
              itemData.vendorName = vendorName.Name;
            } else {
              itemData.vendorName = " ";
            }
          } else {
            itemData.vendorName = " ";
          }

          itemData.Id = doc.id;
          let imgUrl;
          if (useOrigImg) {
            imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`ecommercelink/${itemData.Id}/image0.png`);
          } else {
            imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`ecommercelink/${itemData.Id}/image1.png`);
          }
          itemData.imgUrl = imgUrl.url;

          return itemData;
        }));
        console.log(itemsList)

        getCuratedItemsCallback(itemsList, pageNum);
      });
    } catch(err) {
      console.log("Error getting curated items, err: " + err);
      getCuratedItemsCallback(false, pageNum);
    }
  },
  getCuratedItemsSearch: async (ecommercelinkIDs, useOrigImg, getCuratedItemsSearchCallback) => {
    // Get curated items based on the algolia search.
    try {
      let itemsList = await Promise.all(ecommercelinkIDs.map(async (id) => {
        const itemDoc = await db.collection('ecommercelink').doc(id).get();
        const itemData = itemDoc.data();

        if (itemData.CreatedBy) {
          const creatorDoc = await db.collection("users").doc(itemData.CreatedBy).get();
          itemData.creatorInfo = creatorDoc.data();
          if (itemData.creatorInfo.profilePic) {
            itemData.creatorInfo.profilePicUrl = "https://us-central1-mixandmatch-287616.cloudfunctions.net/app/profilepic/" + creatorDoc.id;
          }
        }

        if (itemData.Vendor) {
          const vendorDoc = await itemData.Vendor.get();
          const vendorName = vendorDoc.data();
          if (vendorName && vendorName.Name !== "unsupported")  {
            itemData.vendorName = vendorName.Name;
          } else {
            itemData.vendorName = " ";
          }
        } else {
          itemData.vendorName = " ";
        }

        itemData.Id = itemDoc.id;

        let imgUrl;
        if (useOrigImg) {
          imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`ecommercelink/${itemData.Id}/image0.png`);
        } else {
          imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`ecommercelink/${itemData.Id}/image1.png`);
        }
        itemData.imgUrl = imgUrl.url;
        
        return itemData;
      }));

      getCuratedItemsSearchCallback(itemsList, 0);
    } catch (err) {
      console.log("Error getting curated items, err: " + err);
      getCuratedItemsSearchCallback(false, 0);
    }
  },
  getAlternativeItemsFirestore: async (itemId, getAlternativeItemsCallback) => {
   try {
     const doc = await db.collection('ecommercelink').doc(itemId).get();
     const targetItem = doc.data();
     let type = [targetItem.Type];

     if (type[0].includes("pillow")) {
       type = ["pillow", "pillow1", "pillow2"];
     } else if (type[0].includes("wallart")) {
       type = ["wallart", "wallart1", "wallart2"];
     }
     const itemsSameTypeDocs = await db.collection('ecommercelink').where("Type", "in", type).get();

     let itemList = [];
     let similarItems = [];
     await Promise.all(itemsSameTypeDocs.docs.map(async (doc, index) => {
       const item = doc.data();
       const vendordoc = await item.Vendor.get();
       const vendorName = vendordoc.data();

       // if vendor is null or if it's unsupported, don't show it
       if (vendorName && vendorName.Name !== "unsupported")  {
           item.vendorName = vendorName.Name;
       }
       else {
           item.vendorName = " ";
       }

       item.Id = doc.id;
       let j = targetItem.Alternatives.findIndex(x => x.id === item.Id);
       if (j !== -1) {
           similarItems[j] = item;
       } else {
           itemList.push(item);
       }
       return true;
     }))
     getAlternativeItemsCallback({itemList, similarItems});
   } catch(err) {
     console.log('Error getting similar items for ' +  itemId + ", err: " + err);
     getAlternativeItemsCallback(false);
   }
  },
  overwriteCollectionFirestore: async (collection, wallColor, floorColor, wallPaper, overwriteCollectionCallback) => {
    let user = firebase.auth().currentUser;
    if (!user) {
      console.error("Firebase not logged in");
      overwriteCollectionCallback(false);
      return;
    }
    try {
      const colRef = db.collection('collections').doc(collection.id);
      const colDoc = await colRef.get();
      const colData = colDoc.data();

      const oldUserDoc = await colData.User.get();
      if (oldUserDoc.id !== user.uid) {
        console.error("User not auth to overwrite");
        overwriteCollectionCallback(false);
        return;
      }

      let wallPaperToSave = {...wallPaper}
      if (wallPaper) {
        wallPaperToSave.item = {Id: wallPaper.item.Id};
      }
      const colorParams = {wallColor, floorColor, wallPaper: wallPaperToSave};
      const itemParams = {};
      const itemRefs = collection.items.map((item, index) => {
        if (item.xFromAnchor !== undefined || item.flipped !== undefined || item.scale !== undefined || item.z !== undefined) {
          itemParams[index] = {};
          if (item.xFromAnchor !== undefined) {
            itemParams[index] = {xFromAnchor: item.xFromAnchor, yFromAnchor: item.yFromAnchor};
          }
          if (item.flipped !== undefined) {
            itemParams[index].flipped = item.flipped;
          }
          if (item.scale !== undefined) {
            itemParams[index].scale = item.scale;
          }
          if (item.z !== undefined) {
            itemParams[index].z = item.z;
          }
        }

        if (typeof item.flattened !== 'undefined') {
          itemParams[index].flattened = item.flattened;
        }
        return db.collection('ecommercelink').doc(item.Id);
      });

      await colRef.update({
        Name: collection.name,
        Type: db.collection('collectiontypes').doc(collection.type),
        items: itemRefs,
        itemParams,
        colorParams,
      });

      overwriteCollectionCallback(true);
    } catch(err) {
      console.log("Error in overwriteCollectionFirestore", err);
      overwriteCollectionCallback(false);
    }
  },
  createNewItemFirestore: async (itemInfo, createNewItemCallback) => {
    let user = firebase.auth().currentUser;
    if (!user) {
      console.error("Firebase not logged in");
      createNewItemCallback(false);
      return;
    }

    if (!itemInfo.selImgUrl) {
      console.error("No product img url found in the req body");
      createNewItemCallback(false);
      return;
    }

    const itemData = {selImgUrl: itemInfo.selImgUrl, Created: Date.now(), CreatedBy: user.uid};
    
    if (itemInfo.imgUrl) {
      itemData.origImgUrl = itemInfo.imgUrl;
    }

    if (itemInfo.whiteBgUrl) {
      itemData.whiteBgUrl = itemInfo.whiteBgUrl;
    }

    if (itemInfo.productURL) {
      itemData.URL = itemInfo.productURL;
    }

    if (itemInfo.price) {
      itemData.Price = itemInfo.price;
    }

    if (itemInfo.productName) {
      itemData.Title = itemInfo.productName;
    }

    if (itemInfo.type) {
      itemData.Depthtype = itemInfo.type;
    }

    if (itemInfo.itemnetResponse) {
      const itemnetResponse = itemInfo.itemnetResponse;
      itemData.PredictedType = itemnetResponse.prediction;
      itemData.TypeConfidence = itemnetResponse.confidence;
      itemData.TypeRanking = itemnetResponse.prediction_ranking;
      itemData.ConfidenceRanking = itemnetResponse.confidence_ranking;
    }
    
    itemData.ImageProcComplete = false;

    try {
      const itemDoc = await db.collection('ecommercelink').add(itemData);

      // add new item to bookmark
      const updateDict = {};

      // Add the new item to the bookmark, but label it as false to indicate
      // that image processing is not complete
      updateDict["bookmarks.items." + itemDoc.id] = false;
      await db.collection('users').doc(user.uid).update(updateDict);
      createNewItemCallback({newItemId: itemDoc.id});
    } catch(err) {
      console.log("Error in createNewItem", err);
      createNewItemCallback(false);
    }
  },
  createNewCollectionFirestore: async (collection, wallColor, floorColor, createNewCollectionCallback) => {
    let user = firebase.auth().currentUser;
    if (!user) {
      console.error("Firebase not logged in");
      createNewCollectionCallback(false);
      return;
    }

    try {
      const colorParams = {wallColor, floorColor};
      const itemParams = {};
      const itemRefs = collection.items.map((item, index) => {
        if (item.xFromAnchor || item.flipped) {
          itemParams[index] = {};
          if (item.xFromAnchor) {
            itemParams[index] = {xFromAnchor: item.xFromAnchor, yFromAnchor: item.yFromAnchor};
          }
          if (item.flipped) {
            itemParams[index].flipped = true;
          }
          if (item.scale) {
            itemParams[index].scale = item.scale;
          }
        }
        if (typeof item.flattened !== 'undefined') {
          itemParams[index].flattened = item.flattened
        }
        return db.collection('ecommercelink').doc(item.Id);
      });

      const colDoc = await db.collection('collections').add({
        Name: collection.name,
        Styles: [],
        Type: db.collection('collectiontypes').doc(collection.type),
        Published: false,
        User: db.collection('/users').doc(user.uid),
        items: itemRefs,
        itemParams,
        colorParams,
        created: Date.now(),
      });

      cloudFunctionsFirebase.bookmarkCollectionFirestore(colDoc.id, (result) => {
        if (result) {
          createNewCollectionCallback({newCollectionId: colDoc.id});
        }
        else {
          createNewCollectionCallback(false);
        }
      });
    } catch(err) {
      console.log("Error in createNewCollection", err);
      createNewCollectionCallback(false);
    }
  },
  duplicateCollectionFirestore: async (collectionId, duplicateCollectionCallback) => {
    let user = firebase.auth().currentUser;
    if (!user) {
      console.error("Firebase not logged in");
      duplicateCollectionCallback(false);
      return;
    }

    try {
      const origColDoc = await db.collection('collections').doc(collectionId).get();
      const colData = origColDoc.data();
      colData.User = db.collection('/users').doc(user.uid);
      colData.created = Date.now();
      const colDoc = await db.collection('collections').add(colData);

      cloudFunctionsFirebase.bookmarkCollectionFirestore(
        colDoc.id,
        (result) => {
          if (result) {
            duplicateCollectionCallback({newCollectionId: colDoc.id});
          }
          else {
            duplicateCollectionCallback(false);
          }
        }
      );
    } catch(err) {
      console.log("Error in createNewCollection", err);
      duplicateCollectionCallback(false);
    }
  },
  updateItemInfo: async (itemId, itemInfo, updateItemInfoCb) => {
    let user = firebase.auth().currentUser;
    if (!user) {
      console.error("Firebase not logged in");
      updateItemInfoCb(false);
      return;
    }

    const itemData = {}
    if (itemInfo.price !== undefined) {
      itemData.Price = itemInfo.price;
    }

    if (itemInfo.productName !== undefined) {
      itemData.Title = itemInfo.productName;
    }

    if (itemInfo.productURL !== undefined) {
      itemData.URL = itemInfo.productURL;
    }

    if (itemInfo.type) {
      itemData.Depthtype = itemInfo.type;
    }

    if (itemInfo.warpImgUrl) {
      itemData.warpImgUrl = itemInfo.warpImgUrl;
    }

    if (itemInfo.imgUrl) {
      itemData.origImgUrl = itemInfo.imgUrl;
    }

    try {
      const itemRef = db.collection('ecommercelink').doc(itemId);
      await itemRef.update(itemData);

      return updateItemInfoCb(true);
    } catch(err) {
      console.log("Error in updateItemInfo", err);
      return updateItemInfoCb(false);
    }
  },
  getImageSignedUrl: async (filePath) => {
    let imageStorageLoc = path.join('images', filePath);
    let newMetadata = {
      cacheControl: 'public,max-age=400000',
    }
    try {
      const imageRef = firebaseStorage.child(imageStorageLoc);
      if (firebase.currentUser) {
        imageRef.updateMetadata(newMetadata);
      }
      const url = await imageRef.getDownloadURL();
      return {url};
    } catch (err) {
      return {url : null};
    }
  }, 
  removeBackground: async (imgUrl, rmBgCallback) => {
    const removeBgURL = cloudFunctionsFirebase.cvServiceURL + "removebg";
    const removeBgOptions = {
      headers: {
        'url': imgUrl,
        'tempStorageID': '',
        'imageStorageLoc': '',
      },
    };

    try {
      const rmBgRes = await fetch(removeBgURL, removeBgOptions);
      if (rmBgRes.ok) {
        const result = await rmBgRes.json();

        if (!rmBgCallback) {
          return result;
        } else {
          rmBgCallback(result.signedURL, result.bgURL);
        }
      }
      else {
        if (!rmBgCallback) {
          return false;
        } else {
          rmBgCallback(false);
        }
      }
    } catch (err) {
      console.error(err);
      if (!rmBgCallback) {
        return false;
      } else {
        rmBgCallback(false);
      }    
    }
  },
  warpRugImage: async (imgUrl, warpRugCallback) => {
    const warpRugURL = cloudFunctionsFirebase.cvServiceURL + "warp";
    const warpRugOptions = {
      headers: {
        'url': imgUrl,
      },
    };

    try {
      const warpRugRes = await fetch(warpRugURL, warpRugOptions);
      if (warpRugRes.ok) {
        const result = await warpRugRes.json();
        warpRugCallback(result.signedURL);
      }
      else {
        warpRugCallback(false);
      }
    } catch (err) {
      warpRugCallback(false);
    }
  },
  itemType: async (imgUrl, itemTypeCallback) => {
    const itemTypeURL = cloudFunctionsFirebase.cvServiceURL + "itemtype";
    const itemTypeOptions = {
      headers: {
        'url': imgUrl,
      },
    };

    try {
      const itemTypeRes = await fetch(itemTypeURL, itemTypeOptions);

      if (itemTypeRes.ok) {
        const result = await itemTypeRes.json();

        if (!itemTypeCallback) {
          return result;
        } else {
          itemTypeCallback(result);
        }
      } else {
        if (!itemTypeCallback) {
          return false;
        } else {
          itemTypeCallback(false);
        }
      }
    } catch (err) {
      if (!itemTypeCallback) {
        return false;
      } else {
        itemTypeCallback(false);
      }
    }
  },
  uploadProfilePhoto: async (profilePhotoData) => {
    const user = firebase.auth().currentUser;
    if (!user) {
      console.error("Firebase not logged in");
      return false;
    }

    const profilePicPath = path.join('profile_pics/', user.uid, user.uid + '.jpg');
    const profilePicRef = firebaseStorage.child(path.join("images", profilePicPath));
    try {
      return profilePicRef.putString(profilePhotoData, 'data_url').then(async (snapshot) => {
        console.log('Uploaded a profile_pic string!');

        // add profilePic to user data
        const updateDict = {
          profilePic: profilePicPath, 
        }

        await db.collection('/users').doc(user.uid).update(updateDict);
        return profilePicPath;
      });
    } catch (err) {
      console.error('Failed to upload profile pic', err);
      return false;
    }
  },
};
export default cloudFunctionsFirebase;
window.vcmsCf = cloudFunctionsFirebase;
