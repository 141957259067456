import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAcJH5rQuBdwQ0F4KAwX7OIYR-j-FIsQ-A",
    authDomain: "app.dabble.so",
    databaseURL: "https://mixandmatch-287616.firebaseio.com",
    projectId: "mixandmatch-287616",
    storageBucket: "mixandmatch-287616.appspot.com",
    messagingSenderId: "1088955582138",
    appId: "1:1088955582138:web:8f9376b58fdee6c9c1e483",
    measurementId: "G-QW0KMGV22S"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
