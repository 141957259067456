import React, { Component } from "react";
import { Modal, TextField, Button, IconButton, Icon, CircularProgress, Typography }  from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import analytics from '../analytics/Analytics';
import path from 'path';


const styles = theme => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    outline: 0,
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '10px',
    paddingBottom: '10px',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: "#ffffff"
  },
});

class SharePopover extends Component {

  getShareUrl() {
    const { collection } = this.props;
    if (!collection) {
      return;
    }
    const shareUrl = "https://" + path.join("app.dabble.so",
      'share', this.props.collection.id);
    return shareUrl;
  }

  onRedditClick = (event) => {
    const { collection } = this.props;
    if (!collection) {
      return;
    }
    const shareUrl = this.getShareUrl();
    const postTitle = "What do you think of my room design? Any suggestions?";
    const redditUrl = "http://www.reddit.com/submit?url=" + encodeURIComponent(shareUrl) + "&title=" + encodeURIComponent(postTitle);

    console.log("Reddit url = "+ redditUrl);
    window.open(redditUrl, '_blank');
  }

  onFacebookClick = (event) => {
    const { collection } = this.props;
    if (!collection) {
      return;
    }

    const shareUrl = this.getShareUrl();
    const facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' +
      encodeURIComponent(shareUrl);

    // send facebook share to analytics
    analytics.track('Collection shared to Facebook', {
      'collection id': collection.Id,
    });

    // go to facebook share
    window.open(facebookUrl, 'facebook-share-dialog', 'width=626,height=436');
  };

  onTweetClick = (event) => {
    const { collection } = this.props;
    if (!collection) {
      return;
    }

    const shareUrl = this.getShareUrl();
    const postText = "Check out my room design on @trydabble. What do you think?";
    const hashtags = "homedesign,interiors,dabble";
    const twitterUrl = "http://twitter.com/share?text=" + encodeURIComponent(postText) 
      + "&url=" + encodeURIComponent(shareUrl)
      + "&hashtags=" + encodeURIComponent(hashtags);

    window.open(twitterUrl, '_blank');
  }

  onPinClick = (event) => {
    const { collection, wallColor, floorColor } = this.props;
    if (!collection) {
      return;
    }

    // build pinterest url
    const baseUrl = "https://us-central1-mixandmatch-287616.cloudfunctions.net";

    let itemString = "?items=";
    collection.items.forEach((item) => {
      itemString = itemString + item.Id + "," ;
    });
    itemString = itemString.slice(0, -1);
    const moodboardUrl = baseUrl + "/app/collectionmoodboard/" + wallColor + "/" +
      floorColor + "/" + itemString;
    const collectionUrl = this.getShareUrl();

    // build pinit button
    const pinterestUrl = "https://www.pinterest.com/pin/create/button/?url=" +
      collectionUrl + "&description=Dabble&media=" + moodboardUrl;

    // send pinterest share to analytics
    analytics.track('Collection shared to Pinterest', {
      'collection id': collection.Id,
    });

    // go to pinterest share
    window.open(pinterestUrl)

    this.props.handleClose();
  };

  onCopyLinkClick = (event) => {
    if (!this.props.collection) {
      return;
    }

    const shareUrl = this.getShareUrl();
    const dummy = document.createElement('p');
    dummy.textContent = shareUrl;
    document.body.appendChild(dummy);

    const range = document.createRange();
    range.setStartBefore(dummy);
    range.setEndAfter(dummy);

    const selection = window.getSelection();
    // First clear, in case the user already selected some other text
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand('copy');
    document.body.removeChild(dummy);

    if (this.props.onLinkCopied) {
      this.props.onLinkCopied();
    }
  };
  
  render() {
    const { classes, isMobile } = this.props;
    const rootWidth = isMobile ? "90%": "500px";
    return (
      <div>
        <Modal
          id="shareDesignPanel"
          className={classes.modal}
          open={this.props.showSharePanel}
          onClose={this.props.handleClose}>
          <div style={{width: rootWidth, height: "auto"}} className={classes.root}>
            <div style={{width: "100%", height: "auto"}} >
              <IconButton  aria-label="close"
                          onClick={this.props.handleClose}>
                <Icon>close</Icon>
              </IconButton>
            </div>
            <div style={{width: "80%", flexDirection: 'column'}}>
              {/* <Typography gutterBottom variant="body1" align='justify' style={{marginBottom: 10, marginTop: 5, fontSize: "14px"}}>
                Share your design with anyone by just sending them this link.
              </Typography> */}
              
              <div style={{width: "100%", height: "40px", display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "20px"}}>
              <TextField style={{height: "100%", marginTop: "0px", marginBottom: "0px", marginRight: "5px"}}
                variant="outlined" fullWidth margin="dense"
                defaultValue={this.getShareUrl()}
                inputProps={
                  { readOnly: true, }
                }
              />
              <Button variant="contained" style={{width: 150, height: "100%", boxShadow: "none", color: "#FFFFFF", backgroundColor: "#741CE5"}} onClick={this.onCopyLinkClick}>
                Copy Link
              </Button>
              </div>

              <div style={{width: "100%", height: "40px", display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "20px", justifyContent: "space-between"}}>
                <Typography gutterBottom variant="body1" align='justify' style={{marginBottom: 10, marginTop: 5, fontSize: "14px"}}>
                  Share to:
                </Typography>
                <Button variant="contained" style={{width: 100, height: "100%", boxShadow: "none", color: "#FFFFFF", backgroundColor: "#FF4500"}} onClick={this.onRedditClick}>
                  Reddit
                </Button>
                <Button variant="contained" style={{width: 100, height: "100%", boxShadow: "none", color: "#FFFFFF", backgroundColor: "#1DA1F2"}} onClick={this.onTweetClick}>
                  Twitter
                </Button>
                <Button variant="contained" style={{width: 100, height: "100%", boxShadow: "none", color: "#FFFFFF", backgroundColor: "#1877F2"}} onClick={this.onFacebookClick}>
                  Facebook
                </Button>
              </div>

              <div style={{width: "100%", height: "auto", flexDirection: 'column'}}>
                { !this.props.collectionImgUrl ?
                  <div style={{display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                    <CircularProgress disableShrink />
                  </div> :
                  <img src={this.props.collectionImgUrl} loading="lazy" alt="design img" style={{ width: "100%",  border: "1px solid #DDDDDD", borderRadius: "10px" }} />
                }
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(SharePopover);
