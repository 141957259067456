import React, {Component} from "react";
import { Grid, IconButton, Icon, Typography, Slider, CircularProgress } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import '../index.sass';
import analytics from '../analytics/Analytics';


/**
 * props expects: item object
 * **/
const useStyles = theme => ({
  gridLabel: {
    color: "#000000",
    marginTop: 10,
    fontSize: '0.8em'
  },
  colorButton: {
    boxSizing: 'border-box',
    outline: 0,
    width: '70px',
    height: '70px',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  imgContainer: {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    height: 300,
    borderRadius: 10,
  },
  img: {
    margin: 'auto',
    display: 'block',
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
  },
});


class WallPaperPanel extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state.wallSplitMode = {
      left: props.wallPaper.left,
      right: props.wallPaper.right,
    };
  }

  render() {
    const { classes, height, onNewWallSplitMode, onNewScaleValue, onClose, wallPaper } = this.props;
    const selectedBorder = "5px solid #8473EE";
    const { wallSplitMode } = this.state;

    return (
      <div style={{height: height, display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center'}}>
        <div style={{width: "100%", height: "auto", display: "flex", justifyContent: "flex-end"}}>
          <IconButton aria-label="close"
            onClick={(e) => {
              // turn on intro tour
              if (onClose) {
                onClose();
              }
            }}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <div style={{overflowY: "auto", display: 'flex', height: "100%",
          flexDirection: "column", justifyContent: "flex-start", padding: 20}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.gridLabel} style={{paddingTop: 10, color: '#767676'}}>Apply wallcolor to</div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.gridLabel}>Left Wall</div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.gridLabel}>Entire Wall</div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.gridLabel}>Right Wall</div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.colorButton}
                style={{backgroundColor: "#E2E2E2", border: wallSplitMode.left && !wallSplitMode.right ? selectedBorder : "none"}}
                onClick={() => {
                  const wallSplitMode = {left: true, right: false};
                  this.setState({wallSplitMode});
                  onNewWallSplitMode(wallSplitMode);
                  analytics.track("Wall paper applied to left");
                }}>
                <div style={{width: "50%", height: "100%", backgroundColor: "#AEAEAE"}}/>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.colorButton}
                style={{backgroundColor: "#E2E2E2", border: wallSplitMode.left && wallSplitMode.right ? selectedBorder : "none"}}
                onClick={() => {
                  const wallSplitMode = {left: true, right: true};
                  this.setState({wallSplitMode});
                  onNewWallSplitMode(wallSplitMode);
                  analytics.track("Wall paper applied to full wall");
                }}>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.colorButton}
                style={{backgroundColor: "#AEAEAE", border: !wallSplitMode.left && wallSplitMode.right ? selectedBorder : "none"}}
                onClick={() => {
                  const wallSplitMode = {left: false, right: true};
                  this.setState({wallSplitMode});
                  onNewWallSplitMode(wallSplitMode);
                  analytics.track("Wall paper applied to right");
                }}>
                <div style={{width: "50%", height: "100%", backgroundColor: "#E2E2E2"}}/>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography id="size-slider" gutterBottom>
                Size
              </Typography>
              <div style={{width: "90%"}}>
                <Slider
                  track={false}
                  aria-labelledby="track-false-slider"
                  defaultValue={1}
                  step={0.01}
                  max={2}
                  min={0.1}
                  onChange={(e, value) => {
                    onNewScaleValue(value);
                    analytics.track("Wall paper scale changed");
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <p style={{fontSize: 15, color: "#767676"}}>Product Details</p>
                <IconButton aria-label="item_info"
                  onClick={this.props.onWallPaperInfoClick}
                  title = {"Item info"}>
                  <Icon><img alt="info_icon" src="/img/icons/info.svg"/></Icon>
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.imgContainer} style={{width: "100%"}}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center",
                  alignItems: "center", height: "100%", width: "100%", backgroundColor: "#EFEFEF"}}>
                  { wallPaper && wallPaper.item.imgUrl ?
                    <img className={classes.img} src={wallPaper.item.imgUrl} alt={wallPaper.item.Title} loading="lazy"/> :
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                      <CircularProgress disableShrink />
                    </div>
                  }
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{paddingTop: 10, fontSize: 12}}>
                {wallPaper ? wallPaper.item.Title : ""}
              </div>
              <div style={{paddingTop: 10, fontSize: 12, color: "#767676"}}>
                {wallPaper ? wallPaper.item.vendorName : ""}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{paddingTop: 10, display: "flex", justifyContent: "flex-end", fontSize: 12, fontWeight: "bold"}}>
                {wallPaper && wallPaper.item.Price ? "$" + wallPaper.item.Price : ""}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}


export default withStyles(useStyles)(WallPaperPanel);
