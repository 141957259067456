import mixpanel from 'mixpanel-browser';

// Initializing mixpanel
const MIXPANEL_TOKEN = "59a74a414eddfae7fdc320dd3ab6bf3c";
mixpanel.init(MIXPANEL_TOKEN);

// env_check lets you ensure that the analytics are only being send in production.
//let env_check = process.env.NODE_ENV === 'production';
let env_check = true; // temporarily setting this to true always

const analytics = {
    identify: (id) => {
      if (env_check) mixpanel.identify(id);
    },
    register_once: (props) => {
      if (env_check) mixpanel.register_once(props);
    },
    alias: (id) => {
      if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
      if (env_check) mixpanel.track(name, props);
    },
    people: {
      set: (props) => {
        if (env_check) mixpanel.people.set(props);
      },
    },
    track_item_info_click: (clicksource, url, vendor, type) => {
      analytics.track("Item info clicked", {
        'clicksource': clicksource,
        'URL': url,
        "item type": type, 
        "item vendor": vendor
      });
    },
    track_shop_it_click: (clicksource, url, vendor, type) => {
      analytics.track("Shop it clicked", {
        'clicksource': clicksource,
        'URL': url,
        "item type": type, 
        "item vendor": vendor
      });
    }
  };
  export default analytics;

