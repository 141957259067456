import React, { Component } from "react";
import ProductInfoPanel from "./dashboard/ProductInfoPanel.js";
import WallPaperPanel from "./dashboard/WallPaperPanel.js";
import MoodBoard from "./dashboard/MoodBoard.js";
import RoomSetup from "./dashboard/RoomSetup.js";
import cloudFunctionsFirebase from "./CloudFunctionsFirebase.jsx";
import { Icon, Button, IconButton, Snackbar, Dialog, CircularProgress, DialogContent, Drawer, Toolbar,
  Tabs, Tab, ImageList, ImageListItem, ImageListItemBar, Menu, MenuItem, Typography, Fade } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/app';
import AdditionPanel from "./dashboard/AdditionPanel.js";
import SharePopover from "./dashboard/SharePopover.js";
import LayerPopover from "./dashboard/LayerPopover.js";
import SharePreview from "./dashboard/SharePreview.js";
import ExplorePanel from "./dashboard/ExplorePanel.js";
import Swal from 'sweetalert2';
import analytics from './analytics/Analytics';
import Navbar from "./layout/Navbar";
import path from 'path';
import clsx from 'clsx';
import Confetti from 'react-confetti'
import { Steps } from 'intro.js-react';
import './introjs.css';
import './index.sass';
import $ from 'jquery'
import _ from 'lodash';


const db = firebase.firestore();
// if (window.location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }

const mainBGColor = "#F0F2F5";  //other option "#F9F7F2";
const flattenedScale = 1.33;

const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'serif'
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      }
    },
    MuiTab: {
      root: {
        lineHeight: 1,
        minWidth: 0,
        '@media (min-width: 600px)': {
          minWidth: 0,
        },
      },
      wrapper: {
        textTransform: 'none',
        fontSize: '10px',
      },
      textColorSecondary: {
        color: 'white',
      }
    },
    MuiImageListItem: {
      item: {
        borderRadius: '10px',
        cursor: 'pointer',
        boxSizing: 'border-box',
      }
    },
    MuiImageListItemBar: {
      root: {
        backgroundColor: 'rgb(0, 0, 0, 0)',
      }
    }
  }
});

const styles = theme => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    width: '90%'
  },
  modalButtonBar: {
    outline: 0,
    backgroundColor: "#fff",
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  paper: {
    outline: 0,
    backgroundColor: "#fff",
    padding: theme.spacing(0, 4, 2),
    overflowY:'auto',
    display: 'block',
    flexWrap: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: '2px solid slategrey'
    }
  },
  root: {
    outline: 0,
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '10px',
    paddingBottom: '10px',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: "#fff",
  },
  onboardDialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: '30px',
    paddingLeft: '0px',
    paddingRight: '0px',
    width: "100%"
  },
  chromeExtButton: {
    width: "100%",
    height: "50px",
    boxShadow: "none",
    color: "#FFFFFF",
    marginTop: "30px",
    backgroundColor: "#741CE5",
    '&:hover': {
      backgroundColor: '#5412A9'
    }
  },
  drawer: {
    flexShrink: 0,
  },
  drawerOpen: {
    width: 400,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
  },
  snackBar: {
    backgroundColor: "#CDCDCD",
    color: "#000"
  },
  overlay: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ctrlButton: {
    height: '100%',
    borderRadius: '5px',
    backgroundColor: '#F0F2F5',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#DAD9D9'
    }
  },
  addItemButton: {
    marginRight: '5px',
    height: '35px',
    width: '35px',
    borderRadius: '5px',
    backgroundColor: '#F0F0F0',
    whiteSpace: 'nowrap',
  },
  textButton: {
    paddingTop: "12px",
    paddingBottom: "12px",
    height: '90%',
    borderRadius: '5px',
    backgroundColor: '#F0F2F5',
    whiteSpace: 'nowrap',
    color: '#000000',
    fontSize: 13,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#DAD9D9'
    }
  },
  totalCostInfo: {
    color: "#000000",
    display: 'flex',
    fontSize: '16px',
    alignItems: "center",
    paddingLeft: '40px',
    whiteSpace: 'nowrap'
  },
  leftPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: mainBGColor,
    width: '400px',
    minWidth: '400px',
  },
  leftPanelTabs: {
    minWidth: 50,
    width: 50,
    borderRight: "1px solid #DDDDDD",
    "& .MuiTabs-indicator": {
      display: "none"
    },
    "& .Mui-selected": {
      backgroundColor: "#FFFFFF",
      "& .tabIcon": {
        filter: "invert(22%) sepia(74%) saturate(7469%) hue-rotate(266deg) brightness(88%) contrast(104%)"
      }
    }
  },
  tabPanels: {
    backgroundColor: "#FFFFFF",
    borderRight: "1px solid #DDDDDD",
    width: '350px',
  },
  icon: {
    color: 'white',
  },
  shareButton: {
    backgroundColor: '#741CE5',
    color: "#FFF",
    borderRadius: '5px',
    height: '35px',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginLeft: '10px',
    fontSize: '15px',
    '&:hover': {
      bottom: "1px",
      right: "1px",
      background: '#6616CC',
      boxShadow: '3px 3px 0px 0px #F9B1AA',
    },
    '&:active': {
      bottom: "0px",
      right: "0px",
      boxShadow: 'none',
      backgroundColor: '#741CE5'
    }
  },
  shareButtonMobile: {
    backgroundColor: '#741CE5',
    color: "#FFF",
    borderRadius: '5px',
    height: '40px',
    paddingLeft: '15px',
    paddingRight: '15px',
    width: '100%',
    marginLeft: '10px',
    fontSize: '18px'
  },
  itemTile: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    padding: '0px',
    borderRadius: '5px',
    backgroundColor: "white",
    justifyContent: 'space-between',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.3em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '0px solid slategrey'
    }
  }
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    borderRadius: "10px",
    boxShadow: "none",
    backgroundColor: "#741CE5",
    '&:hover': {
      backgroundColor: "#8473EE",
    }
  },
}))(Button);


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={"vertical-tabpanel" + index}
      aria-labelledby={"vertical-tabpanel" + index}
      {...other}
    >
      {(
        children
      )}
    </div>
  );
}

function isIpadOS() {
  return navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    (/MacIntel/.test(navigator.platform) || (/Ipad/.test(navigator.platform)));
}

const withMediaQuery = () => Component => props => {
  const isMobilePh = useMediaQuery('(max-width: 480px)');
  const isTabletOS = isIpadOS();
  return <Component isMobile={isMobilePh} isTablet={isTabletOS} {...props} />;
};

const ImageErrors = Object.freeze({"WAIT": -1, "OK": 0, "NOBG":1, "NOIMG":2, "NORUG": 3})

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ctrlPanelTab: window.localStorage.needOnboarding === 'true' ? 1 : 0,
      onboardStepIdx: 0,
      floorColor: "hardwood_6",
      wallColor: "FFFFFF",
      pageNum: 0,
      showProductPanel: false,
      showSharePanel: false,
      favEnabled: false,
      snackBarOpen: false,
      alternatives: null,
      redirectToLogin: false,
      saveEditsStatus: null,
      overlayOpen: true,
      loadingCollection: true,
      showProductInfoPanel: false,
      tourStepsEnabled: false,
      updateToggle: 0,
      showShoppingCart: false
    };
    this.collectionHistory = [];
    this.snackBarText = "";
    this.container = React.createRef();
    this.boardAspectRatio = 0.67;
    this.structElemIds = {
      "gMWkiYn54CciJopVNBFw": "door1",
      "kpxvoDMBtWPSbgpM6rPK": "door2",
      "ribVe1JUNhw7H46CjBP2": "door3",
      "I8NmDtUO4HBD3zHsUcDM": "door4",
      "o8dpKD1mIrTWMABbFt1R": "door5",
      "kyY7JlFg8rRAyuUbsZ1S": "door6",
      "caddo7FJFswypSqfnD5k": "window1",
      "gmE3EUZqVfxRGa8bWzaB": "window2",
      "pNWDZ3k9cAlmZzOkdzXc": "window3",
      "oJyYTSAdSSE11fylmPUW": "window4",
      "uCd2KJCpOw4tBAJM1JS0": "window5",
      "5fyt3Oi8VSx4DbqQVw8U": "window6",
    }

    this.steps = [
      {
        element: '#moodboardstage',
        intro: "<b>This is your Canvas</b></br></br>Every design starts as an empty canvas with a customizable <b>wall</b> and <b>floor.</b>",
        position: 'left',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        disableInteraction: true,
      },
      {
        element: '#color_grid',
        intro: "Let's first setup the canvas.<br><br>The room setup tab lets you pick wall colors, flooring, and windows to recreate the room.<br><br><img style='width:100%;border-radius:5px' src='/img/emptyroomwithwindow.jpg'/>",
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        disableInteraction: false,
        onAfterChange: () => {
          $('#room_setup_tab').trigger("click");
          $('.introjs-nextbutton').text('Add room features');
          this.setState({ctrlPanelTab: 2});
          this.stepsObj.introJs.refresh();
        },
      },
      {
        element: '#explorepanel',
        intro: "Awesome! <br><br> You can now find more products from our curated collection of furniture and decor.",
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        disableInteraction: true,
        onBeforeChange: () => {
          const { structElem2ItemData } = this.state;
          // {id: "pNWDZ3k9cAlmZzOkdzXc", xPos: 0.189, yPos: -0.303, scale: 1, z: 9, flipped: false},
          let newItem = structElem2ItemData["window3"];
          newItem.added = true;
          newItem.uuid = uuidv4();
          newItem.flattened = false;

          newItem.xFromAnchor = 0.293621669871238;
          newItem.yFromAnchor = -0.2643473590401623;
          newItem.scale = 1.2;
          newItem.z = 0;

          const newItems = [ newItem ];
          const processed = this.processCollection(newItems);
          const collection = {
            ...this.state.collection,
            items: processed.items,
          };

          this.setState({collection, wallColor: "E1D7C2", floorColor: "hardwood_5"});
          this.triggerSave();
        },
        onAfterChange: () => {
          $('#explore_tab').trigger("click");
          $('.introjs-nextbutton').text('Next');
          this.setState({ctrlPanelTab: 0});
          this.stepsObj.introJs.refresh();
        },
      },
      {
        element: '#add_item_button',
        intro: "You can also save product images from anywhere online.<br><br>Dabble magically removes the background on any product image you save.<img style='width:100%;border-radius:5px; margin-top:20px;' src='/img/bgremoveanim.gif'/>",
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        disableInteraction: true,
        onAfterChange: () => {
          $('#wishlist_tab').trigger("click");
          this.setState({ctrlPanelTab: 1})
          this.stepsObj.introJs.refresh();
        },
      },
      {
        element: '#additionpanel',
        intro: "Any products you save show up in the Saved Items tab.<br><br>We've added a few sample products so you can start designing right away.",
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        disableInteraction: true,
      },
      {
        element: '#wishlist_grid_' + window.localStorage.sampleItem1,
        intro: "Let's first find a couch similar to the one in our room.<br><br>Drag the couch into the canvas",
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        disableInteraction: false,
        onAfterChange: () => {
          $('.introjs-nextbutton').hide();
        },
      },
      {
        element: '#moodboardstage',
        intro: "Awesome, we've recreated our room!<br><br><img style='width:100%;border-radius:5px' src='/img/emptyroomwithwindow.jpg'/><br><br>PS: you can visit any product store link from the toolbar above the canvas",
        position: 'left',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        disableInteraction: true,
      },
      {
        element: '#moodboardstage',
        intro: "Now let's add the remaining saved items to see how it all looks together!",
        position: 'left',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        disableInteraction: true,
        onAfterChange: () => {
          $('.introjs-nextbutton').text('Add remaining items');
        },
      },
      {
        element: '#moodboardstage',
        intro: "That.<br>Looks.<br>INCREDIBLE!",
        position: 'left',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        disableInteraction: false,
        onAfterChange: () => {
          $('.introjs-nextbutton').text('Next');
          this.fillInSampleBoard();
        },
      },
      {
        element: '#share_button',
        intro: "Once you're done, create a <b>shareable link</b> to your design with clickable product links!<br><br><a href='https://app.dabble.so/share/"
          + window.localStorage.sampleBoardId + "' target='_blank'><u style='color:white;'>View share page</u></a>",
        position: 'bottom',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
        disableInteraction: true,
        onAfterChange: () => {
          $('.introjs-skipbutton').show();
          $('.introjs-nextbutton').hide();
          window.localStorage.removeItem("sampleItem1");
        },
      },
    ];

    this.stepsObj = React.createRef();
    this.pageLimit = 26;

    const itemTypesLiteRef = db.collection("itemtypeslite/");
    this.itemTypesLite = {};
    itemTypesLiteRef.get()
    .then(documents => {
      documents.forEach(doc => {
        this.itemTypesLite[doc.id] = doc.data();
      });
    });
  }

  async initItems(items) {
    return await Promise.all(items.map(async (item) => {
      item.uuid = uuidv4();
      let result = await cloudFunctionsFirebase.getImageSignedUrl(`ecommercelink/${item.Id}/image1.png`);
      item.imgUrl = result.url;

      if ((item.Depthtype === "rug" && typeof item.flattened == 'undefined') || item.flattened) {
        // legacy item marked a rug but flattened setting not set. assume flattened
        item.flattened = true;

        if (!item.scale) {
          item.scale = flattenedScale;
        }
      }
      return item;
    }));
  }

  async updateCollectionLastOpened(colId) {
    const colRef = db.collection("collections").doc(colId);
    const LastOpened = Date.now();
    await colRef.update({LastOpened});
    const newCols = this.state.collections.map(col => {
      if (col.id === colId) {
        col.LastOpened = LastOpened;
      }
      return col;
    });
    this.setState({collections: newCols});
  }

  // Initialize the board. Runs after authentication.
  initialize(user) {
    if (!this.props.match.params.colId) {
      this.setState({overlayOpen: false})
      if (user) {
        this.props.history.push("/Favorites");
      } else {
        this.props.history.push("/Login");
      }
      return;
    }

    let collectionId = this.props.match.params.colId;
    const tourStepsEnabled = collectionId === window.localStorage.sampleBoardId;
    //const needOnboarding = window.localStorage.needOnboarding === 'true';
    window.localStorage.removeItem("sampleBoardId");
    window.localStorage.removeItem("needOnboarding");
    //const showResumeTut = !tourStepsEnabled && needOnboarding;
    const showResumeTut = false;

    // if collection Id matches sample board, show sample board
    this.setState({
      currentCollectionId: collectionId,
      tourStepsEnabled,
      showResumeTut,
    });

    cloudFunctionsFirebase.getCollectionEcommerceLinksFirestore(collectionId, async (data) => {
      if (data) {
        data.id = collectionId;
        data.items = await this.initItems(data.items);

        this.setState({overlayOpen: false});
        if (!user || data.owner !== user.uid) {
          this.setState({redirectToShare: true, collection: data});
        }
        else {
          this.setState({collection: data});
        }

        const processed = this.processCollection(data.items);
        data.items = processed.items;

        if (data.colorParams) {
          this.setState({
            wallColor: data.colorParams.wallColor,
            floorColor: data.colorParams.floorColor
          });

          const { wallPaper } = data.colorParams;
          if (wallPaper && wallPaper.item) {
            const doc = await db.collection('ecommercelink').doc(wallPaper.item.Id).get();
            const itemData = doc.data();
            if (itemData.Vendor) {
              const vendorDoc = await itemData.Vendor.get();
              const vendorName = vendorDoc.data();
              if (vendorName && vendorName.Name !== "unsupported")  {
                itemData.vendorName = vendorName.Name;
              }
              else {
                itemData.vendorName = " ";
              }
            }
            else {
              itemData.vendorName = " ";
            }

            itemData.Id = doc.id;
            const imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(
              `ecommercelink/${itemData.Id}/image1.png`);
            itemData.imgUrl = imgUrl.url;
            wallPaper.item = itemData;

            this.setState({wallPaper});
          } else {
            this.setState({wallPaper: null});
          }
        }
        this.setState({collection: data, loadingCollection: false});
        analytics.track("Collection opened", {"collectionId" : collectionId});
      }
      else {
        console.log("failed to get collection!?");
      }

      this.setState({overlayOpen: false, loadingCollection: false});
    })

    if (!user) {
      return;
    }

    // fetch curated products
    cloudFunctionsFirebase.getCuratedItems(null, null, this.pageLimit, 0, false, (itemsList) => {
      this.setState({exploreProducts: itemsList});
    });

    // add sub to db event, and update it when new items comes in
    db.collection("users/").doc(user.uid).onSnapshot(async (doc) => {
      const userData = doc.data();
      if (!userData || !userData.bookmarks) {
        return;
      }

      userData.id = user.uid;
      this.setState({userData});

      const bookmarks = userData.bookmarks;
      const colIds = Object.keys(bookmarks.collections);
      Promise.all(colIds.map(async (colId) => {
        const colRef = db.collection("collections").doc(colId);
        const colDoc = await colRef.get();
        const colData = colDoc.data();
        const collection = {
          id: colId,
          bookmarkId: userData.bookmarks.moodboards[colId],
          LastOpened: colData && colData.LastOpened ? colData.LastOpened : 0,
        };

        if (collection.bookmarkId) {
          let imgUrl
          if (collection.bookmarkId.includes('jpg')) {
            imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`bookmarks/${collection.bookmarkId}`);
          }
          else {
            imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`bookmarks/${collection.bookmarkId}.png`);
          }
          collection.imgUrl = imgUrl.url;
        }

        return collection;
      })).then((collections) => {
        this.setState({collections});
        this.updateCollectionLastOpened(this.state.currentCollectionId);
      });

      const newItems = await Promise.all(Object.keys(bookmarks.items).map(async (itemId) => {
        const bmItemRef = db.collection('ecommercelink').doc(itemId);
        const ind = this.state.wishlist ? this.state.wishlist.findIndex(item => item.Id === itemId) : -1;

        let newItem = null;
        if (ind < 0) {
          // new item found! Add that to the state
          newItem = (await bmItemRef.get()).data();

          if (!newItem) {
            console.log("item", itemId, " is no longer available");
            cloudFunctionsFirebase.removeBookmarkItemFirestore(itemId, () => {})
            return undefined;
          }

          newItem.Id = itemId;
          if (bookmarks.itemBookmarkDates && newItem.Id in bookmarks.itemBookmarkDates) {
            newItem.BookmarkedStamp = bookmarks.itemBookmarkDates[newItem.Id];
          } else {
            newItem.BookmarkedStamp = newItem.Created;
          }

          if (newItem.Vendor) {
            const vendorDoc = await newItem.Vendor.get();
            const vendorName = vendorDoc.data();
            if (vendorName && vendorName.Name !== "unsupported")  {
              newItem.vendorName = vendorName.Name;
            } else {
              newItem.vendorName = " ";
            }
          } else {
            newItem.vendorName = " ";
          }
        } else {
          newItem = this.state.wishlist[ind];
        }

        if (!newItem.imgUrl) {
          const imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`ecommercelink/${newItem.Id}/image1.png`);
          if (!imgUrl.url) {
            // there's no bg removed image
            if (newItem.origImgUrl) {
              // if the original image exists, set it to that.
              newItem.imgUrl = newItem.origImgUrl;
              newItem.imageType = ImageErrors.NOBG // no bg removed image.
            } else {
              // no images available. show error image.
              // Check imageprocessing status. Need to update from firebase because of weird firebase bug
              const updatedItemListingRef = db.collection('ecommercelink').doc(itemId);
              const updatedItemListing = (await updatedItemListingRef.get()).data();

              if (updatedItemListing.ImageProcComplete === true) {
                // image processing is complete but no images are available. show error.
                newItem.imgUrl = '/img/noimg.jpg'
                newItem.imageType = ImageErrors.NOIMG //bad (rare) situation, no image available
                console.error ("No saved images found for id: " + newItem.Id + ". Please investigate!")
              } else {
                // still waiting for image processing to complete
                newItem.imageType = ImageErrors.WAIT
              }
            }
          } else {
            // default case, all good.
            newItem.imgUrl = imgUrl.url;
            newItem.imageType = ImageErrors.OK
          }
        }

        // Check to make sure the item type is set.
        if (!newItem.PredictedType && !newItem.Type) {
          // Just get the item again from Firebase once it's finalized.
          let updatedItem = (await bmItemRef.get()).data();

          if (updatedItem.PredictedType) {
            newItem.PredictedType = updatedItem.PredictedType;
          }

          if (updatedItem.TypeConfidence) {
            newItem.TypeConfidence = updatedItem.TypeConfidence;
          }

          if (updatedItem.ConfidenceRanking) {
            newItem.ConfidenceRanking = updatedItem.ConfidenceRanking;
          }

          if (updatedItem.TypeRanking) {
            newItem.TypeRanking = updatedItem.TypeRanking;
          }
        }

        return newItem;
      }));

      const filteredItems = newItems.filter(function (el) {
        return el !== undefined;
      });

      this.setState({loading: false, wishlist: filteredItems})
    });
  }

  componentWillMount(){
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
  }

  // handle authentication state changed
  onAuthStateChanged = (user) => {
    if (user) {
      console.log("logged in");
      user.getIdToken().then((idToken) => {
        window.sessionStorage.token = idToken;
        this.initialize(user);
      });
    }
    else {
      console.log("logged out");
      this.initialize();
    }
  };

  checkSize = async () => {
    const maxStageHeight = Math.max(200, window.screen.height * 0.75);
    const maxStageWidth = Math.max(300, window.screen.width * 0.75);

    let screenScale = Math.max(Math.min(window.innerHeight / window.screen.height,
      window.innerWidth / window.screen.width), 0.1); //clamping the lower end to 0.8, otherwise ipads aspect ratio makes it looks worse.

    let stageWidth = 0, stageHeight = 0;
    const widthForMaxHeight = maxStageHeight / this.boardAspectRatio;
    if (widthForMaxHeight <= maxStageWidth) {
      stageHeight = maxStageHeight * screenScale;
      stageWidth = stageHeight / this.boardAspectRatio;
    } else {
      stageWidth = maxStageWidth * screenScale;
      stageHeight = stageWidth * this.boardAspectRatio;
    }

    this.setState({
      stageWidth,
      stageHeight,
      screenScale,
    });

    if (this.state.collection) {
      const processed = this.processCollection(this.state.collection.items);
      const collection = {
        ...this.state.collection,
        items: processed.items,
      };
      this.setState({
        collection,
      });
    }
  };

  onBackButtonEvent = (e) => {
    e.preventDefault();
    if (window.location.toString().includes('/design/')) { //if someone hit back and got to a design page, just reload the page to make sure the new design loaded.
      window.location.reload();
    }
  }

  // Load the spacebar handlers and pinterest share script after the react component mounts
  async componentDidMount(){
    // observer checking for resize of container element. thought i would have to connect
    // it to moodboard not container... but it works this way??
    const observer = new ResizeObserver(entries => {
      this.checkSize();
    });
    this.container = document.getElementById("container");
    observer.observe(this.container);

    document.addEventListener("keyup", this.onDeleteButtonHit, false);
    document.addEventListener("keydown", this.onArrowButtonHit, false);

    //watch back button clicks
    window.addEventListener('popstate', this.onBackButtonEvent);

    // Crisp script to insert chat button in DOM
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "35b26049-d6bc-4c18-b088-4124b59851ea";
    (function() {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onDeleteButtonHit, false);
    document.removeEventListener("keydown", this.onArrowButtonHit, false);
  }

  onDeleteButtonHit = (event) => {
    if (event.type !== 'touchend' && event.keyCode !== 46 && event.keyCode !== 8) {
      return;
    }

    if (this.state.itemSelected) {
      this.onDeleteItemClick();
    } else if (this.state.itemGroupSelected) {
      this.onDeleteGroupClick();
    }
  }

  onAddDesignClicked = () => {
    const collection = {
      name: uuidv4(),
      type: "livingroom",
      items: [],
    };

    return cloudFunctionsFirebase.createNewCollectionFirestore(collection,
      "FFFFFF", "hardwood_4", (result) => {
        if (result) {
          // Analytics Event: new collection created
          analytics.track('New collection created', {
            "collection id": result.newCollectionId
          });
          window.location.replace("/design/" + result.newCollectionId);
          return true;
        } else {
          Swal.fire('Failed to create new collection', '', 'error');
        }
      }
    );
  }

  onArrowButtonHit = async (event) => {
    if (!this.state.itemSelected) {
      return;
    }

    let dx = 0;
    let dy = 0;
    switch (event.keyCode) {
      // left
      case 37:
        dx = -5;
        break;
      // up
      case 38:
        dy = -5;
        break;
      // right
      case 39:
        dx = 5;
        break;
      // down
      case 40:
        dy = 5;
        break;
      default:
        return;
    }

    event.preventDefault();

    const newItems = this.state.collection.items.map((item) => {
      if (item.uuid === this.state.itemSelected.uuid) {
        item.x += dx;
        item.y += dy;
        item.xFromAnchor = (item.x + item.imageWidth / 2 - this.state.stageWidth / 2) / this.state.stageWidth;
        item.yFromAnchor = (item.y + item.imageHeight / 2 - this.state.stageHeight / 2) / this.state.stageHeight;
      }
      return item;
    });

    const processed = this.processCollection(newItems);
    const collection = {
      ...this.state.collection,
      items: processed.items,
    };
    this.setState({collection});
    this.triggerSave();
  }

  // Script to share on Pinterest
  preloadWidgetScript = () => {
    const script = document.createElement('script');
    script.async = true;
    script.dataset.pinBuild = 'doBuild';
    script.src = '//assets.pinterest.com/js/pinit.js';
    document.body.appendChild(script);
  };

  // function sent as prop to RoomSetup
  onWallColorPicked = (color) => {
    this.setState({wallColor: color});
    analytics.track("Wall Color Changed", {"color": color})
    this.triggerSave();
  };

  // function sent as prop to RoomSetup
  onFloorColorPicked = (color) => {
    this.setState({floorColor : color});
    analytics.track("Floor Color Changed", {"color": color})
    this.triggerSave();
  };

  handleStylePanelClose = () => {
    this.setState({showStylePanel : false});
  };

  onClickItem = async (e, item) => {
    if (!e.evt || !e.evt.shiftKey) {
      this.setState({
        itemSelected: item,
        itemGroupSelected: null,
        showProductInfoPanel: false,
        showWallPaperPanel: false,
        showShoppingCart: false,
        additionSelected: null,
      });
    } else {
      let { itemGroupSelected, itemSelected } = this.state;
      if (!itemGroupSelected) {
        itemGroupSelected = {};
        if (itemSelected) {
          itemGroupSelected[itemSelected.uuid] = itemSelected;
        }
      }
      itemGroupSelected[item.uuid] = item;
      const newItemGroup = {...itemGroupSelected};
      this.setState({itemGroupSelected: newItemGroup, itemSelected: null});
    }

    if (this.state.tourStepsEnabled) {
      this.stepsObj.introJs.goToStepNumber(this.state.onboardStepIdx + 2);
    }
  };

  onDblClickItem = async (item) => {
    console.log("Item double clicked", item);
    analytics.track_item_info_click('moodboard double click', item.URL, item.vendorName, item.Depthtype)
    this.setState({additionSelected: null, itemSelected: item, showProductInfoPanel: true});
  }

  onDeselectItem = async () => {
    this.setState({itemSelected: null, showProductInfoPanel: false, showWallPaperPanel: false, itemGroupSelected: null});
  };

  handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackBarOpen: false
    });
  };

  showSnack() {
    if (this.snackInterval) {
      clearInterval(this.snackInterval);
    }

    this.snackInterval = setInterval(this.handleSaveEditSnackbarClose, 3000);
  }

  handleSaveEditSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    clearInterval(this.snackInterval);
    this.setState({
      saveEditsStatus: null
    })
  };

  updateCollectionWithFlattened = (itemIdx, toFlatten) => {
    const items = this.state.collection.items;

    // send item to layer 0, alongside all other flattened items.
    toFlatten.yFromAnchor = 0.15;
    toFlatten.z = 0;
    toFlatten.scale = flattenedScale;

    let flattenIdx = 0;
    for (const idx in items) {
      if (!items[idx].flattened) {
        flattenIdx = parseInt(idx);
        break;
      }
    }

    items.splice(itemIdx, 1);
    items.splice(flattenIdx, 0, toFlatten);
    const processed = this.processCollection(items);
    const collection = {
      ...this.state.collection,
      items: processed.items,
    };

    this.setState({collection, saveEditsStatus: "Done..", updateToggle: !this.state.updateToggle});
    this.showSnack();
  }

  handleFlatten = async () => {
    const itemIdx = this.state.collection.items.findIndex(
      item => item.uuid === this.state.itemSelected.uuid);
    if (itemIdx < 0) {
      console.error("item not found for flattening op", this.state.itemSelected);
      return;
    }

    this.setState({saveEditsStatus: "Processing..."});

    const items = this.state.collection.items;
    const toFlatten = items[itemIdx];

    toFlatten.flattened = !Boolean(toFlatten.flattened); //toggle flattened-ness
    this.state.itemSelected.flattened = toFlatten.flattened;

    if (toFlatten.flattened) {
      // is a skewed/flattened image already available?
      this.updateCollectionWithFlattened(itemIdx, toFlatten);
      this.triggerSave();
    } else {
      // unflattening an item, just delete and add it back in
      this.deleteItem(toFlatten);
      // remove all rug related sizing from the listing
      const itemUnflattened = _.omit(toFlatten, ['scale','x', 'y', 'z', 'xFromAnchor', 'yFromAnchor', 'imageHeight', 'imageWidth', 'aspectRatio','bottom']);
      itemUnflattened.flattened = false;
      this.handleAddItem(itemUnflattened, null, null)
    }
  }

  handleTileWallpaper = () => {
    this.setState({
      wallPaper: {item: this.state.itemSelected, scale: 1, left: true, right: true},
      showWallPaperPanel: true,
    });
    this.onDeleteItemClick();
  }

  handleAllTheWayFront = () => {
    const itemIdx = this.state.collection.items.findIndex(
      item => item.uuid === this.state.itemSelected.uuid);
    if (itemIdx < 0) {
      console.error("item notfound for all the way front op", this.state.itemSelected);
      return;
    }

    if (itemIdx === this.state.collection.items.length - 1) {
      console.log("already at forefront");
      return;
    }

    console.log("item layer to the forefront", this.state.collection.items[itemIdx]);

    // Analytics event: item dragged
    const collectionId = this.state.currentCollectionId;
    analytics.track('Item changed layer in collection', {
      "collection id": collectionId,
      "item id": this.state.collection.items[itemIdx].Id,
      "instance id": this.state.collection.items[itemIdx].uuid
    });

    const items = this.state.collection.items;
    const toFront = items[itemIdx];
    items.splice(itemIdx, 1);
    items.push(toFront);

    const newItems = items.map((item, idx) => {
      item.z = idx;
      return item;
    });

    const collection = {
      ...this.state.collection,
      items: newItems,
    };

    this.setState({collection});
    this.triggerSave();
  };

  handleAllTheWayBack = () => {
    const itemIdx = this.state.collection.items.findIndex(
      item => item.uuid === this.state.itemSelected.uuid);
    if (itemIdx < 0) {
      console.error("item notfound for forward once op", this.state.itemSelected);
      return;
    }

    if (itemIdx === 0) {
      console.log("already at the back");
      return;
    }

    console.log("item layer allTheWayBack", this.state.collection.items[itemIdx]);

    // Analytics event: item dragged
    const collectionId = this.state.currentCollectionId;
    analytics.track('Item changed layer in collection', {
      "collection id": collectionId,
      "item id": this.state.collection.items[itemIdx].Id,
      "instance id": this.state.collection.items[itemIdx].uuid
    });

    const items = this.state.collection.items;
    const toBack = items[itemIdx];
    items.splice(itemIdx, 1);
    items.splice(0, 0, toBack);

    const newItems = items.map((item, idx) => {
      item.z = idx;
      return item;
    });

    const collection = {
      ...this.state.collection,
      items: newItems,
    };

    this.setState({collection});
    this.triggerSave();
  };

  handleAllTheWayFrontGroup = () => {
    const { itemGroupSelected, currentCollectionId, collection } = this.state;
    Object.values(itemGroupSelected).forEach(item => {
      const itemIdx = collection.items.findIndex(
        oldItem => oldItem.uuid === item.uuid);
      analytics.track('Item changed layer in collection', {
        "collection id": currentCollectionId,
        "item id": collection.items[itemIdx].Id,
        "instance id": collection.items[itemIdx].uuid
      });

      const toFront = collection.items[itemIdx];
      collection.items.splice(itemIdx, 1);
      collection.items.push(toFront);
    });

    const newItems = collection.items.map((item, idx) => {
      item.z = idx;
      return item;
    });

    const newCollection = {
      ...collection,
      items: newItems,
    };

    this.setState({collection: newCollection});
    this.triggerSave();
    this.onDeselectItem();
  };

  handleAllTheWayBackGroup = () => {
    const { itemGroupSelected, currentCollectionId, collection } = this.state;
    Object.values(itemGroupSelected).forEach(item => {
      const itemIdx = collection.items.findIndex(
        oldItem => oldItem.uuid === item.uuid);
      analytics.track('Item changed layer in collection', {
        "collection id": currentCollectionId,
        "item id": collection.items[itemIdx].Id,
        "instance id": collection.items[itemIdx].uuid
      });

      const toBack = collection.items[itemIdx];
      collection.items.splice(itemIdx, 1);
      collection.items.splice(0, 0, toBack);
    });

    const newItems = collection.items.map((item, idx) => {
      item.z = idx;
      return item;
    });

    const newCollection = {
      ...collection,
      items: newItems,
    };

    this.setState({collection: newCollection});
    this.triggerSave();
    this.onDeselectItem();
  };

  compareId(a, b) {
    if (a.Id < b.Id) {
      return -1;
    }
    if (a.Id > b.Id) {
      return 1;
    }
    return 0;
  }

  triggerSave() {
    if (!this.state.userData) {
      return;
    }

    if (this.state.userData.id !== this.state.collection.owner) {
      console.log("user ID and owner doens't match", this.state.userData.id, this.state.collection.owner);
      return;
    }

    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(this.onApplyEditClick, 1000);
    const collections = this.state.collections.map((collection) => {
      if (collection.id === this.state.currentCollectionId) {
        delete collection["imgUrl"];
      }
      return collection;
    })
    this.setState({saveEditsStatus: "Saving...", collections});
  }

  handleProductPanelClose = (showsnackbar = false) => {
    analytics.track ("Product Panel Closed")
    if (showsnackbar) {
      this.snackBarText = "Product changes saved!"
      this.setState({snackBarOpen: true });
    }
    else {
    }

    this.setState({additionSelected: null, showProductInfoPanel: false, itemSelected: null});
  }

  handleAddItem = async (item, dropX, dropY) => {
    if (!item) {
      console.error("null item!?", item);
      return;
    }

    let newItem = {...item, deleted: false, added: true, uuid: uuidv4()};
    if (dropX && dropY) {
      newItem.xFromAnchor = (dropX - this.state.stageWidth  / 2) / this.state.stageWidth;
      newItem.yFromAnchor = (dropY - this.state.stageHeight / 2) / this.state.stageHeight;
    }
    else {
      newItem.xFromAnchor = -0.1;
      newItem.yFromAnchor = -0.1;
    }

    if (typeof newItem.flattened === 'undefined' && newItem.Depthtype === 'rug') {
      newItem.flattened = true;
      newItem.scale = flattenedScale;
    }

    const newItems = this.state.collection.items.map((item) => item);
    newItems.push(newItem);

    const processed = this.processCollection(newItems);
    const collection = {
      ...this.state.collection,
      items: processed.items,
    };

    this.setState({collection, itemSelected: newItem});
    this.triggerSave();

    // Analytics: item added to moodboard
    const collectionId = this.state.currentCollectionId;

    analytics.track('Item added to collection', {
      "collection id": collectionId,
      "item id": newItem.Id,
      "instance id": newItem.uuid
    });
  };

  onDeleteWallPaper = () => {
    this.setState({wallPaper: null, showWallPaperPanel: false});
    this.triggerSave();
  }

  deleteItem (itemSelected) {
    itemSelected.deleted = true;
    const newItems = this.state.collection.items.map((item) => {
      if (item.uuid === itemSelected.uuid) {
        item.deleted = true;
      }
      return item;
    });
    console.log("deleting item", this.state.collection.items);

    const processed = this.processCollection(newItems);
    const collection = {
      ...this.state.collection,
      items: processed.items,
    };
    this.setState({collection, itemSelected: null});
  }

  onDeleteItemClick = () => {
    const itemSelected = this.state.itemSelected;
    if (this.state.showProductInfoPanel) {
      this.setState({showProductInfoPanel: false});
    }

    // Adding analytics event on top because data is deleted later
    const collectionId = this.state.currentCollectionId;
    analytics.track('Item deleted from collection', {
      "collection id": collectionId,
      "item id": itemSelected.Id,
      "instance id": itemSelected.uuid
    });

    this.deleteItem(itemSelected)
    this.triggerSave();
  };

  onDeleteGroupClick = () => {
    const itemGroupSelected = this.state.itemGroupSelected;

    const { currentCollectionId, collection } = this.state;
    Object.values(itemGroupSelected).forEach(item => {
      const itemIdxToDelete = collection.items.findIndex(
        oldItem => oldItem.uuid === item.uuid);
      collection.items[itemIdxToDelete].deleted = true;

      analytics.track('Item deleted from collection', {
        "collection id": currentCollectionId,
        "item id": item.Id,
        "instance id": item.uuid,
      });
    });

    const processed = this.processCollection(collection.items);
    const newCollection = {
      ...collection,
      items: processed.items,
    };
    this.setState({collection: newCollection, itemGroupSelected: null});
    this.triggerSave();
  };

  onFlipItemClick = async () => {
    console.log("flipping", this.state.itemSelected);
    console.log("collection items", this.state.collection.items);
    const itemSelected = this.state.itemSelected;
    const itemIdxToFlip = this.state.collection.items.findIndex(
      item => item.uuid === itemSelected.uuid);
    if (itemIdxToFlip >= 0) {
      console.log("flipping original item", this.state.collection.items[itemIdxToFlip]);
      const newItems = this.state.collection.items.map((item) => {
        if (item.uuid === this.state.itemSelected.uuid) {
          item.flipped = item.flipped ? false : true;;
          console.log("flipping", item);
        }
        return item;
      });

      const processed = this.processCollection(newItems);
      const collection = {
        ...this.state.collection,
        items: processed.items,
      };
      this.setState({collection});
      this.triggerSave();

      // Analytics event: item flipped
      const collectionId = this.state.currentCollectionId;
      analytics.track('Item flipped in collection', {
        "collection id": collectionId,
        "item id": itemSelected.Id,
        "instance id": itemSelected.uuid
      });
    } else {
      const swappedPairs = Object.entries(this.state.alternatives).filter(
        (altPair) => altPair[1].uuid === this.state.itemSelected.uuid);
      if (!swappedPairs) {
        return;
      }

      console.log("flipping alternative");
      const origId = swappedPairs[0][0];
      const newItems = this.state.collection.items.map((item) => {
        if (item.uuid === origId) {
          item.flipped = item.flipped ? false : true;;
          console.log("flipping swapped item", item);
        }
        return item;
      });
      const processed = this.processCollection(newItems);
      const collection = {
        ...this.state.collection,
        items: processed.items,
      };
      this.setState({collection});
      this.triggerSave();
    }
  };

  onDraggedGroup = async (draggedItems) => {
    const { collection } = this.state;
    draggedItems.forEach(item => {
      const itemIdxToDrag = collection.items.findIndex(
        oldItem => oldItem.uuid === item.uuid);
      if (itemIdxToDrag >= 0) {
        // Analytics event: item dragged
        const collectionId = this.state.currentCollectionId;
        analytics.track('Item dragged in collection', {
          "collection id": collectionId,
          "item id": collection.items[itemIdxToDrag].Id,
          "instance id": collection.items[itemIdxToDrag].uuid
        });

        collection.items[itemIdxToDrag].xFromAnchor = item.xFromAnchor;
        collection.items[itemIdxToDrag].yFromAnchor = item.yFromAnchor;
      }
    });

    const processed = this.processCollection(collection.items);
    const newCollection = {
      ...collection,
      items: processed.items,
    };

    this.setState({collection: newCollection});
    this.triggerSave();
  };

  onDraggedItem = async (itemUuid, xFromAnchor, yFromAnchor) => {
    const itemIdxToDrag = this.state.collection.items.findIndex(
      item => item.uuid === itemUuid);
    if (itemIdxToDrag >= 0) {
      // Analytics event: item dragged
      const collectionId = this.state.currentCollectionId;
      analytics.track('Item dragged in collection', {
        "collection id": collectionId,
        "item id": this.state.collection.items[itemIdxToDrag].Id,
        "instance id": this.state.collection.items[itemIdxToDrag].uuid,
      });

      const newItems = this.state.collection.items.map((item) => {
        if (item.uuid === itemUuid) {
          item.xFromAnchor = xFromAnchor;
          item.yFromAnchor = yFromAnchor;
        }
        return item;
      });
      const processed = this.processCollection(newItems);
      const collection = {
        ...this.state.collection,
        items: processed.items,
      };

      this.setState({collection});
      this.triggerSave();
    }
  };

  onDuplicateItem = async () => {
    const itemToDuplicate = this.state.itemSelected;
    const itemIdxToDuplicate = this.state.collection.items.findIndex(
      item => item.uuid === this.state.itemSelected.uuid);

    if (itemIdxToDuplicate >= 0) {
      // add a new item
      let newItem = {};
      Object.assign(newItem, itemToDuplicate);
      newItem.added = true;
      newItem.uuid = uuidv4();

      newItem.xFromAnchor = itemToDuplicate.xFromAnchor + 0.05;
      newItem.yFromAnchor = itemToDuplicate.yFromAnchor + 0.05;

      const newItems = this.state.collection.items.map((item) => item);
      newItems.forEach((item, index) => {
        newItems[index].z = item.z !== undefined ? item.z : index;
      });
      newItems.push(newItem);

      const processed = this.processCollection(newItems);
      const collection = {
        ...this.state.collection,
        items: processed.items,
      };
      this.setState({collection, itemSelected: newItem});
      this.triggerSave();

      // Analytics event: item flipped
      const collectionId = this.state.currentCollectionId;
      analytics.track('Item duplicated in collection', {
        "collection id": collectionId,
        "item id": itemToDuplicate.Id,
        "instance id": itemToDuplicate.uuid
      });
    }
  };

  onDuplicateGroup = async() => {
    const itemGroupSelected = this.state.itemGroupSelected;

    const { currentCollectionId, collection } = this.state;
    const newGroupSelected = {}
    const newItems = collection.items.map((item) => item);
    Object.values(itemGroupSelected).forEach(item => {
      let newItem = {};
      Object.assign(newItem, item);
      newItem.added = true;
      newItem.uuid = uuidv4();

      newItem.xFromAnchor = item.xFromAnchor + 0.05;
      newItem.yFromAnchor = item.yFromAnchor + 0.05;

      newItems.push(newItem);
      newGroupSelected[newItem.uuid] = newItem;

      analytics.track('Item duplicated from collection', {
        "collection id": currentCollectionId,
        "item id": item.Id,
        "instance id": item.uuid,
      });
    });
    newItems.forEach((item, index) => {
      newItems[index].z = index;
    });

    const processed = this.processCollection(newItems);
    const newCollection = {
      ...collection,
      items: processed.items,
    };
    this.setState({collection: newCollection, itemGroupSelected: newGroupSelected});
    this.triggerSave();
  };

  onScaledItem = async (itemUuid, scale) => {
    const itemIdxToScale = this.state.collection.items.findIndex(
      item => item.uuid === itemUuid);
    if (itemIdxToScale >= 0) {
      console.log("setting item size", this.state.collection.items[itemIdxToScale]);

      // Analytics event: item scaled
      const collectionId = this.state.currentCollectionId;
      analytics.track('Item scaled in collection', {
        "collection id": collectionId,
        "item id": this.state.collection.items[itemIdxToScale].Id,
        "instance id": this.state.collection.items[itemIdxToScale].uuid
      });

      const newItems = this.state.collection.items.map((item) => {
        if (item.uuid === itemUuid) {
          if (!item.scale) {
            item.scale = 1;
          }
          item.scale *= scale;
        }
        return item;
      });
      const processed = this.processCollection(newItems);
      const collection = {
        ...this.state.collection,
        items: processed.items,
      };
      this.setState({collection});
      this.triggerSave();
    }
  };

  onItemImageLoaded = async (itemUuid, image) => {
    const itemIdx = this.state.collection.items.findIndex(
      item => item.uuid === itemUuid);
    if (itemIdx >= 0) {
      const newItems = this.state.collection.items.map((item) => {
        if (item.uuid === itemUuid) {
          item.aspectRatio = image.width / image.height;
        }
        return item;
      });
      const processed = this.processCollection(newItems);
      const collection = {
        ...this.state.collection,
        items: processed.items,
      };
      this.setState({collection});
    }
  };

  onFavoriteItemGettingDragged = async (itemtile) => {
    this.setState({favoriteItemBeingDragged: itemtile})
    if (this.state.tourStepsEnabled) {
      const yPos = this.state.stageHeight / 2 + 0.05 * this.state.stageHeight;
      this.handleAddItem(itemtile, this.state.stageWidth / 2, yPos);
      this.stepsObj.introJs.goToStep(this.state.onboardStepIdx + 2);
      return;
    }

    if (itemtile.imageType === ImageErrors.NOBG) {
      //  background not removed.
      this.snackBarText = "Image Background not removed. Double click on the product to edit image!"
      this.setState({
        snackBarOpen: true,
        favoriteItemBeingDragged: itemtile
      })
    } else if (itemtile.imageType === ImageErrors.NOIMG) {
      this.snackBarText = "Images not saved properly! Delete and clip again. If it still doesn't work, please contact hello@dabble.com. Sorry!"
      this.setState({
        snackBarOpen: true
      })
    } else if (itemtile.imageType === ImageErrors.NORUG) {
      this.snackBarText = "Rug image was not processed correctly. Please delete and clip again. If it still doesn't work, please contact hello@dabble.com. Sorry!"
      this.setState({
        snackBarOpen: true,
        favoriteItemBeingDragged: itemtile //TODO: Should we allow people to drag non-skewed image onto the moodboard. Yes for now.
      })
    } else if (itemtile.imageType === ImageErrors.WAIT ) {
      this.snackBarText ="Images still being processed, please give it a second..."
      this.setState({
        snackBarOpen: true
      })
    } else {
      this.setState({
        favoriteItemBeingDragged: itemtile
      });
    }
  }

  onFavoriteItemGettingDraggedEnd = async () => {
    console.log("drag end" , this.state.favoriteItemBeingDragged.Id)
  }

  onFavItemDeleted = (itemDeleted) => {
    const items = this.state.wishlist.map((item => item));
    const itemIdx = this.state.wishlist.findIndex(
      item => item.Id === itemDeleted.Id);
    if (itemIdx >= 0) {
      items.splice(itemIdx, 1);
    }
    this.setState({wishlist: items});
  }

  onItemUpdated = (itemId, itemUpdatedData) => {
    console.log("itemId:", itemId);
    console.log("itemUpdatedData:", itemUpdatedData);
    const updateItemsData = (itemsToUpdate) => {
      return itemsToUpdate.map((item) => {
        if (item.Id === itemId) {
          if (itemUpdatedData.productName !== undefined) {
            item.Title = itemUpdatedData.productName;
          }

          if (itemUpdatedData.price !== undefined) {
            item.Price = itemUpdatedData.price;
          }

          if (itemUpdatedData.type) {
            item.Depthtype = itemUpdatedData.type;
          }

          if (itemUpdatedData.productURL !== undefined) {
            item.URL = itemUpdatedData.productURL;
          }

          if (itemUpdatedData.imgUrl !== undefined) {
            item.imgUrl = itemUpdatedData.imgUrl;
          }
        }
        return item;
      });
    }

    const newWishlist = updateItemsData(this.state.wishlist);
    const newItems = updateItemsData(this.state.collection.items);
    const collection = {
      ...this.state.collection,
      items: newItems,
    };
    this.setState({wishlist: newWishlist, collection});
  }

  onAdditionClicked = (additionSelected, hideDeleteButton) => {
    if (this.state.tourStepsEnabled) {
      return;
    }

    this.setState({additionSelected, showProductInfoPanel: true, hideDeleteButton});
    analytics.track_item_info_click('moodboard side panel',
      additionSelected.URL, additionSelected.vendorName, additionSelected.Depthtype);
  }

  onApplyEditClick = async () => {
    clearInterval(this.interval);

    // check if the any swapping has been performed, if so, save it as a new collection and add it to bookmark
    const newItems = this.state.collection.items.map((item) => {
      if (item.deleted) {
        return undefined;
      }
      if (item.added) {
        delete item["added"];
      }
      delete item["Vendor"];
      return item;
    });

    let filteredItems = newItems.filter(function (el) {
      return el !== undefined;
    });

    const collectionId = this.state.currentCollectionId;
    const collection = {
      ...this.state.collection,
      items: filteredItems,
      id: collectionId,
    };

    return cloudFunctionsFirebase.overwriteCollectionFirestore(collection,
      this.state.wallColor, this.state.floorColor, this.state.wallPaper, (result) => {
        if (result) {
          window.history.pushState({"pageTitle":""}, "",
            path.join("/design", collection.id));

          this.setState({saveEditsStatus: "Saved!"});
          this.showSnack();

          return true;
        } else {
          Swal.fire('Failed to create new collection', '', 'error');
        }
      }
    );
  };


  fillInSampleBoard() {
    const livingroom = [
      {id: "oH2ky1Y0gI5UCKyj4qzn", xPos: -0.17, yPos: -0.253, scale: 0.434, z: 2, flipped: false},
      {id: "RVepF7Ty18tzsqgCSnG5", xPos: 0.01, yPos: -0.253, scale: 0.434, z: 3, flipped: false},
      {id: "3lKAp9sDwkELPAY9gdbn", xPos: 0.0, yPos: 0.69, scale: 1.2, z: 0, flipped: false},
      {id: "aim3lUpsWONmxjvRWJx9", xPos: -0.3, yPos: 0.0988, scale: 0.323, z: 1, flipped: false},
      {id: "Of6UiU4VXUeTKii5wOsB", xPos: 0.268, yPos: -0.03, scale: 0.82, z: 5, flipped: false},
      {id: "i23bHYniFTZIKOBU2be1", xPos: 0.284, yPos: 0.251, scale: 0.55, z: 6, flipped: false},
      {id: "xG8Gv02fdcezJ0nsved6", xPos: -0.01, yPos: 0.245, scale: 1, z: 7, flipped: false},
      {id: "JSi71TU8ZAgMplK0Z0XM", xPos: -0.29, yPos: -0.037, scale: 0.232, z: 8, flipped: false},
    ];

    const newItems = this.state.collection.items.map(item => item);

    // add items to wishlist
    const itemsToAdd = livingroom;
    itemsToAdd.forEach(toAdd => {
      // getting vendor name
      const itemId = toAdd.id;
      const ind = this.state.wishlist? this.state.wishlist.findIndex(item => item.Id === itemId) : -1;
      let newItem = null;
      if (ind >= 0) {
        newItem = {...this.state.wishlist[ind]};
      } else {
        console.log("couldn't find", itemId, "in wishlist");
        return null;
      }

      newItem.added = true;
      newItem.uuid = uuidv4();
      newItem.flattened = newItem.Depthtype === "rug";

      newItem.xFromAnchor = toAdd.xPos;
      newItem.yFromAnchor = toAdd.yPos;
      newItem.scale = toAdd.scale * (newItem.flattened ? flattenedScale : 1);
      newItem.flipped = toAdd.flipped;
      newItem.z = toAdd.z;

      console.log("this item is being added: ", newItem);
      newItems.push(newItem);
      return 0;
    });

    const wallPaperId = "yuKaizxA0BlXoUhqVAiJ";
    const ind = this.state.wishlist? this.state.wishlist.findIndex(item => item.Id === wallPaperId) : -1;
    let wallPaper = null;
    if (ind >= 0) {
      wallPaper = {item: {...this.state.wishlist[ind]}, scale: 1, left: true, right: true};
    } else {
      console.log("couldn't find", wallPaperId, "in wishlist");
    }

    const processed = this.processCollection(newItems);
    const collection = {
      ...this.state.collection,
      items: processed.items,
    };

    this.setState({collection, confettiOn: true, wallPaper});
    this.triggerSave();
  }

  processItem(item) {
    // If size is available use it, otherwise, assume its around 45"x45"
    // (looks nice enough on moodboard, between a small coffeetable and a sofa)
    let type;
    // No scale, set a size based on the itemtype or predicted type.
    if ("Type" in item) {
      type = item.Type;

      if (!(type in this.itemTypesLite)) {
        // Have to convert this type.
        if (type === "floorplant" || type === "pot") {
          type = "plant";
        } else if (type === "floorlamp" || type === "tablelamp") {
          type = "lighting";
        } else if (type === "nightstand") {
          type = "sidetable";
        } else if (type === "sidedecor") {
          type = "other";
        }
      }
    } else if ("PredictedType" in item) {
      type = item.PredictedType;
    }

    if (!item.scale && !item.Length && !item.Height) {
      // No scale previously set, grab the info from the itemTypes.
      let typeInfo = this.itemTypesLite[type];
      item.scale = typeInfo.Scale;
    }

    if (typeof item.flattened === 'undefined' && item.Depthtype === 'rug') {
      // Assume things marked rug are flattened (user can override)
      item.flattened = true;
      item.scale = flattenedScale;
    }

    const itemScale = this.state.stageHeight / 9 * (item.scale ? item.scale : 1);

    if (!item.Length && !item.Height) {
      item.Length = 45;
      item.Height = 45;
    }

    let width  = 0.11 * itemScale * item.Length;
    let height = 0.11 * itemScale * item.Height;

    if (item.aspectRatio) {
      if (item.aspectRatio >= 1) {
        height = width / item.aspectRatio;
      } else {
        width = height * item.aspectRatio;
      }
    }

    // set positions of each item
    let depthType;
    if (!item.Depthtype) {
      // set depthtype based on the itemnet or curated type prediction
      let typeInfo = this.itemTypesLite[type];
      depthType = typeInfo.Depthtype;
    } else {
      depthType = item.Depthtype;
    }

    if (depthType.includes("rug") || item.flattened) {
      let layerLevel = item.flattened ? 0 : 1;

      if (item.flattened) {
        width = 0.15 * itemScale * item.Length;
        height = 0.15 * itemScale * item.Width ? item.Width : 0.15 * itemScale * item.Height;
      }

      item.z = item.z !== undefined ? item.z : layerLevel;
    } else if (depthType.includes("pillow")) {
      item.z = item.z !== undefined ? item.z : 99;
    } else if (depthType.includes("art")) {
      item.z = item.z !== undefined ? item.z : 0;
    } else if (depthType.includes("tabledecor")) {
      item.z = item.z !== undefined ? item.z : 100;
    } else if (depthType.includes("floordecor")) {
      item.z = item.z !== undefined ? item.z : 1;
    } else {
      item.z = item.z !== undefined ? item.z  : 1;
    }

    item.imageWidth = width;
    item.imageHeight = height;

    const newItem = {
      ...item,
      x: item.xFromAnchor !== undefined ? item.xFromAnchor * this.state.stageWidth
        + this.state.stageWidth / 2 - item.imageWidth / 2 : item.x,
      y: item.yFromAnchor !== undefined ? item.yFromAnchor * this.state.stageHeight
        + this.state.stageHeight / 2 - item.imageHeight / 2 : item.y,
    };
    newItem.bottom = newItem.y + newItem.imageHeight;

    return newItem;
  }

  processCollection(collectionItems) {
    // check if collection was sent in correctly
    if (!collectionItems || collectionItems.length === 0) {
      return {items: []};
    }

    const items = collectionItems.map( (item) => {
      // check if this item type was pulled from the database, return if not.
      if (item.deleted) {
        return undefined;
      }

      item = this.processItem(item);
      return item;
    });

    function compareDepth( a, b ) {
      if ( a.z < b.z ) {
        return -1;
      }

      if ( a.z === b.z ) {
        if ( a.bottom > b.bottom ) {
          return 1;
        }

        if ( a.bottom < b.bottom ) {
          return -1;
        }
      }

      if ( a.z > b.z ) {
        return 1;
      }

      return 0;
    }

    let filteredItems = items.filter(function (el) {
      return el !== undefined;
    });

    filteredItems.sort( compareDepth );

    return {items: filteredItems};
  };

  getTotalBudget() {
    let total = 0.0;
    if (this.state.collection) {
      this.state.collection.items.forEach((item) => {
        if (!item.deleted) {
          if (item.Price) {
            total += parseFloat(item.Price);
          }
        }
        return item;
      })
    }
    return total.toFixed(2);
  }

  onDuplicateCollection = (collection) => {
    console.log("duplicating bookmarked collection: " + collection.id);
    this.setState({colMenuAnchor: null, collectionForMenu: null});

    cloudFunctionsFirebase.duplicateCollectionFirestore(collection.id, async (result) => {
      if (result) {
        // Analytics Event: new collection created
        analytics.track('Collection duplicated', {
          "original collection id": collection.id,
          "new collection id": result.newCollectionId,
        });

        const currentCollectionId = result.newCollectionId;
        this.setState({currentCollectionId, loadingCollection: true});

        const colUrl = path.join("/design", currentCollectionId);
        this.updateCollectionLastOpened(currentCollectionId);

        cloudFunctionsFirebase.getCollectionEcommerceLinksFirestore(currentCollectionId, async (data) => {
          if (data) {
            data.id = currentCollectionId;
            data.items = await this.initItems(data.items);

            const processed = this.processCollection(data.items);
            data.items = processed.items;

            if (data.colorParams) {
              this.setState({wallColor: data.colorParams.wallColor, floorColor: data.colorParams.floorColor});
              const { wallPaper } = data.colorParams;
              if (wallPaper && wallPaper.item) {
                const doc = await db.collection('ecommercelink').doc(wallPaper.item.Id).get();
                const itemData = doc.data();
                if (itemData.Vendor) {
                  const vendorDoc = await itemData.Vendor.get();
                  const vendorName = vendorDoc.data();
                  if (vendorName && vendorName.Name !== "unsupported")  {
                    itemData.vendorName = vendorName.Name;
                  }
                  else {
                    itemData.vendorName = " ";
                  }
                }
                else {
                  itemData.vendorName = " ";
                }

                itemData.Id = doc.id;
                const imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(
                  `ecommercelink/${itemData.Id}/image1.png`);
                itemData.imgUrl = imgUrl.url;
                wallPaper.item = itemData;

                this.setState({wallPaper});
              } else {
                this.setState({wallPaper: null});
              }
            }
            this.setState({collection: data});
          } else {
            console.log("failed to get collection!?");
          }
          this.setState({loadingCollection: false});
          window.history.pushState({"pageTitle":""}, "", colUrl);
        });
      } else {
        Swal.fire('Failed to duplicate new collection', '', 'error');
      }
    });
  }

  onDeleteCollection = (collections, colToDelete) => {
    this.setState({colMenuAnchor: null, collectionForMenu: null});
    Swal.fire({
      title: "Delete?",
      text: "Are you sure you want to delete this board?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2F2CB3',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("removing bookmarked collection: " + colToDelete.id);
        analytics.track('Deleted collection from side panel', {
          "collection id": colToDelete.id,
        });
        const indToDelete = collections.findIndex(collection => collection.id === colToDelete.id);
        if (indToDelete < 0) {
          Swal.fire({title: "Oops!", icon: "error", text: "Collection doesn't exists??"});
          return;
        }

        collections.splice(indToDelete, 1);
        cloudFunctionsFirebase.removeBookmarkCollectionFirestore(colToDelete.id, (data) => {
          if (data) {
            console.log("removed bookmarked collection: " + colToDelete.id);
          }
        });

        if (colToDelete.id !== this.state.collection.id) {
          this.setState({collections});
          return;
        }

        if (collections.length === 0) {
          return;
        }

        const currentCollectionId = collections[0].id;
        this.setState({currentCollectionId, loadingCollection: true, collections});

        const colUrl = path.join("/design", currentCollectionId);
        this.updateCollectionLastOpened(currentCollectionId);
        cloudFunctionsFirebase.getCollectionEcommerceLinksFirestore(currentCollectionId, async (data) => {
          if (data) {
            data.id = currentCollectionId;
            data.items = await this.initItems(data.items);

            const processed = this.processCollection(data.items);
            data.items = processed.items;

            if (data.colorParams) {
              this.setState({wallColor: data.colorParams.wallColor, floorColor: data.colorParams.floorColor});
              const { wallPaper } = data.colorParams;
              if (wallPaper && wallPaper.item) {
                const doc = await db.collection('ecommercelink').doc(wallPaper.item.Id).get();
                const itemData = doc.data();
                if (itemData.Vendor) {
                  const vendorDoc = await itemData.Vendor.get();
                  const vendorName = vendorDoc.data();
                  if (vendorName && vendorName.Name !== "unsupported")  {
                    itemData.vendorName = vendorName.Name;
                  }
                  else {
                    itemData.vendorName = " ";
                  }
                }
                else {
                  itemData.vendorName = " ";
                }

                itemData.Id = doc.id;
                const imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(
                  `ecommercelink/${itemData.Id}/image1.png`);
                itemData.imgUrl = imgUrl.url;
                wallPaper.item = itemData;

                this.setState({wallPaper});
              } else {
                this.setState({wallPaper: null});
              }
            }
            this.setState({collection: data});
          } else {
            console.log("failed to get collection!?");
          }
          this.setState({loadingCollection: false});
          window.history.pushState({"pageTitle":""}, "", colUrl);
        });
      }
    });
  }

  onShareButtonClicked = () => {
    this.setState({
      showSharePanel: true
    });
    analytics.track('Share button clicked', {
      "shareUrl": "https://app.dabble.so/share/" + this.state.currentCollectionId,
    });
  }

  favItem (item) {
    if (!(item.Id in this.state.userData.bookmarks.items)) {
      analytics.track("Added product to wishlist", {
        'clicksource': 'explore tab',
        "URL": item.URL,
        "item vendor": item.vendorName,
        "item type": item.Depthtype
      });
      cloudFunctionsFirebase.bookmarkItemFirestore(item.Id, (result) => {
        if (!result) {
          Swal.fire('Failed to bookmark item', '', 'error');
        }
      })
      this.snackBarText = "Added to Wishlist!";
    } else {
      analytics.track("Removed product from wishlist", {
        'clicksource': 'explore tab',
        "URL": item.URL,
        "item vendor": item.vendorName,
        "item type": item.Depthtype
      });

      this.onFavItemDeleted(item);
      cloudFunctionsFirebase.removeBookmarkItemFirestore(item.Id, (result) => {
        if (!result) {
          Swal.fire('Failed to remove bookmark item', '', 'error');
        }
      })
      this.snackBarText = "Removed from Wishlist";
    }

    this.setState({snackBarOpen: true});
  }

  render() {
    const { classes } = this.props;
    const dialogDom = (
      <Dialog fullScreen open={this.state.overlayOpen}>
        <div className={classes.overlay}>
          <img src="/img/dabble_logo.svg" height="40px" alt="desktop_logo" style={{objectFit: "contain", marginBottom: "20px"}}/>
          <CircularProgress color="inherit" />
        </div>
      </Dialog>
    );

    if (this.state.redirectToShare) {
      return (
        <ThemeProvider theme={theme}>
          <div id="container">
            {dialogDom}
            <SharePreview
              userId={this.state.collection.owner}
              collectionId={this.state.collection.id}
              collection={this.state.collection}
              structElems={this.structElemIds}
              wallPaper={this.state.wallPaper}
            />
          </div>
        </ThemeProvider>
      );
    }

    if (!this.state.userData || !this.state.collection) {
      return (
        <ThemeProvider theme={theme}>
          <div id="container">
            {dialogDom}
          </div>
        </ThemeProvider>
      );
    }

    // get the img url for the current collection. This value is sent to share popover
    const { collections } = this.state;
    let collectionImgUrl = null;
    if (collections) {
      const collectionImgUrlIdx = collections.findIndex(collection => collection.id === this.state.currentCollectionId);
      if (collectionImgUrlIdx > -1) {
        collectionImgUrl = collections[collectionImgUrlIdx].imgUrl;
      }
    }

    if (this.props.isMobile) {
      return (
        <ThemeProvider theme={theme}>
          <div id="container">
            <Navbar onShareButtonClicked={this.onShareButtonClicked}
              onAddDesignClicked={this.onAddDesignClicked}
              onTutorialClicked={() => {
                this.setState({showResumeTut: true});
              }}/>

            <SharePopover
              isMobile={true}
              userData={this.state.userData}
              collection={this.state.collection}
              collectionImgUrl = {collectionImgUrl}
              wallColor={this.state.wallColor}
              showSharePanel={this.state.showSharePanel}
              floorColor={this.state.floorColor}
              onLinkCopied={() => {
                analytics.track('Collection copied', {
                  "collection id": this.state.collection.id
                });
                this.snackBarText = "URL copied to clipboard!"
                this.setState({
                  snackBarOpen: true
                });
              }}
              handleClose={() => {
                this.setState({
                  showSharePanel: false
                });
              }}
            />
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',
              justifyContent: 'flex-start', width: "90vw", height: "calc(90vw * 0.67)", marginTop: 50}}>
              <div style={{margin: 5, marginBottom: 20, padding: 10, fontSize: 18,
                textAlign: "center", backgroundColor: "#FBF8F5", borderRadius: '10px'}}>
                You can only edit a design on desktop <span role="img" aria-label="computer">💻</span>but we're adding a mobile app soon!<br/><br/>
                Login on desktop at <a href="https://dabble.so" style={{color: "purple"}}>dabble.so</a>
              </div>
              {
                collectionImgUrl
                  ? <img alt="col-img" src={collectionImgUrl} loading="lazy" style={{borderRadius: 10, width: '100%'}}/>
                  : <div style={{
                      width: "100%", height: "100%", display: 'flex',
                      justifyContent: 'center', alignItems: 'center',
                      borderRadius: 10, backgroundColor: "#F4F4F4", flexDirection: 'column',
                    }}>
                      <CircularProgress disableShrink />
                    </div>
              }
              <div style={{marginTop: 20, marginBottom: 10, fontSize: 18, textAlign: "center"}}>
                Share your design with anyone
              </div>
              <Button className={classes.shareButtonMobile} style={{marginTop: 5}} startIcon={<Icon>send</Icon>}
                onClick={(e) => {
                  if (navigator.share) {
                    const shareUrl = "https://" + path.join("app.dabble.so",
                      'share', this.state.currentCollectionId);
                    navigator.share({
                      title: 'Checkout this design in Dabble!',
                      url: shareUrl
                    }).then(() => {
                      console.log('Thanks for sharing!');
                    })
                    .catch(console.error);
                  } else {
                    // fallback
                    this.onShareButtonClicked();
                  }
                }}>
                Share
              </Button>
            </div>
          </div>
        </ThemeProvider>
      );
    }

    let totalBudget = this.getTotalBudget();
    let colItemDoms = null;

    if (this.state.collection) {
      const items = [];
      this.state.collection.items.forEach(item => {
        if (item.Id in this.structElemIds) {
          return;
        }
        items.push(item);
      });

      if (this.state.wallPaper && this.state.wallPaper.item) {
        items.push(this.state.wallPaper.item);
      }
      colItemDoms = items.map((item) => {
        const linkURLString = item.vendorName;
        let itemTitleString = null;
        if(item.Title) {
          if (item.Title.length > 50)
            itemTitleString = item.Title.substring(0, 50) + "..."
          else
            itemTitleString = item.Title
        }

        return (
          <ImageListItem key={item.uuid}
            style={{borderRadius: 10, border: '1px solid #DDDDDD', margin: '5px'}}
          >
            <div className={classes.itemTile}>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: "40%", height: "100%"}}>
                <img src={item.imgUrl} loading="lazy" alt="item img" style={{objectFit: "contain", maxHeight: "90%", maxWidth: "80%"}}/>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: "60%", alignItems: 'start'}}>
                <Typography gutterBottom variant="body2">{itemTitleString? itemTitleString : " "}</Typography>
                <Typography gutterBottom variant="body2"><b>{item.Price? "$" + item.Price : " "}</b></Typography>
                { linkURLString && linkURLString.length > 1 ?
                  <a rel="noopener noreferrer"
                    target="_blank"
                    aria-label="link to shop"
                    variant="text"
                    style={{padding: 0, fontWeight: 'fontWeightRegular', fontSize: '12px', color: "#AAAAAA"}}
                    href={item.URL}>
                    {linkURLString}
                    <Icon style={{fontSize: 10}}>call_made</Icon>
                  </a>
                  : null
                }
              </div>
            </div>
          </ImageListItem>);
      });
    }

    let favColDoms = [];
    if (collections) {
      collections.sort(function (a, b) {
        if (a.LastOpened < b.LastOpened) {
          return 1;
        }
        if (a.LastOpened > b.LastOpened) {
          return -1;
        }

        return 0;
      });

      const collectionId = this.state.currentCollectionId;
      favColDoms = collections.map((collection) => {
        const color = collectionId === collection.id ?
          "5px solid #8473EE" : "0px none #000";

        return (
          <ImageListItem key={collection.id}
            style={{border: color, borderRadius: 10}}
            onClick={() => {
              analytics.track('Collection opened from side panel', {
                "collection id": collection.id,
              });

              const colUrl = path.join("/design", collection.id);
              const tourStepsEnabled = window.localStorage.sampleBoardId === collection.id;
              this.setState({
                currentCollectionId: collection.id,
                tourStepsEnabled,
                loadingCollection: true
              });
              this.updateCollectionLastOpened(collection.id);
              this.props.history.push(colUrl)

              cloudFunctionsFirebase.getCollectionEcommerceLinksFirestore(collection.id, async (data) => {
                if (data) {
                  data.id = collection.id;
                  data.items = await this.initItems(data.items);

                  const processed = this.processCollection(data.items);
                  data.items = processed.items;

                  if (data.colorParams) {
                    this.setState({wallColor: data.colorParams.wallColor, floorColor: data.colorParams.floorColor});
                    const { wallPaper } = data.colorParams;
                    if (wallPaper && wallPaper.item) {
                      const doc = await db.collection('ecommercelink').doc(wallPaper.item.Id).get();
                      const itemData = doc.data();
                      if (itemData.Vendor) {
                        const vendorDoc = await itemData.Vendor.get();
                        const vendorName = vendorDoc.data();
                        if (vendorName && vendorName.Name !== "unsupported")  {
                          itemData.vendorName = vendorName.Name;
                        }
                        else {
                          itemData.vendorName = " ";
                        }
                      }
                      else {
                        itemData.vendorName = " ";
                      }

                      itemData.Id = doc.id;
                      const imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(
                        `ecommercelink/${itemData.Id}/image1.png`);
                      itemData.imgUrl = imgUrl.url;
                      wallPaper.item = itemData;

                      this.setState({wallPaper});
                    } else {
                      this.setState({wallPaper: null});
                    }
                  }
                  this.setState({collection: data});
                } else {
                  console.log("failed to get collection!?");
                }
                this.setState({loadingCollection: false});
              });
            }}>
            { !collection.imgUrl ?
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <CircularProgress disableShrink />
              </div> :
              <img src={collection.imgUrl} loading="lazy" alt="design img"/>}
            <ImageListItemBar
              position="top"
              actionIcon={
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({colMenuAnchor: e.currentTarget, collectionForMenu: collection});
                  }}
                >
                  <Icon>more_vert</Icon>
                </IconButton>
              }
            />
          </ImageListItem>
        );
      });
    }

    const itemSelected = this.state.additionSelected
      ? this.state.additionSelected : this.state.itemSelected;
    return (
      <ThemeProvider theme={theme}>
        <div id="container">
          { this.state.confettiOn &&
            <div style={{zIndex: 10000000}}>
              <Confetti width={window.innerWidth}
                height={window.innerHeight}
                recycle={false}
                numberOfPieces={600}
                onConfettiComplete={(confetti) => {
                  this.setState({confettiOn: false});
                }}/>
            </div>
          }
          {
            this.state.overlayOpen
            ? <Dialog fullScreen open={this.state.overlayOpen}>
                <div className={classes.overlay}>
                  <img src="/img/dabble_logo.svg" height="40px" alt="desktop_logo"
                    style={{objectFit: "contain", marginBottom: "20px"}}/>
                  <CircularProgress color="inherit" />
                </div>
              </Dialog>
            : null
          }
          { !this.state.overlayOpen ?
          <>
            <Navbar onShareButtonClicked={this.onShareButtonClicked}
              onAddDesignClicked={this.onAddDesignClicked}
              saveStatusMessage={this.state.saveEditsStatus}/>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: this.state.showProductInfoPanel,
                [classes.drawerClose]: !this.state.showProductInfoPanel,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: this.state.showProductInfoPanel,
                  [classes.drawerClose]: !this.state.showProductInfoPanel,
                }),
              }}>
              <Toolbar disableGutters={true}/>
              { this.state.showProductInfoPanel ?
                <ProductInfoPanel onClose={this.handleProductPanelClose}
                  handleFavItemClick={() => this.favItem(itemSelected)}
                  updatedItemCallback={this.onItemUpdated}
                  owner={this.state.userData.id}
                  onAddToDesign={this.handleAddItem}
                  faved={itemSelected ? itemSelected.Id in this.state.userData.bookmarks.items : false}
                  imagePasted={(dataURL) => {
                    let itemSelected = this.state.additionSelected
                      ? this.state.additionSelected : this.state.itemSelected;

                    if (!itemSelected) {
                      itemSelected = {};
                    }

                    itemSelected.newItem = true;
                    itemSelected.selImgUrl = dataURL;
                    this.setState({additionSelected: itemSelected})
                  }}
                  currItem={itemSelected}/> : null
              }
            </Drawer>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: this.state.showWallPaperPanel,
                [classes.drawerClose]: !this.state.showWallPaperPanel,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: this.state.showWallPaperPanel,
                  [classes.drawerClose]: !this.state.showWallPaperPanel,
                }),
              }}>
              <Toolbar disableGutters={true}/>
              { this.state.showWallPaperPanel
                ? <WallPaperPanel
                    wallPaper={this.state.wallPaper}
                    onClose={() => {
                      this.setState({showWallPaperPanel: false});
                    }}
                    onWallPaperInfoClick={() => {
                      this.setState({
                        itemSelected: this.state.wallPaper.item,
                        showProductInfoPanel: true,
                        showWallPaperPanel: false
                      });
                    }}
                    onNewScaleValue={(newScale) => {
                      const newWallPaper = {...this.state.wallPaper};
                      if (!newWallPaper) {
                        return;
                      }

                      console.log("newScale", newScale);
                      newWallPaper.scale = newScale;
                      this.setState({wallPaper: newWallPaper});
                      this.triggerSave();
                    }}
                    onNewWallSplitMode={(wallPaperMode) => {
                      const newWallPaper = {...this.state.wallPaper};
                      if (!newWallPaper) {
                        return;
                      }

                      console.log("wallPaperMode", wallPaperMode);
                      newWallPaper.left = wallPaperMode.left;
                      newWallPaper.right = wallPaperMode.right;
                      this.setState({wallPaper: newWallPaper});
                      this.triggerSave();
                    }}/>
                : null
              }
            </Drawer >
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: this.state.showShoppingCart,
                [classes.drawerClose]: !this.state.showShoppingCart,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: this.state.showShoppingCart,
                  [classes.drawerClose]: !this.state.showShoppingCart,
                }),
              }}>
                <Toolbar disableGutters={true}/>
                { this.state.showShoppingCart
                  ?
                  <div style={{width: "100%", overflowY: "scroll", display: "flex", flexDirection: "column", justifyContent: "flex-start", height: "100%"}}>
                    <div style={{width: "100%", height: "auto", display: "flex", justifyContent: "flex-end"}}>
                      <IconButton aria-label="close"
                        onClick={(e) => {
                          this.setState({showShoppingCart: false});
                        }}>
                        <Icon>close</Icon>
                      </IconButton>
                    </div>

                    <div className={classes.totalCostInfo}>
                        <strong>Total: ${totalBudget} </strong>
                    </div>
                    <ImageList rowHeight={120} cols={1} gap={10} style={{margin: "20px 30px 30px 30px", overflowY: 'unset'}}>
                      {colItemDoms}
                    </ImageList>
                  </div>
                : null
                }
            </Drawer>
            <Dialog
              aria-labelledby="extension-install-dialog"
              classes={{paper: classes.modal}}
              open={Boolean(this.state.showChromeExtDialog)}
              onClose={(event, reason) => {
                if (reason === "backdropClick") {
                  return false;
                }
                if (reason === "escapeKeyDown") {
                  return false;
                }
              }}>
              <DialogContent classes={{root: classes.onboardDialog}} style={{paddingTop: 0}}>
                <div style={{width: "100%", height: "auto"}} className={classes.modalButtonBar}>
                  <IconButton aria-label="close"
                    onClick={() => {
                      // turn on intro tour
                      this.setState({showChromeExtDialog: false});
                    }}>
                    <Icon>close</Icon>
                  </IconButton>
                </div>
                <div style={{width: "90%", height: "auto", justifyContent: "flex-start", display: "flex", flexDirection: "column"}}>
                  <h1 style={{marginTop: 20}}>Get the Dabble Chrome Extension</h1>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{flex: 0, flexGrow: 3, flexShrink: 1}}>
                      <span style={{fontSize: 18, paddingBottom: 20, color: 'grey'}}>Clip an image, url, and price from any website in one click.</span>
                    </div>
                    <div style={{flex: 0, flexGrow: 2, flexShrink: 1, display: 'flex', justifyContent: 'flex-end'}}>
                      <ColorButton target="_blank" style={{marginTop: 10, marginBottom: 20, paddingLeft: 20, paddingRight: 20}}
                        href={"https://chrome.google.com/webstore/detail/dabble/lnlgccgpbhgpnlgjignoencljjpoleca"}
                        onClick={this.handleAddItemPanelClose}>
                        <span style={{fontSize: 18}}>Add to Chrome</span>
                      </ColorButton>
                    </div>
                  </div>

                  <img style={{width: "100%", paddingBottom: 5, paddingTop: 20}} alt="get_chrome_ext" src="/img/clippingdemo.gif"/>

                </div>
              </DialogContent>
            </Dialog>
            <Dialog
              aria-labelledby="resume-tutorial-dialog"
              classes={{paper: classes.modal}}
              open={Boolean(this.state.showResumeTut)}>
              <DialogContent classes={{root: classes.onboardDialog}}
                style={{paddingTop: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width: "96%", height: "auto"}} className={classes.modalButtonBar}>
                  <IconButton aria-label="close"
                    onClick={() => {
                      // turn on intro tour
                      this.setState({showResumeTut: false});
                    }}>
                    <Icon>close</Icon>
                  </IconButton>
                </div>
                <h2 style={{marginTop: 0, marginBottom: 10}}>Resume your Dabble Tutorial?</h2>
                <ColorButton style={{marginTop: 10}} onClick={(e) => {
                  if (!Boolean(window.localStorage.sampleBoardId)) {
                    this.props.history.push('/');
                    return;
                  }

                  const colId = window.localStorage.sampleBoardId;
                  const colUrl = path.join("/design", colId);
                  this.setState({
                    currentCollectionId: colId,
                    showSampleOnboard: true,
                    loadingCollection: true,
                    showResumeTut: false,
                  });

                  cloudFunctionsFirebase.getCollectionEcommerceLinksFirestore(colId, async (data) => {
                    if (data) {
                      data.id = colId;
                      data.items = await this.initItems(data.items);

                      const processed = this.processCollection(data.items);
                      data.items = processed.items;
                      if (data.colorParams) {
                        this.setState({
                          wallColor: data.colorParams.wallColor,
                          floorColor: data.colorParams.floorColor
                        });
                      }

                      const { wallPaper } = data.colorParams;
                      if (wallPaper && wallPaper.item) {
                        const doc = await db.collection('ecommercelink').doc(wallPaper.item.Id).get();
                        const itemData = doc.data();
                        if (itemData.Vendor) {
                          const vendorDoc = await itemData.Vendor.get();
                          const vendorName = vendorDoc.data();
                          if (vendorName && vendorName.Name !== "unsupported")  {
                            itemData.vendorName = vendorName.Name;
                          }
                          else {
                            itemData.vendorName = " ";
                          }
                        }
                        else {
                          itemData.vendorName = " ";
                        }

                        itemData.Id = doc.id;
                        const imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(
                          `ecommercelink/${itemData.Id}/image1.png`);
                        itemData.imgUrl = imgUrl.url;
                        wallPaper.item = itemData;

                        this.setState({wallPaper});
                      } else {
                        this.setState({wallPaper: null});
                      }
                      this.setState({collection: data});
                    } else {
                      console.log("failed to get collection!?");
                    }

                    this.setState({loadingCollection: false});
                    window.history.pushState({"pageTitle":""}, "", colUrl);
                  });
                }}>
                  Resume
                </ColorButton>
              </DialogContent>
            </Dialog>
            <SharePopover
              userData={this.state.userData}
              collection={this.state.collection}
              collectionImgUrl = {collectionImgUrl}
              wallColor={this.state.wallColor}
              showSharePanel={this.state.showSharePanel}
              floorColor={this.state.floorColor}
              onLinkCopied={() => {
                analytics.track('Collection copied', {
                  "collection id": this.state.collection.id
                });
                this.snackBarText = "URL copied to clipboard!"
                this.setState({
                  snackBarOpen: true
                });
              }}
              handleClose={() => {
                this.setState({
                  showSharePanel: false
                });
              }}
            />
            <div id="moodboardcontainer">
              <div className={classes.leftPanel}>
                <Tabs
                  //TabIndicatorProps={{style: {background:'#741CE5'}}}
                  className={classes.leftPanelTabs}
                  value={this.state.ctrlPanelTab}
                  onChange={(event, newValue) => {
                    this.setState({ctrlPanelTab: newValue});
                    switch (newValue) {
                      case 0:
                        analytics.track("Opened Wishlist tab");
                        break;
                      case 1:
                        analytics.track("Opened Room Setup tab");
                        break;
                      case 2:
                        analytics.track("Opened Designs tab");
                        break;
                      case 3:
                        analytics.track("Opened Explore tab");
                        break;
                      default:
                        break;
                    }
                  }}
                  orientation="vertical"
                  indicatorColor="secondary"
                  textColor="secondary"
                  centered
                >
                  <Tab id={"explore_tab"} style={{color: "#757575"}} label="Find Products"
                    icon={<Icon><img alt="explore-logo" className="tabIcon" src="/img/icons/search.svg"/></Icon>} />
                  <Tab id={"wishlist_tab"} style={{color: "#757575"}} label="Saved Items"
                    icon={<Icon><img alt="wishlist-logo" className="tabIcon" src="/img/icons/heart.svg"/></Icon>} />
                  <Tab id={"room_setup_tab"} style={{color: "#757575"}} label="Room Setup"
                    icon={<Icon><img alt="room-setup-logo" className="tabIcon" src="/img/icons/roomsetup.svg"/></Icon>} />
                  <Tab style={{color: "#757575"}} label="Designs"
                    icon={<Icon><img alt="designs-logo" className="tabIcon" src="/img/icons/designs.svg"/></Icon>} />
                </Tabs>
                <TabPanel value={this.state.ctrlPanelTab} index={0} className = {classes.tabPanels}>
                  <div style={{width: "348px"}}>
                    <ExplorePanel
                      onNewCurateType={(itemType) => {
                        analytics.track("Changed curated product type", {
                          "to": itemType
                        })
                        this.setState({pageNum: 0, exploreProducts: [], fetchingNewPage: true});
                        cloudFunctionsFirebase.getCuratedItems(itemType, null, this.pageLimit, 0, false, (itemsList, pageNum) => {
                          this.setState({exploreProducts: itemsList, pageNum: pageNum + 1});
                        });
                      }}
                      fetchNewPage={(itemType) => {
                        analytics.track("Scrolling past pagination for curated item", {
                          "itemType": itemType
                        })
                        const lastVisibleIdx = this.state.exploreProducts.length
                          ? this.state.exploreProducts[this.state.exploreProducts.length - 1].Created : null;
                        this.setState({fetchingNewPage: true});
                        cloudFunctionsFirebase.getCuratedItems(itemType, lastVisibleIdx, this.pageLimit, this.state.pageNum, false,
                          (itemsList, pageNum) => {
                            if (pageNum !== this.state.pageNum) {
                              return;
                            }
                            const newProducts = this.state.exploreProducts.concat(itemsList);
                            this.setState({exploreProducts: newProducts, fetchingNewPage: false, pageNum: pageNum + 1});
                          });
                      }}
                      onSearchResults={(ecommercelinkIDs) => {
                        this.setState({pageNum: 0, fetchingNewPage: true, exploreProducts: []})
                        cloudFunctionsFirebase.getCuratedItemsSearch(ecommercelinkIDs, false, (itemsList, pageNum) => {
                          this.setState({exploreProducts: itemsList, pageNum: pageNum + 1, fetchingNewPage: false});
                        })
                      }}
                      fetchingNewPage={this.state.fetchingNewPage}
                      userData={this.state.userData}
                      products={this.state.exploreProducts}
                      deselectItem={this.onDeselectItem}
                      onItemGettingDragged={this.onFavoriteItemGettingDragged}
                      onAdditionClicked={this.onAdditionClicked}
                      height={0.85 * window.innerHeight}/>
                  </div>
                </TabPanel>
                <TabPanel value={this.state.ctrlPanelTab} index={1} className = {classes.tabPanels}>
                  <div style={{width: "348px"}}>
                    <AdditionPanel
                      additions={this.state.wishlist}
                      onItemGettingDragged={this.onFavoriteItemGettingDragged}
                      onAdditionClicked={this.onAdditionClicked}
                      onAddItemClicked={() => {
                        this.setState({showProductInfoPanel: true, additionSelected: false, itemSelected: false});
                      }}
                      deselectItem={this.onDeselectItem}
                      height={0.85 * window.innerHeight}
                      userID={this.state.userData.id}/>
                  </div>
                </TabPanel>
                <TabPanel value={this.state.ctrlPanelTab} index={2} className = {classes.tabPanels}>
                  <div style={{width: "348px", display: "flex", flexDirection: "column", justifyContent: "flex-start", height: "100%"}}>
                    <RoomSetup
                      onStructElemsFetched={structElem2ItemData => this.setState({structElem2ItemData})}
                      onStructElemDragged={this.onFavoriteItemGettingDragged}
                      onWallColorPicked={this.onWallColorPicked}
                      onFloorColorPicked={this.onFloorColorPicked}
                      currentWallColor={this.state.wallColor}
                    />
                  </div>
                </TabPanel>
                <TabPanel value={this.state.ctrlPanelTab} index={3} className = {classes.tabPanels}>
                  <div className="alternativesctrlpanel"
                    style={{alignItems: "center", marginBottom: "10px", display: 'flex', justifyContent: "flex-end"}}>
                    <IconButton
                      className = {classes.addItemButton}
                      title={"Add Design"}
                      variant="contained"
                      onClick={this.onAddDesignClicked}>
                      <Icon>
                        <img alt="add-item-logo" src="/img/icons/additem.svg"/>
                      </Icon>
                    </IconButton>
                  </div>
                  <div style={{width: "348px", overflowY: "scroll", display: "flex",
                    flexDirection: "column", justifyContent: "flex-start", height: "100%"}}>
                    <ImageList rowHeight={214} cols={1} gap={20} style={{margin: "20px 30px 20px 30px", overflowY: 'unset'}}>
                      {favColDoms}
                    </ImageList>
                  </div>
                </TabPanel>
                <Menu
                  id="col-control-menu"
                  anchorEl={this.state.colMenuAnchor}
                  keepMounted
                  open={Boolean(this.state.colMenuAnchor)}
                  TransitionComponent={Fade}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  onClose={() => {this.setState({colMenuAnchor: null})}}
                >
                  <MenuItem key={"duplicate"} selected={false} onClick={() => {
                    this.onDuplicateCollection(this.state.collectionForMenu)}
                  }>
                    Duplicate
                  </MenuItem>
                  <MenuItem key={"delete"} selected={false} onClick={() => {
                    this.onDeleteCollection(collections, this.state.collectionForMenu)}
                  }>
                    Delete
                  </MenuItem>
                </Menu>
              </div>
              <div id="moodboard" style={{paddingLeft: "10px", paddingRight: "20px", width: "100%", backgroundColor: mainBGColor}}>
                <div id="moodboardctrlpanel">
                  <div id="moodboardctrldiv" style={{width: this.state.stageWidth, display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                    { this.state.showWallPaperPanel || (this.state.wallPaper && this.state.itemSelected === this.state.wallPaper.item) ?
                      <div style={{display: "flex", width: "100%", justifyContent: "flex-start", flexDirection: "row", alignItems: 'center'}}>
                        <div>
                          <IconButton aria-label="item_info"
                            onClick={(e) => {
                              analytics.track_item_info_click('moodboard wallpaper info icon', this.state.wallPaper.item.Id);
                              this.setState({showProductInfoPanel: true, itemSelected: this.state.wallPaper.item, showWallPaperPanel: false});
                            }}
                            title = {"Item info"}
                            className={classes.ctrlButton}>
                            <Icon><img alt="info-logo"  src="/img/icons/info.svg"/></Icon>
                          </IconButton>
                          { this.state.wallPaper.item && this.state.wallPaper.item.URL ?
                            <IconButton className={classes.ctrlButton} target="_blank"
                              onClick={() => {
                                analytics.track_shop_it_click('moodboard button',
                                  this.state.wallPaper.item.URL);
                              }}
                              href={this.state.wallPaper.item.URL}>
                              <Icon style={{color: 'black'}}>
                                link
                              </Icon>
                            </IconButton> : null }
                        </div>
                        <IconButton aria-label="delete_wallpaper"
                          disabled={!this.state.showWallPaperPanel}
                          title={"Delete Wallpaper"}
                          onClick={this.onDeleteWallPaper}
                          className={classes.ctrlButton}>
                          <Icon>
                            <img alt="trash-logo" src="/img/icons/trash.svg"/>
                          </Icon>
                        </IconButton>
                      </div> : null
                    }
                    { !this.state.showWallPaperPanel && this.state.itemSelected &&
                      !(this.state.wallPaper && this.state.itemSelected === this.state.wallPaper.item) ?
                      <div style={{display: "flex", width: "100%", justifyContent: "flex-start", flexDirection: "row", alignItems: 'center'}}>
                        <div>
                          <IconButton aria-label="item_info"
                            onClick={(e) => {
                              analytics.track_item_info_click('moodboard info icon', this.state.itemSelected.URL,
                                this.state.itemSelected.vendorName, this.state.itemSelected.Depthtype);
                              this.setState({showProductInfoPanel: true});
                            }}
                            title = {"Item info"}
                            className={classes.ctrlButton}>
                            <Icon><img alt="info-logo" src="/img/icons/info.svg"/></Icon>
                          </IconButton>
                          { this.state.itemSelected && this.state.itemSelected.URL ?
                            <IconButton className={classes.ctrlButton} target="_blank"
                              disabled={!this.state.itemSelected}
                              onClick={() => {
                                analytics.track_shop_it_click('moodboard button',
                                  this.state.itemSelected.URL, this.state.itemSelected.vendorName,
                                  this.state.itemSelected.Depthtype);
                              }}
                              href={this.state.itemSelected ? this.state.itemSelected.URL : null}>
                              <Icon style={{color: 'black'}}>
                                link
                              </Icon>
                            </IconButton> : null }
                        </div>
                        <div>
                          <IconButton aria-label="flip_item" disabled={!this.state.itemSelected}
                            title={"Flip Item"}
                            onClick={this.onFlipItemClick}
                            className={classes.textButton}>
                              <Icon><img alt="flip-logo" src="/img/icons/flip.svg"/></Icon>
                          </IconButton>
                          <LayerPopover disabled={!this.state.itemSelected}
                            rightClickActivated = {false}
                            flattenItem={this.handleFlatten}
                            itemFlattened={this.state.itemSelected ? this.state.itemSelected.flattened : false}
                            tileWallpaper={this.handleTileWallpaper}
                            allTheWayFront={this.handleAllTheWayFront}
                            allTheWayBack={this.handleAllTheWayBack}/>
                          <IconButton aria-label="duplicate_item"
                            disabled={!this.state.itemSelected}
                            title={"Duplicate Item"}
                            onClick={this.onDuplicateItem}
                            className={classes.ctrlButton}>
                            <Icon>
                              <img alt="duplicate-logo" src="/img/icons/duplicate.svg"/>
                            </Icon>
                          </IconButton>
                          <IconButton aria-label="delete_item"
                            disabled={!this.state.itemSelected}
                            title={"Delete Item"}
                            onClick={this.onDeleteItemClick}
                            className={classes.ctrlButton}>
                            <Icon>
                              <img alt="trash-logo" src="/img/icons/trash.svg"/>
                            </Icon>
                          </IconButton>
                        </div>
                      </div> : null }
                    { !this.state.showWallPaperPanel && this.state.itemGroupSelected ?
                      <div style={{display: "flex", width: "100%", justifyContent: "flex-start", flexDirection: "row", alignItems: 'center'}}>
                        <div>
                          <LayerPopover disabled={!this.state.itemGroupSelected}
                            allTheWayFront={this.handleAllTheWayFrontGroup}
                            allTheWayBack={this.handleAllTheWayBackGroup}/>
                          <IconButton aria-label="duplicate_item_group"
                            disabled={!this.state.itemGroupSelected}
                            title={"Duplicate Items"}
                            onClick={this.onDuplicateGroup}
                            className={classes.ctrlButton}>
                            <Icon>
                              <img alt="duplicate-logo" src="/img/icons/duplicate.svg"/>
                            </Icon>
                          </IconButton>
                          <IconButton aria-label="delete_item_group"
                            disabled={!this.state.itemGroupSelected}
                            title={"Delete Items"}
                            onClick={this.onDeleteGroupClick}
                            className={classes.ctrlButton}>
                            <Icon>
                              <img alt="trash-logo" src="/img/icons/trash.svg"/>
                            </Icon>
                          </IconButton>
                        </div>
                      </div> : null }
                      </div>
                      <div>
                        <IconButton
                          title={"Shopping Cart"}
                          className={classes.ctrlButton}
                          onClick={() => {
                            this.setState({showShoppingCart: true})
                          }}
                        >
                          <Icon>
                            <img alt="shoppingcart-logo" src="/img/icons/shoppingcart.svg"/>
                          </Icon>
                        </IconButton>
                      </div>
                  </div>
                </div>

                <div className="moodboardwrapper"
                  style={{position: "relative", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 0}}>
                  <div style={{width: this.state.stageWidth, height: this.state.stageHeight}}>
                    { this.state.loadingCollection ?
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center",
                        height: "100%", backgroundColor: "rgb(227, 227, 227)", borderRadius: "10px"}}>
                        <CircularProgress disableShrink />
                      </div> :
                      <div
                        onDrop={(e)=> {
                          e.preventDefault();
                          const moodboardRect = e.target.getBoundingClientRect();
                          const dropLocationX = e.clientX - moodboardRect.left;
                          const dropLocationY = e.clientY - moodboardRect.top;
                          this.handleAddItem(this.state.favoriteItemBeingDragged, dropLocationX, dropLocationY);
                          this.setState({favoriteItemBeingDragged: null});
                        }}
                        onDragEnter={(e)=>{
                          e.preventDefault()
                        }}
                        onDragOver={(e)=>{
                          e.preventDefault()
                        }}>
                        <MoodBoard {...this.props} wallColor={this.state.wallColor}
                          floorColor={this.state.floorColor}
                          updateState = {this.state.updateToggle}
                          collection={this.state.collection}
                          wallPaper={this.state.wallPaper}
                          itemGroupSelected={this.state.itemGroupSelected}
                          wallPaperClicked={() => {
                            this.setState({showWallPaperPanel: true, showShoppingCart: false})
                          }}
                          onMouseDragSelect={(groupedItems) => {
                            if (groupedItems.length === 0) {
                              return;
                            }

                            const newGroupedItems = {};
                            groupedItems.forEach(uuid => {
                              const itemIdx = this.state.collection.items.findIndex(
                                item => item.uuid === uuid);
                              if (itemIdx >= 0) {
                                newGroupedItems[uuid] = this.state.collection.items[itemIdx];
                              }
                            });

                            if (groupedItems.length === 1) {
                              this.setState({
                                itemGroupSelected: null,
                                itemSelected: newGroupedItems[groupedItems[0]],
                              });
                            } else {
                              this.setState({itemGroupSelected: newGroupedItems, itemSelected: null});
                            }
                          }}
                          onDeselectItem={this.onDeselectItem}
                          onClickItem={this.onClickItem}
                          onDblClickItem={this.onDblClickItem}
                          onDraggedItem={this.onDraggedItem}
                          onDraggedGroup={this.onDraggedGroup}
                          onScaledItem={this.onScaledItem}
                          onItemImageLoaded={this.onItemImageLoaded}
                          stageWidth={this.state.stageWidth}
                          stageHeight={this.state.stageHeight}
                          screenScale={this.state.screenScale}
                          alternatives={this.state.alternatives}
                          itemSelected={this.state.itemSelected}
                          onBringFront={this.handleAllTheWayFront}
                          onSendBack={this.handleAllTheWayBack}
                          onTileWallpaper={this.handleTileWallpaper}
                          onFlatten={this.handleFlatten}
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <Steps
              initialStep={0}
              enabled={this.state.tourStepsEnabled &&
                Boolean(this.state.wishlist) && Boolean(this.state.structElem2ItemData)}
              steps={this.steps}
              ref={steps => (this.stepsObj = steps)}
              onStart={() => {
                $('.introjs-skipbutton').hide();
                $('.introjs-prevbutton').hide();
                analytics.track("Onboarding started");
              }}
              onBeforeChange={(newStepIdx) => {
                if (this.steps[newStepIdx].onBeforeChange) {
                  this.steps[newStepIdx].onBeforeChange();
                }
              }}
              onAfterChange={(newStepIdx) => {
                console.log("step idx", newStepIdx);
                analytics.track("Onboarding progressed", {"step" : newStepIdx})
                this.setState({onboardStepIdx: newStepIdx});

                if (this.steps[newStepIdx].onAfterChange) {
                  this.steps[newStepIdx].onAfterChange();
                } else {
                  $('.introjs-nextbutton').show();
                }
              }}
              onExit={() => {
                this.setState({tourStepsEnabled: false, showChromeExtDialog: true});
                analytics.track("Onboarding finished in moodboard!")
              }}
              options={{
                showStepNumbers: false,
                showButtons: true,
                hidePrev: true,
                showBullets: false,
                exitOnEsc: false,
                exitOnOverlayClick: false,
                doneLabel: "Got it!",
                nextLabel: "Next",
                overlayOpacity: 0.3,
              }}
            />
          </> : null}
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackBarOpen}
          onClose={this.handleSnackBarClose}
          autoHideDuration={5000}
          ContentProps={{
            classes: {
              root: classes.snackBar
            }
          }}
          message= {this.snackBarText}
        />
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(withMediaQuery()(App));
