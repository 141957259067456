/**
 *
 * @type {string} used in reducer. setting the string as a const makes it easier to change the string definition
 */
export const ALTERNATIVE_OPEN = "ALTERNATIVE_OPEN";
export const ALTERNATIVE_CLOSE = "ALTERNATIVE_CLOSE";

/**
 * things to return. type and any additional objects to be passed to store
 * @returns {{type: string}}
 */
const openAlternative = () => {
  return {
    type: ALTERNATIVE_OPEN
  };
};

const closeAlternative = () => {
  return {
    type: ALTERNATIVE_CLOSE
  };
};

/**
 * dispatch action that is passed to reducer
 * @returns {function(*): *}
 */
export const setAlternativeOpen = () => {
  return (dispatch) => dispatch(openAlternative());
};

export const setAlternativeClose = () => {
  return (dispatch) => dispatch(closeAlternative());
};