import React from 'react';
import { Grid, Tabs, Tab, } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { SketchPicker } from 'react-color';
import analytics from '../analytics/Analytics';
import firebase from 'firebase/app';

const db = firebase.firestore();
const useStyles = theme => ({
  colorButton: {
    boxSizing: 'border-box',
    outline: 0,
    width: '70px',
    height: '70px',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  structElemButton: {
    boxSizing: 'border-box',
    outline: 0,
    width: '100%',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  noneColorButton: {
    outline: 0,
    width: '66px',
    height: '66px',
    border: '2px solid #AAAAAA',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  gridLabel: {
    color: "#000000",
    marginTop: 10,
    fontSize: '0.8em'
  },
  floorImg: {
    width: "100%",
    height: "100%",
    borderRadius: '0px',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  structElemImg: {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
    objectFit: "contain",
    borderRadius: '0px',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  tabPanel: {
    paddingLeft: 30,
    paddingRight: 30,
    overflowY: "scroll",
  },
  tabLabel: {
    fontSize: '14px'
  },
  popover: {
    position: 'absolute',
    left: '100px',
    top: '240px',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

class RoomSetup extends React.Component {
  state = {
    ctrlPanelTab: 0,
    displayColorPicker: false,
    wallSplitMode: "full", 
  };

  constructor(props) {
    super(props);
    if (typeof props.currentWallColor === "string") {
      this.state.wallColor = {left: props.currentWallColor, right: props.currentWallColor};
    }
    else {
      this.state.wallColor = props.currentWallColor;
    }

    this.structElemIds = {
      "gMWkiYn54CciJopVNBFw": "door1",
      "kpxvoDMBtWPSbgpM6rPK": "door2",
      "ribVe1JUNhw7H46CjBP2": "door3",
      "I8NmDtUO4HBD3zHsUcDM": "door4",
      "o8dpKD1mIrTWMABbFt1R": "door5",
      "kyY7JlFg8rRAyuUbsZ1S": "door6",
      "caddo7FJFswypSqfnD5k": "window1",
      "gmE3EUZqVfxRGa8bWzaB": "window2",
      "pNWDZ3k9cAlmZzOkdzXc": "window3",
      "oJyYTSAdSSE11fylmPUW": "window4",
      "uCd2KJCpOw4tBAJM1JS0": "window5",
      "5fyt3Oi8VSx4DbqQVw8U": "window6",
    }
    this.doorIds = ["door1", "door2", "door3", "door4", "door5", "door6"];
    this.windowIds = ["window1", "window2", "window3", "window4", "window5", "window6"];

    const fetchItemData = async (itemIds) => {
      const itemsData = await Promise.all(itemIds.map(async (itemId) => {
        // getting product details
        const itemRef = db.collection('ecommercelink').doc(itemId); 
        const itemDoc = await itemRef.get();
        const itemData = itemDoc.data();

        if (!itemData) {
          return;
        }

        // getting vendor name
        if (itemData.Vendor) {
          const vendorDoc = await itemData.Vendor.get();
          const vendorName = vendorDoc.data();
          if (vendorName && vendorName.Name !== "unsupported")  {
            itemData.vendorName = vendorName.Name;
          }
          else {
            itemData.vendorName = " ";
          }
        }
        else {
          itemData.vendorName = " ";
        }

        itemData.Id = itemDoc.id;
        itemData.imgUrl = '/img/struct_elems/' + this.structElemIds[itemData.Id] + '.png';
        return itemData;
      }));

      return itemsData;
    }

    fetchItemData(Object.keys(this.structElemIds)).then((structElemItemData) => {
      const structElem2ItemData = {};
      structElemItemData.forEach(itemData => {
        const structElem = this.structElemIds[itemData.Id];
        structElem2ItemData[structElem] = itemData; 
      }); 
      this.setState({structElem2ItemData});
      if (typeof this.props.onStructElemsFetched === 'function') {
        this.props.onStructElemsFetched(structElem2ItemData);
      }
    });
  }

  setWallColor(color) {
    const mode = this.state.wallSplitMode;
    const newWallColor = {...this.state.wallColor}; 
    if (mode === "left") {
      newWallColor.left = color;
      analytics.track("New wall color selected for left", {"color": color});
    }
    else if (mode === "right") {
      newWallColor.right = color;
      analytics.track("New wall color selected for right", {"color": color});
    }
    else {
      newWallColor.left = color;
      newWallColor.right = color;
      analytics.track("New wall color selected for full wall", {"color": color});
    }
    this.setState({wallColor: newWallColor});
    if (this.props.onWallColorPicked) {
      this.props.onWallColorPicked(newWallColor);
    }
  }

  wallColorsToGridItems (wallColors) {
    const wallColorButtons = wallColors.map((color) => (
      <Grid item xs={3} key={"wallbutton" + color}>
        <div className={this.props.classes.colorButton} style={{backgroundColor: "#" + color}}
          onClick={() => {
            console.log("clicked on " + color)
            this.setWallColor(color);
          }}
        />
      </Grid>
    ));
    return wallColorButtons;
  }

  floorColorsToGridItems (floorColors) {
    const floorColorButtons = floorColors.map((color) => {
      return <Grid item xs={3} key={"floorbutton" + color}>
        <div className={this.props.classes.colorButton}>
          <img className={this.props.classes.floorImg}
            src={'/img/floors_thumbs/' + color + '.png'}
            alt={color}
            onClick={() => {
              console.log("clicked on " + color)
              if (this.props.onFloorColorPicked) {
                this.props.onFloorColorPicked(color);
              }
            }}
          />
        </div>
      </Grid>
    });
    return floorColorButtons;
  }

  structElementGridItems (structElems) {
    const structElemButtons = structElems.map((elem) => {
      return <Grid item xs={6} key={"struct_elem_button_" + elem}>
        <div className={this.props.classes.structElemButton}>
          <img className={this.props.classes.structElemImg}
            src={'/img/struct_elems/' + elem + '.png'}
            alt={elem}
            onDragStart={(e) => {
              analytics.track("Structural element getting dragged", {"elementName": elem});
              if (this.props.onStructElemDragged && this.state.structElem2ItemData) {
                this.props.onStructElemDragged(this.state.structElem2ItemData[elem]);
              }
            }}
          />
        </div>
      </Grid>
    });
    return structElemButtons;
  }

  handleColorPickerChangeComplete = (color) => {
    console.log("Color change complete", color.hex);
    this.setWallColor(color.hex.substring(1));
  };

  handleColorPickerChange = (color) => {
    console.log("Color changed", color);
  };

  handleColorPickerClick =() => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
    analytics.track('Color Picker opened');
  };

  handleColorPickerClose = () => {
    this.setState({ displayColorPicker: false })
  };

  render() {
    const { classes } = this.props;
    const { wallColor, wallSplitMode } = this.state;

    let selectedWallColor = "#" + wallColor.left;
    if (wallSplitMode === "right") {
      selectedWallColor = "#" + wallColor.right;
    }

    const selectedBorder = "5px solid #8473EE";
    return (
      <div id="color_grid" style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
        <Tabs
          className={classes.leftPanelTabs}
          value={this.state.ctrlPanelTab}
          onChange={(event, newValue) => {this.setState({ctrlPanelTab: newValue});}}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label={<span className={classes.tabLabel}>Wall</span>}/>
          <Tab label={<span className={classes.tabLabel}>Floor</span>}/>
          <Tab label={<span className={classes.tabLabel}>Windows/Doors</span>}/>
        </Tabs>
        <TabPanel value={this.state.ctrlPanelTab} index={0} className={classes.tabPanel}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.gridLabel} style={{paddingTop: 10, color: '#767676'}}>Apply color to</div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.gridLabel}>Left Wall</div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.gridLabel}>Entire Wall</div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.gridLabel}>Right Wall</div>
            </Grid>
            <Grid item xs={4}>
              <div className={this.props.classes.colorButton}
                style={{backgroundColor: "#E2E2E2", border: wallSplitMode === "left" ? selectedBorder : "none"}}
                onClick={() => {
                  this.setState({wallSplitMode: "left"});
                }}>
                <div style={{width: "50%", height: "100%", backgroundColor: "#AEAEAE"}}/>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={this.props.classes.colorButton}
                style={{backgroundColor: "#E2E2E2", border: wallSplitMode === "full" ? selectedBorder : "none"}}
                onClick={() => {
                  this.setState({wallSplitMode: "full"});
                }}>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={this.props.classes.colorButton}
                style={{backgroundColor: "#AEAEAE", border: wallSplitMode === "right" ? selectedBorder : "none"}}
                onClick={() => {
                  this.setState({wallSplitMode: "right"});
                }}>
                <div style={{width: "50%", height: "100%", backgroundColor: "#E2E2E2"}}/>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.gridLabel} style={{paddingBottom: "10px"}}>None</div>
              <div className={this.props.classes.noneColorButton}>
                <img className={this.props.classes.floorImg}
                  src={'/img/none.jpeg'}
                  alt={'none_button_img'}
                  onClick={() => {
                    console.log("clicked on none")
                    this.setWallColor("FFFFFF");
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.gridLabel} style={{paddingBottom: "10px"}}>Color Picker</div>
              <div className={this.props.classes.noneColorButton} style={{backgroundColor: selectedWallColor}}
                onClick={this.handleColorPickerClick}/>
            </Grid>
            
            { this.state.displayColorPicker 
              ? <div className={ classes.popover }>
                  <div className={ classes.cover } onClick={ this.handleColorPickerClose }/>
                  <SketchPicker 
                    color={ selectedWallColor } 
                    width={250} 
                    presetColors={[]} 
                    disableAlpha={true} 
                    onChange={ this.handleColorPickerChange } 
                    onChangeComplete={this.handleColorPickerChangeComplete}/>
                </div>
              : null
            }

            <Grid item xs={12}>
              <div className={classes.gridLabel}>Popular Colors</div>
            </Grid>
            {this.wallColorsToGridItems(this.props.benMooreWalls)}
            <Grid item xs={12}>
              <div className={classes.gridLabel}>White</div>
            </Grid>
            {this.wallColorsToGridItems(this.props.whiteWalls)}
            <Grid item xs={12}>
              <div className={classes.gridLabel}>Grey</div>
            </Grid>
            {this.wallColorsToGridItems(this.props.greyWalls)}
            
            <Grid item xs={12}>
              <div className={classes.gridLabel}>Brown</div>
            </Grid>
            {this.wallColorsToGridItems(this.props.brownWalls)}
            <Grid item xs={12}>
              <div className={classes.gridLabel}>Green</div>
            </Grid>
            {this.wallColorsToGridItems(this.props.greenWalls)}
            <Grid item xs={12}>
              <div className={classes.gridLabel}>Blue</div>
            </Grid>
            {this.wallColorsToGridItems(this.props.blueWalls)}
            <Grid item xs={12}>
              <div className={classes.gridLabel}>Purple</div>
            </Grid>
            {this.wallColorsToGridItems(this.props.purpleWalls)}
            <Grid item xs={12}>
              <div className={classes.gridLabel}>Red</div>
            </Grid>
            {this.wallColorsToGridItems(this.props.redWalls)}
            <Grid item xs={12}>
              <div className={classes.gridLabel}>Orange</div>
            </Grid>
            {this.wallColorsToGridItems(this.props.orangeWalls)}
            <Grid item xs={12}>
              <div className={classes.gridLabel}>Yellow</div>
            </Grid>
            {this.wallColorsToGridItems(this.props.yellowWalls)}
          </Grid>
        </TabPanel>
        <TabPanel value={this.state.ctrlPanelTab} index={1} className={classes.tabPanel}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.gridLabel} style={{paddingBottom: "10px"}}>None</div>
              <div className={this.props.classes.noneColorButton}>
                <img className={this.props.classes.floorImg}
                  src={'/img/none.jpeg'}
                  alt={'none_button_img'}
                  onClick={() => {
                    console.log("clicked on none")
                    if (this.props.onFloorColorPicked) {
                      this.props.onFloorColorPicked(null);
                    }
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.gridLabel}>Hardwood</div>
            </Grid>
            {this.floorColorsToGridItems(this.props.hardwoodFloors)}
            <Grid item xs={12}>
              <div className={classes.gridLabel}>Carpet</div>
            </Grid>
            {this.floorColorsToGridItems(this.props.carpetFloors)}
            <Grid item xs={12}>
              <div className={classes.gridLabel}>Tile</div>
            </Grid>
            {this.floorColorsToGridItems(this.props.tileFloors)}
          </Grid>
        </TabPanel>
        <TabPanel value={this.state.ctrlPanelTab} index={2} className={classes.tabPanel}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.gridLabel}>Windows</div>
            </Grid>
            {this.structElementGridItems(this.windowIds)}
            <Grid item xs={12}>
              <div className={classes.gridLabel}>Doors</div>
            </Grid>
            {this.structElementGridItems(this.doorIds)}
          </Grid>
        </TabPanel>
      </div>);
  }
}

RoomSetup.defaultProps = {
  benMooreWalls: ["70898B", "958775", "BA8867", "D0D6CE", "DEBF87", "E1D7C2", "E6DCAB", "F3F0E9"],
  whiteWalls: ["EFEEE5", "F1EADB", "F6F0E0", "F6F6ED", "F8F5E5", "F8F6E6", "F9F2E0", "FCF4DD"],
  greyWalls: ["7F8386", "9A9FA2", "A2A9B1", "C3C9CD", "CDCCCA", "D8D9D8", "DBD9D4", "DCE1E1"],
  brownWalls: ["9D8174", "B49D93", "CCB9AC", "D2C5B9", "DBCDC5", "DECEBB", "E8DDD8", "EBE3DC"],
  greenWalls: ["7A7665", "908971", "94917F", "A1A07E", "AEAB92", "B8B8A0", "DAD9C9", "E0E3D3"],
  blueWalls: ["485B6E", "527687", "769FB0", "7B939E", "95BAC5", "9FB7C1", "C3D6DD", "D4E2E4"],
  purpleWalls: ["5C5A7B","7F85BE","897DAA","AAB0DB","AFAFCB","CBCFEA","CFD0E0","E2E5F1"],
  redWalls: ["9D2E3B","B04253","DF9B91","E86673","EB8496","F9C9C0","FEDBD3","FFB9B3"],
  orangeWalls: ["D98F50","EC9557","FFA84D","FFB577","FFC26F","FFD5A9","FFDCA6","FFE7CA"],
  yellowWalls: ["E9C335","F1D529","FDE692","FDE875","FFC31D","FFD867","FFE99F","FFF2C3"],
  hardwoodFloors: ['hardwood_0', 'hardwood_1', 'hardwood_2', 'hardwood_3',
    'hardwood_4', 'hardwood_5','hardwood_6','hardwood_7'],
  carpetFloors: ['carpet_0', 'carpet_1', 'carpet_2', 'carpet_3',
    'carpet_4', 'carpet_5','carpet_6','carpet_7'],
  tileFloors: ['tile_0', 'tile_1', 'tile_2', 'tile_3'],
};
export default withStyles(useStyles)(RoomSetup)
