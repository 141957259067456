import React, { Component } from "react";
import { ImageList, ImageListItem, Button, Icon,
  CircularProgress, Typography, TextField, InputAdornment
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import '../index.sass';
import {setAlternativeClose} from "./dashActions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import algoliasearch from 'algoliasearch/lite';

// Set up algoliaClient with search-only API key.
const algoliaClient = algoliasearch('XFU4WVQDCK', '468ba34dc6b499d93b733f63f705e8f3');

/**
 * props expects: item object
 * **/
const useStyles = theme => ({
  imgContainer: {
    flex: '1',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
  img: {
    margin: 'auto',
    display: 'block',
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  formControl: {
    minWidth: 120,
  },
  grid: {
    borderRadius: '8px',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    width: "100%"
  },
  itemTile: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0px',
    borderRadius: '10px',
    backgroundColor: "#FFFFFF",
    border: '0px solid #DDDDDD',
    borderColor: '#BBB9B9',
    boxSizing: 'border-box',
  },
  itemInfo: {
    display:'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: "10px",
    fontSize: '10px',
    color: "#808080"
  },
  vendorInfo: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '60%'
  },
  itemTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: "#000000"
  },
  line: {
    borderBottom: '0px solid',
    borderColor: '#FFFFFF',
    paddingTop: '0px',
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F0F0F0",
    opacity: "0.9",
    borderRadius: "8px"
  },
  searchBar: {
    marginLeft: '5px',
    marginRight: '10px',
    width: '100%',
    borderRadius: '5px',
    whiteSpace: 'nowrap',
    fontSize: '15px',
  },
  addItemButton: {
    marginLeft: '5px',
    marginRight: '5px',
    width: '35%',
    height: '35px',
    borderRadius: '5px',
    backgroundColor: '#741CE5',
    color: "#FFF",
    whiteSpace: 'nowrap',
    fontSize: '15px',
    '&:hover': {
      bottom: "1px",
      right: "1px",
      background: '#6616CC',
      boxShadow: '3px 3px 0px 0px #F9B1AA',
    },
    '&:active': {
      bottom: "0px",
      right: "0px",
      boxShadow: 'none',
      backgroundColor: '#741CE5'
    }
  },
  root: {
    outline: 0,
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '10px',
    paddingBottom: '10px',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: "#FFF",
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtonBar: {
    outline: 0,
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 4, 2),
  },
  paper: {
    outline: 0,
    padding: theme.spacing(0, 4, 2),
    overflowY:'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: '2px solid slategrey'
    }
  }
});


const searchTheme = createTheme({
  palette: {
    primary: {
      main: '#741CE5',
    }
  },
});

function sortWishlist(wishlist) {
  let notimestamp = wishlist.filter(function(e) {
    return e.BookmarkedStamp <= 0;
  });

  let timestamped = wishlist.filter(function(e) {
    return e.BookmarkedStamp > 0;
  });

  timestamped.sort(function (a, b) {
    return b.BookmarkedStamp - a.BookmarkedStamp;
  });

  notimestamp.sort(function (a, b) {
    return ('' + a.Id).localeCompare(b.Id);
  });

  return timestamped.concat(notimestamp);
}

class AdditionPanel extends Component {
  constructor(props) {
    super(props);
    this.gridRef = React.createRef();
    this.searchRef = React.createRef();

    // Define the algolia Client
    const algoliaIndex = algoliaClient.initIndex('dabble');
    const queryIndex = algoliaClient.initIndex('dabble_query_suggestions');

    this.state = {
      initialized: false,
      algoliaIndex: algoliaIndex,
      queryIndex: queryIndex,
      queryOptions: [],
      inputValue: ""
    };
  }

  componentDidMount() {
    if (this.state.queryOptions.length === 0) {
      // Set up the queryOptions.
      const queryIndex = this.state.queryIndex;
      queryIndex.search('',
      {                          
        hitsPerPage: 1000
        
      })
      .then(({ hits }) => {
        this.setState({queryOptions: hits});
      });
    }
   
    if (this.props.additions) {
      if (!this.state.initialized) {
        // We have a state update, set the new props as the wishlist.
        const wishlist = sortWishlist(this.props.additions);
        this.setState({
          results: wishlist,
          wishlist: wishlist,
          initialized: true
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.additions) {
      if (!this.state.initialized || (this.props.additions.length !== prevProps.additions.length)) {
        // We have a state update, set the new props as the wishlist.
        const wishlist = sortWishlist(this.props.additions);

        // Reset search bar
        this.searchRef.current.value = '';

        this.setState({
          results: wishlist,
          wishlist: wishlist,
          initialized: true
        })
      }
    }
  }

  render() {
    const { classes, onItemGettingDragged, additions } = this.props;
    const { wishlist, results } = this.state;
    const additionsPanel = this;
    let itemList = results;
    if (!results) {
      if (!wishlist) {
        itemList = additions ? additions : [];
      }
      else {
        itemList = wishlist;
      }
    }

    return (
      <div id="alternatives" style={{height: this.props.height}}>
        <div
          className="alternativesctrlpanel"
          style={{alignItems: "center", marginBottom: "10px", display: 'flex', justifyContent: "center"}}>
          <ThemeProvider theme={searchTheme}>
            <Autocomplete
              options={this.state.queryOptions}
              className={classes.searchBar}
              disableClearable
              freeSolo
              inputValue={this.state.inputValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={this.searchRef}
                  placeholder="Search"
                  variant="outlined"
                  margin="dense"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* <SearchIcon /> */}
                        <Icon>
                          <img alt="algolia-logo" src="/img/icons/algolia-logo.svg" style={{height: "100%"}}/>
                        </Icon>
                      </InputAdornment>
                    ),
                    style: {
                      // Have to handle height here, shift up to line up with button.
                      height: 35,
                      top: "-2px",
                    },
                    classes: {
                      root: classes.searchBar
                    },
                    onFocus: (e) => {
                      if (typeof this.props.deselectItem === 'function') {
                        this.props.deselectItem()
                      }
                    },
                  }}
                  onChange={(e) => {
                    const input = e.target.value;
                    this.setState({inputValue: input})
                    
                    if (input === '') {
                      this.setState({results: wishlist});
                    } else {
                      const algoliaIndex = this.state.algoliaIndex;
                      algoliaIndex.search(input,
                        {
                          filters: 'userID:' + this.props.userID,
                          hitsPerPage: 1000
                        })
                        .then(({ hits }) => {
                          this.setState({results: hits});
                        })
                    }
                  }}>
                </TextField>
              )}
              getOptionLabel={(option) => {
                return option.query ? option.query : "";
              }}
              onChange={(event, value, reason) => {
                let input = '';
                if (reason !== "clear") {
                  input = value.query ? value.query : value;
                }
                this.setState({inputValue: input});

                if (input === '') {
                  this.setState({results: wishlist});
                } else {
                  const algoliaIndex = this.state.algoliaIndex;
                  algoliaIndex.search(input,
                    {
                      filters: 'userID:' + this.props.userID,
                      hitsPerPage: 1000
                    })
                    .then(({ hits }) => {
                      if (reason === "clear") {
                        // No input, just use the wishlist to ensure it's up to date
                        hits = wishlist;
                      }
  
                      this.setState({results: hits});
                    })
                }
              }}>
            </Autocomplete>
          </ThemeProvider>
          <Button
            id={"add_item_button"}
            className={classes.addItemButton}
            title={"Add Item"}
            variant="contained"
            onClick={(e) => {
              if (this.props.onAddItemClicked) {
                this.props.onAddItemClicked();
              }
            }}>
            Add Item
          </Button>
        </div>
        <div id="additionpanel" style={{overflowY: "auto", height: "100%", display: 'flex', justifyContent: "center", flexWrap: "wrap"}}>
          { !this.state.initialized
            ? <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%",
                height: "100%", borderRadius: "10px"}}>
                <ThemeProvider theme={searchTheme}>
                  <CircularProgress disableShrink />
                </ThemeProvider>
              </div>
            : null
          }
          { this.state.initialized && additions && additions.length === 0
            ? <div style={{width: "80%", height: "150px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around"}}>
                <Typography variant="subtitle2">
                  You don't have any saved products yet. Get the Dabble Chrome extension to add some!
                </Typography>
                <Button style={{width: '100%', height: 67, borderRadius: 10, color: 'white', background: '#9250FF'}}
                  variant="contained" size='large'
                  href={"https://chrome.google.com/webstore/detail/dabble/lnlgccgpbhgpnlgjignoencljjpoleca"}>
                  Install Extension
                  <img alt="chrome-store-logo" style={{marginLeft: 30, height: 55}} src="/img/icons/chrome-store.png"/>
                </Button>
              </div>
            : null
          }
          { itemList.length === 0 && this.state.initialized && additions && additions.length !== 0
            ? <div style={{width: "80%", height: "150px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around"}}>
                <Typography variant="subtitle2">
                  No products found <span role="img">☹</span>️
                </Typography>
              </div>
            : null
          }
          <ImageList
            ref={this.gridRef}
            className={classes.grid}
            rowHeight={160} cols={2} gap={10}
            style={{margin: 0, paddingLeft: 5, paddingRight: 5}}>
              { itemList.map(result => {
                let tile;
                let itemID;

                if (result.objectID && wishlist) {
                  tile = wishlist.find(item => item.Id === result.objectID);
                  itemID = result.objectID;
                } else if (result.Id) {
                  tile = result;
                  itemID = result.Id;
                }

                if (!tile) {
                  return null;
                }

                return (
                  <ImageListItem id={"wishlist_grid_" + itemID} key={itemID} draggable
                    onDragStart={(e) => {
                      if (tile.imgUrl) {
                        console.log("Dragging product " + itemID);
                        onItemGettingDragged(tile);
                      }
                    }}
                    style={{border: "0px none #000", cursor: "pointer"}}
                    onClick = {(e) => {
                      if (additionsPanel.props.onAdditionClicked && tile.imgUrl) {
                        additionsPanel.props.onAdditionClicked(tile);
                      }
                    }}>
                    <div className={classes.itemTile}>
                      <div className={classes.imgContainer}>
                        { !tile.imgUrl ?
                          <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "absolute"}}>
                              <Typography variant="subtitle2" style={{position: "-10px", padding: "10px"}}>
                                Finalizing...
                              </Typography>
                              <ThemeProvider theme={searchTheme}>
                                <CircularProgress disableShrink />
                              </ThemeProvider>
                            </div>
                          </div> :
                          <img className={classes.img} src={tile.imgUrl} alt={tile.Title} loading="lazy"/>
                        }
                        { tile.Depthtype === "rug" ?
                          <img style={{display: "none"}} src={tile.skewedImgUrl} alt={tile.Title} loading="lazy"/> : null
                        }
                      </div>
                      <div className={classes.line} />
                      <div className={classes.itemInfo}>
                        <div className={classes.vendorInfo}>{tile.vendorName}</div>
                        <div className={classes.itemTitle}>{tile.Title}</div>
                        { tile.Price ? <div style={{fontWeight: 'bold'}}>${tile.Price}</div> : null }
                      </div>
                    </div>
                  </ImageListItem>
                )
              })}
          </ImageList>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAlternativePanelClose: () => dispatch(setAlternativeClose())
  }
};

function mapStateToProps(state) {
  return {
    isAlternativePanelOpen: state.dash.isAlternativePanelOpen
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(useStyles))(AdditionPanel);
