import React, { Component } from "react";
import { Button, Icon, IconButton, AppBar, Toolbar } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import firebase from 'firebase/app';
import Konva from 'konva';
import analytics from '../analytics/Analytics';
import { Stage, Layer, Image, Line, Circle } from 'react-konva';
import Dropzone from 'react-dropzone'
import '../introjs.css';
import '../index.sass';
import _ from 'lodash';


const storage = firebase.storage();
const firebaseStorage = storage.ref();

const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'serif'
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      }
    },
    MuiTab: {
      root: {
        lineHeight: 1,
        minWidth: 0,
        '@media (min-width: 600px)': {
          minWidth: 0,
        },
      },
      wrapper: {
        textTransform: 'none',
        fontSize: '10px',
      },
      textColorSecondary: {
        color: 'white',
      }
    },
    MuiImageListItem: {
      item: {
        borderRadius: '10px',
        cursor: 'pointer',
        boxSizing: 'border-box',
      }
    },
    MuiImageListItemBar: {
      root: {
        backgroundColor: 'rgb(0, 0, 0, 0)',
      }
    }
  }
});

const styles = theme => ({
  root: {
    outline: 0,
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '10px',
    paddingBottom: '10px',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: "#fff",
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
    color: "black"
  },
  overlay: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textButton: {
    paddingTop: "12px",
    paddingBottom: "12px",
    height: '90%',
    borderRadius: '5px',
    backgroundColor: '#F0F2F5',
    whiteSpace: 'nowrap',
    color: '#000000',
    fontSize: 13,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#DAD9D9'
    }
  },
  openDabbleButton: {
    backgroundColor: '#fff',
    fontSize: 18,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#000',
    '&:hover': {
      backgroundColor: '#F0C6FF',
      color: '#000'
    }
  },
  uploadContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 320,
    backgroundColor: "#F0C6FF",
    flexDirection: "column",
    marginBottom: 20,
    borderStyle: "dashed",
    borderColor: "black",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#C5C7FF',
    }
  },
  introPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: "white",
    width: 1000,
    marginTop: 80,
  },
  introPanelMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: "white",
    width: "85%",
    marginTop: 40,
  },
  icon: {
    color: 'white',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.3em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '0px solid slategrey'
    }
  }
});


const withMediaQuery = () => Component => props => {
  const isTabletOrMobile = useMediaQuery('(max-width: 480px)');
  return <Component isMobile={isTabletOrMobile} {...props} />;
};

class ImageCleanupApp extends Component {
  state = {
    stageWidth: 0,
    stageHeight: 0,
    isDrawing: false,
    cleaning: false,
    originalHeld: false,
    imagesHistory: [],
    mousePos: {x: -1, y: -1}
  };

  constructor(props) {
    super(props);
    this.stageRef = React.createRef();
    this.lineRef = React.createRef();
    this.maskCanvas = document.createElement('canvas');
    this.imgCanvas = document.createElement('canvas');
    analytics.track('Cleanup app visited');
  }


  // Load the spacebar handlers and pinterest share script after the react component mounts
  async componentDidMount(){
    // observer checking for resize of container element. thought i would have to connect
    // it to moodboard not container... but it works this way??
    this.container = document.getElementById("container");
  }

  handleLoad = async () => {
    const imagesHistory = this.state.imagesHistory.map(image => image);
    imagesHistory.push(this.image);

    const maxStageHeight = Math.max(200, window.screen.height * 0.75);
    const maxStageWidth = Math.max(300, window.screen.width * 0.75);
    let screenScale = Math.min(window.innerHeight / window.screen.height,
      window.innerWidth / window.screen.width);

    this.boardAspectRatio = this.image.height / this.image.width;
    let stageWidth = 0, stageHeight = 0;
    const widthForMaxHeight = maxStageHeight / this.boardAspectRatio;
    if (widthForMaxHeight <= maxStageWidth) {
      stageHeight = maxStageHeight * screenScale;
      stageWidth = stageHeight / this.boardAspectRatio;
    } else {
      stageWidth = maxStageWidth * screenScale;
      stageHeight = stageWidth * this.boardAspectRatio;
    }

    this.setState({
      image: this.image,
      stageWidth,
      stageHeight,
      line: [],
      cleaning: false,
      imagesHistory,
    });
  };

  handleMouseDown = (e) => {
    if (this.state.cleaning) {
      return;
    }

    const pos = e.target.getStage().getPointerPosition();
    this.setState({isDrawing: true, line: [pos.x, pos.y]});
  };

  handleMouseMove = (e) => {
    // no drawing - skipping
    this.setState({mousePos: e.currentTarget.getPointerPosition()});
    if (!this.state.isDrawing) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    // add point
    const newLine = this.state.line.map(pt => pt);
    newLine.push(point.x);
    newLine.push(point.y);

    // replace last
    this.setState({line: newLine});
  };

  handleMouseUp = async () => {
    if (!this.state.isDrawing) {
      return;
    }

    analytics.track('User performed clean operation');
    this.setState({isDrawing: false});

    // generate mask!!
    this.refreshCanvasMask();
    const maskData = this.maskCanvas.toDataURL();

    // hit the service endpoint!
    this.imgCanvas.width = this.state.image.width;
    this.imgCanvas.height = this.state.image.height;

    // Copy the image contents to the canvas
    const ctx = this.imgCanvas.getContext("2d");
    ctx.drawImage(this.state.image, 0, 0);

    const imgData = this.imgCanvas.toDataURL();
    const imageBlob = this.dataURItoBlob(imgData);

    this.setState({cleaning: true});

    const period = 300;
    this.anim = new Konva.Animation(frame => {
        this.lineRef.current.opacity(Math.sin(frame.time / period) / 4 + 0.5);
      }, this.lineRef.current.getLayer());
    this.anim.start();

    const newImgUrl = await this.inpaint(imageBlob, maskData);
    if (this.state.cleaning) {
      this.image = new window.Image();
      this.image.crossOrigin = "Anonymous";
      this.image.src = newImgUrl;
      this.image.addEventListener('load', this.handleLoad);
      this.anim.stop();
      this.lineRef.current.opacity(1);
    }
  };


  dataURItoBlob(dataURI) {
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const binary = Buffer.from(dataURI.split(',')[1], "base64")

    return new Blob([binary], { type: mime })
  }


  onRedditClick = (event) => {
    const shareUrl = "https://app.dabble.so/roomcleaner"
    const postTitle = "A magic eraser for room photos by dabble.so";
    const redditUrl = "http://www.reddit.com/submit?url=" + encodeURIComponent(shareUrl)
      + "&title=" + encodeURIComponent(postTitle);

    console.log("Reddit url = "+ redditUrl);
    window.open(redditUrl, '_blank');
  }

  onFacebookClick = (event) => {
    const shareUrl = "https://app.dabble.so/roomcleaner"
    const facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' +
      encodeURIComponent(shareUrl);

    // go to facebook share
    window.open(facebookUrl, 'facebook-share-dialog', 'width=626,height=436');
  };

  onTweetClick = (event) => {
    const shareUrl = "https://app.dabble.so/roomcleaner"
    const postText = "a magic eraser for room photos by @tryDabble";
    const hashtags = "homedesign,interiors,dabble";
    const twitterUrl = "http://twitter.com/share?text=" + encodeURIComponent(postText)
      + "&url=" + encodeURIComponent(shareUrl)
      + "&hashtags=" + encodeURIComponent(hashtags);

    window.open(twitterUrl, '_blank');
  }


  async getImageSignedUrl(filePath) {
    let imageStorageLoc = filePath;
    let newMetadata = {
      cacheControl: 'public,max-age=400000',
    }
    try {
      const imageRef = firebaseStorage.child(imageStorageLoc);
      if (firebase.currentUser) {
        imageRef.updateMetadata(newMetadata);
      }
      const url = await imageRef.getDownloadURL();
      return {url};
    } catch (err) {
      return {url : null};
    }
  }

  async inpaint(imageBlob, maskBase64) {
    const fd = new FormData()
    fd.append('file', imageBlob)
    const mask = this.dataURItoBlob(maskBase64)
    fd.append('mask', mask)

    const res = await fetch("https://image-cleanup.dabble.so/clean_handler", {
      method: 'POST',
      body: fd,
    });

    const result = await res.json();

    const imgUrl = await this.getImageSignedUrl(
      `cleanup/${result.result_id}_output.png`);

    return imgUrl.url;
  }

  async depth(imageBlob) {
    const fd = new FormData()
    fd.append('file', imageBlob)

    const res = await fetch("https://rgb-depth-semantics-converter.dabble.so/depth_handler", {
      method: 'POST',
      body: fd,
    });

    const result = await res.json();

    return result;
  }


  async semantics(imageBlob) {
    const fd = new FormData()
    fd.append('file', imageBlob)

    const res = await fetch("https://rgb-depth-semantics-converter.dabble.so/semantics_handler", {
      method: 'POST',
      body: fd,
    });

    const result = await res.json();

    return result;
  }

  drawLine(ctx, line) {
    const ratio = this.state.image.width / this.state.stageWidth;

    ctx.fillStyle = '#fff';
    ctx.beginPath();
    ctx.moveTo(line[0] * ratio, line[1] * ratio);
    for (let i = 2; i < line.length - 1; i=i+2) {
      ctx.lineTo(line[i] * ratio, line[i + 1] * ratio);
    }

    ctx.closePath();
    ctx.fill();
  }

  refreshCanvasMask = () => {
    if (!this.state.image) {
      return;
    }

    this.maskCanvas.width = this.state.image.width;
    this.maskCanvas.height = this.state.image.height;
    const ctx = this.maskCanvas.getContext('2d');
    if (!ctx) {
      throw new Error('could not retrieve mask canvas');
    }

    this.drawLine(ctx, this.state.line);
  }

  render() {
    const { classes, isMobile } = this.props;
    const toolbar = (
      <AppBar elevation={0} position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          { Boolean(this.state.image)
            ? <div style={{paddingLeft: 20, width: "30%"}}>
                <Button startIcon={<Icon>arrow_back_ios</Icon>}
                  style={{ fontSize: 18, paddingLeft: isMobile ? 0 : 15, paddingRight: 15}}
                  onClick={(e) => {
                    if (this.anim) {
                      this.anim.stop();
                    }
                    this.setState({
                      image: null,
                      line: [],
                      cleaning: false,
                      originalHeld: false,
                      imagesHistory: [],
                    });
                  }}>
                  { isMobile ? null : <b>New Image</b> }
                </Button>
              </div>
            : null }
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            { isMobile && Boolean(this.state.image) ? null :
              <img style={{width: isMobile ? 150 : 200, marginLeft: 10, marginRight: 10}}
                alt="room-cleaner-logo" src="/img/cleanup_logo.svg"/> }
          </div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: "30%"}}>
            <IconButton onClick={this.onTweetClick} style={{backgroundColor: '#ffffff', width: '35px'}}>
              <Icon>
                <img alt="twitter-logo" src="/img/twitter_border.svg"/>
              </Icon>
            </IconButton>
            <IconButton onClick={this.onFacebookClick} style={{backgroundColor: '#ffffff', width: '35px'}}>
              <Icon>
                <img alt="facebook-logo" src="/img/facebook_border.svg"/>
              </Icon>
            </IconButton>
            <IconButton onClick={this.onRedditClick} style={{backgroundColor: '#ffffff', width: '35px'}}>
              <Icon>
                <img style={{width: "100%"}} alt="reddit-logo" src="/img/reddit_border.svg"/>
              </Icon>
            </IconButton>
            <a style={{paddingLeft: 10, paddingRight: 10}} target="_blank"
              rel="noopener noreferrer" href="/">
              <b>Dabble Home</b>
            </a>
          </div>
        </Toolbar>
      </AppBar>);

    const introPanel = (
      <div className={isMobile ? classes.introPanelMobile : classes.introPanel}>
        <div style={{display: "flex", justifyContent: "flex-start", flexDirection: "column",
          width: isMobile ? "100%" : "50%", marginRight: isMobile ? 0 : 20}}>
          <h1 style={{marginTop: 0, fontSize: 50}}>
            A <b style={{color: "#741CE5"}}>magic eraser</b> for your room photos.
          </h1>
          { isMobile ? null : 
            <p style={{marginTop: 0, marginBottom: 20, fontSize: 20}}>
              Remove unwanted items from your photos when designing a room, renting your space, or selling online!
            </p> }
          { isMobile ? null : 
            <img style={{borderRadius: 5, width: "60%"}} alt="sample room" src="/img/cleanup.gif"/> }
        </div>
        <div style={{display: "flex", justifyContent: "space-between",
          flexDirection: "column", width: isMobile ? "100%" : "50%", height: "100%",
          paddingLeft: isMobile ? 0 : 50}}>
          <div className={classes.uploadContainer} style={{height: isMobile ? 200 : 320}}>
            <Dropzone onDrop={acceptedFiles => {
              const item = acceptedFiles[0];
              if (item.type.indexOf("image") === 0) {
                const reader = new FileReader();
                reader.onload = async (event) => {
                  this.pastedImgDataUrl = event.target.result;
                  this.image = new window.Image();
                  this.image.crossOrigin = "Anonymous";
                  this.image.src = this.pastedImgDataUrl;
                  this.image.addEventListener('load', this.handleLoad);
                };

                reader.readAsDataURL(item);
              }
            }}>
              {({getRootProps, getInputProps}) => (
                <div style={{display: "flex", justifyContent: "center", flexDirection: "column",
                  alignItems: "center", height: "100%", width: "100%", cursor: "pointer"}} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p style={{display: "flex", fontSize: 20}}>
                      <Icon style={{marginRight: 10}}>upload</Icon>
                      Upload image or drop a file
                    </p>
                </div>
              )}
            </Dropzone>
          </div>
          <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
            <p>Try an example</p>
            <div style={{display: "flex", justifyContent: "flex-end", flexDirection: "row"}}>
              { isMobile ? null :
                <img style={{width: 80, height: "100%", marginLeft: 10, cursor: "pointer", borderRadius: 2}}
                  alt="sample room 1" src="/img/cleanup-room1.jpeg" onClick={(e) => {
                    this.image = new window.Image();
                    this.image.crossOrigin = "Anonymous";
                    this.image.src = "/img/cleanup-room1.jpeg";
                    this.image.addEventListener('load', this.handleLoad);
                  }}/> }
              <img style={{width: 80, height: "100%", marginLeft: 10, cursor: "pointer", borderRadius: 2}}
                alt="sample room 2" src="/img/cleanup-room2.jpeg" onClick={(e) => {
                  this.image = new window.Image();
                  this.image.crossOrigin = "Anonymous";
                  this.image.src = "/img/cleanup-room2.jpeg";
                  this.image.addEventListener('load', this.handleLoad);
                }}/>
              { isMobile ? null :
                <img style={{width: 80, height: "100%", marginLeft: 10, cursor: "pointer", borderRadius: 2}}
                  alt="sample room 3" src="/img/cleanup-room3.jpeg" onClick={(e) => {
                    this.image = new window.Image();
                    this.image.crossOrigin = "Anonymous";
                    this.image.src = "/img/cleanup-room3.jpeg";
                    this.image.addEventListener('load', this.handleLoad);
                  }}/> }
              <img style={{width: 50, height: "100%", marginLeft: 10, cursor: "pointer", borderRadius: 2}}
                alt="sample room 4" src="/img/cleanup-room4.jpeg" onClick={(e) => {
                  this.image = new window.Image();
                  this.image.crossOrigin = "Anonymous";
                  this.image.src = "/img/cleanup-room4.jpeg";
                  this.image.addEventListener('load', this.handleLoad);
                }}/>
            </div>
          </div>
        </div>
      </div>);

    const moodboard = (
      <div id="moodboard" style={{width: "100%", marginTop: 30,
        display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start",
          alignItems: "center", marginTop: 0}}>
          <div style={{display: "flex", flexDirection: "row", justifyContent: isMobile ? "center" : "flex-start",
            color: "grey", alignItems: "center", width: "100%", marginBottom: 10}}>
            {this.state.cleaning ? "Erasing..." : "Draw around the object to erase"}
          </div>

          <div style={{width: this.state.stageWidth, height: this.state.stageHeight}}>
            <Stage ref={ref => { this.stageRef = ref; }}
              width={this.state.stageWidth} height={this.state.stageHeight}
              style={{borderRadius: '10px', overflow: 'hidden', border: '1px solid #DDDDDD'}}
              onMouseEnter={(e) => {
                this.stageRef.container().style.cursor = "none";
                this.setState({mousePos: e.currentTarget.getPointerPosition()});
              }}
              onMouseLeave={(e) => {
                this.stageRef.container().style.cursor = "default";
                this.setState({mousePos: {x: -1, y: -1}});
              }}
              onMouseDown={this.handleMouseDown}
              onMouseMove={this.handleMouseMove}
              onMouseUp={this.handleMouseUp}
              onTouchStart={this.handleMouseDown}
              onTouchMove={this.handleMouseMove}
              onTouchEnd={this.handleMouseUp}>
              <Layer>
                { this.state.image
                  ? <Image x={0} y={0}
                      width={this.state.stageWidth}
                      height={this.state.stageHeight}
                      image={this.state.image}/>
                  : null }
                { this.state.imagesHistory.length > 0 && this.state.originalHeld
                  ? <Image x={0} y={0}
                      width={this.state.stageWidth}
                      height={this.state.stageHeight}
                      image={this.state.imagesHistory[0]}/>
                  : null }
                <Line
                  ref={this.lineRef}
                  key={"line"}
                  points={this.state.line}
                  stroke="#A110FA"
                  fill="#D354FF88"
                  strokeWidth={5}
                  tension={0.5}
                  lineCap="round"
                  closed={this.state.cleaning}/>
                <Circle
                  x={this.state.mousePos.x}
                  y={this.state.mousePos.y}
                  key={"cursor"}
                  fill="#A110FA"
                  radius={5}/>
              </Layer>
            </Stage>
          </div>

          <div style={{display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "space-between",
            alignItems: "center", width: "100%", marginTop: 10}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
              <Button variant="text"
                disabled={this.state.imagesHistory.length < 2}
                onMouseDown={e => this.setState({originalHeld: true})}
                onMouseUp={e => this.setState({originalHeld: false})}
                style={{ fontSize: 18, paddingLeft: 15, paddingRight: 15}}>
                <b>Original</b>
              </Button>
              <Button variant="text" startIcon={<Icon>undo</Icon>}
                disabled={this.state.imagesHistory.length < 2}
                onClick={(e) => {
                  const newImagesHistory = this.state.imagesHistory.map(image => image);
                  newImagesHistory.pop();
                  const newImage = newImagesHistory.at(newImagesHistory.length - 1);
                  this.setState({imagesHistory: newImagesHistory, image: newImage});
                }}
                style={{ fontSize: 18, paddingLeft: 15, paddingRight: 15}}
                >
                { isMobile ? null : <b>Undo</b> }
              </Button>
              <Button variant="text" startIcon={<Icon>download</Icon>}
                disabled={this.state.imagesHistory.length < 2}
                onClick={(e) => {
                  let link = document.createElement("a");
                  link.download = "result.png";
                  link.href = this.stageRef.toDataURL().replace("image/png", "image/octet-stream");
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
                style={{ fontSize: 18, paddingLeft: 15, paddingRight: 15}}>
                { isMobile ? null : <b>Download</b> }
              </Button>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
              <Button variant="text"
                onClick={(e) => {
                  let link = document.createElement("a");
                  link.href = "/";
                  link.target="_blank";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  analytics.track('Open Dabble button clicked');
                }}
                className={classes.openDabbleButton}>
                <b>Open Dabble</b>
              </Button>
            </div>
          </div>
        </div>
      </div>);

    // get the img url for the current collection. This value is sent to share popover
    return (
      <ThemeProvider theme={theme}>
        <div id="container">
          {toolbar}
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
            alignItems: 'center', width: '100%'}}>
            {!Boolean(this.state.image) ? introPanel : moodboard}
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(withMediaQuery()(ImageCleanupApp));
