import React, { Component } from "react";
import clsx from 'clsx';
import { ImageList, ImageListItem, CircularProgress, Button, Icon, IconButton, Drawer, Snackbar,
  List, ListItem, ListItemText, ListItemSecondaryAction, Toolbar, Divider, Avatar } from "@material-ui/core";
import { createTheme, useTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cloudFunctionsFirebase from "../CloudFunctionsFirebase.jsx";
import Navbar from '../layout/Navbar.js'
import firebase from 'firebase/app';
import analytics from '../analytics/Analytics';
import Swal from 'sweetalert2';
import psl from 'psl';
import { isWidthUp } from "@material-ui/core/withWidth";
import '../index.sass';


const db = firebase.firestore();

const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'serif'
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      }
    },
    MuiImageListItem: {
      tile: {
        borderRadius: '10px',
        cursor: 'pointer',
        boxSizing: 'border-box'
      }
    },
  }
});

/**
 * props expects: item object
 * **/
const useStyles = theme => ({
  imgContainer: {
    flex: '1',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
  img: {
    margin: 'auto',
    display: 'block',
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  formControl: {
    minWidth: 120,
  },
  grid: {
    borderRadius: '8px',
  },
  snackBar: {
    backgroundColor: "#CDCDCD",
    color: "#000"
  },
  itemTile: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: "#F5F5F5",
    border: '0px solid #DDDDDD',
    borderColor: '#BBB9B9',
    boxSizing: 'border-box',
  },
  itemInfo: {
    display:'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: "10px 0px 10px 10px",
    fontSize: '14px',
    color: "#808080",
    maxWidth: "60%",
  },
  creatorInfo: {
    display:'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'column',
    padding: "10px 0px 10px 10px",
    fontSize: '10px',
    color: "#808080",
    maxWidth: "40%",
  },
  vendorInfo: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  itemTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: "#000000"
  },
  line: {
    borderBottom: '0px solid',
    borderColor: '#FFFFFF',
    paddingTop: '0px',
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F0F0F0",
    opacity: "0.9",
    borderRadius: "8px"
  },
  addItemButton: {
    marginRight: '5px',
    height: '35px',
    width: '35px',
    borderRadius: '5px',
    backgroundColor: '#F0F0F0',
    whiteSpace: 'nowrap',
  },
  root: {
    outline: 0,
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '10px',
    paddingBottom: '10px',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: "#FFF",
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtonBar: {
    outline: 0,
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  paper: {
    outline: 0,
    padding: theme.spacing(0, 4, 2),
    overflowY:'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: '2px solid slategrey'
    }
  },
  drawer: {
    flexShrink: 0,
  },
  drawerOpen: {
    width: 350,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const withMediaQuery = () => Component => props => {
  const isTabletOrMobile = useMediaQuery('(max-width: 480px)');

  const width = useWidth();
  return <Component isMobile={isTabletOrMobile} width={width} {...props} />;
};

class Explorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNum: 0,
      showProductPanel: false,
      fetchingNewPage: false,
      openFilters: true,
      loadingProducts: true,
      snackBarOpen: false,
      explorerHintEnabled: window.localStorage.showExploreHints === "true",
    }

    const bgColorsArray = ["#F3E7E7", "#E7EBF3", "#F3F1E7", "#E7F3E8", "#F3E7F3", "#EDF8FF"];
    this.state.bgColorsArray = bgColorsArray;

    const typeDict = {
      tableLamps: {name: "Table Lamps", type: "tablelamp"},
      floorLamps: {name: "Floor Lamps", type: "floorlamp"},
      rugs: {name: "Rugs", type: "rug"},
      arts: {name: "Art", type: "wallart"},
      pillows: {name: "Pillows", type: "pillow"},
      sofas: {name: "Sofas & Sectionals", type: "sofa"},
      chairs: {name: "Chairs", type: "chairfaceleft"},
      coffeeTables: {name: "Coffee Tables", type: "coffeetable"},
      sideTables: {name: "Side Tables", type: "sidetable"},
      nightstands: {name: "Nightstands", type: "nightstand"},
      sideboards: {name: "Consoles & Sideboards", type: "sideboard"},
      benches: {name: "Benches", type: "bench"},
      desks: {name: "Desks", type: "desk"},
      ottomans: {name: "Ottomans & Poufs", type: "ottoman"},
      beds: {name: "Beds", type: "bed"},
      wallpaper: {name: "Wallpaper", type: "wallpaper"},
      plants: {name: "Plants", type: "floorplant"},
    };

    this.state.typeDict = typeDict;
    this.gridRef = React.createRef();
    this.exploreRef = React.createRef();
    this.pageLimit = 27;
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
  }

  // handle authentication state changed
  onAuthStateChanged = (user) => {
    if (user) {
      console.log("logged in");
      this.setState({user});
      db.collection("users/").doc(user.uid)
        .onSnapshot(async (doc) => {
          const userData = doc.data();
          if (!userData || !userData.bookmarks) {
            return;
          }

          userData.id = user.uid;
          this.setState({userData});
        });

      analytics.track("Explore page visited");
      cloudFunctionsFirebase.getCuratedItems(null, null, this.pageLimit, 0, false, (itemsList) => {
        this.setState({products: itemsList, loadingProducts: false});
      });
    }
  };

  handleItemTypeChange = (event) => {
    const itemType = event.target.value;
    this.setState({itemType});
  }

  handleProductPanelClose = () => {
    analytics.track ("Product Panel Closed")
    this.setState({showProductPanel : false});
  };

  favItem (item) {
    if (!(item.Id in this.state.userData.bookmarks.items)) {
      analytics.track("Added product to wishlist", {
        'clicksource': 'explore page',
        "URL": item.URL,
        "item vendor": item.vendorName,
        "item type": item.Depthtype
      });
      cloudFunctionsFirebase.bookmarkItemFirestore(item.Id, (result) => {
        if (!result) {
          Swal.fire('Failed to bookmark item', '', 'error');
        }
      })
      this.snackBarText = "Added to Wishlist!";
    } else {
      analytics.track("Removed product from wishlist", {
        'clicksource': 'explore page',
        "URL": item.URL,
        "item vendor": item.vendorName,
        "item type": item.Depthtype
      });
      cloudFunctionsFirebase.removeBookmarkItemFirestore(item.Id, (result) => {
        if (!result) {
          Swal.fire('Failed to remove bookmark item', '', 'error');
        }
      })
      this.snackBarText = "Removed from Wishlist";
    }

    // turn off onboarding if an item is faved during it
    if (this.state.explorerHintEnabled) {
      this.setState({explorerHintEnabled: false});
    }
    this.setState({snackBarOpen: true});
  }

  handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackBarOpen: false
    })
  };

  addExploreItem = (tile, index) => {
    if (!tile) {
      return;
    }

    let avatar;
    let userTitleString;
    if (tile.CreatedBy) {
      avatar = <Avatar src={tile.creatorInfo.profilePicUrl}/>;
      userTitleString = "";
      if (tile.creatorInfo.admin === true) {
        const protoMatch = /^(https?|ftp):\/\//;
        const cleanDomain = tile.URL.replace(protoMatch, '').split('/')[0].split('?')[0];
        const linkURLString = psl.parse(cleanDomain).domain.split('.')[0];
        avatar = <Avatar src={"/img/vendors/" + linkURLString + ".jpg"}/>;
        userTitleString = "@" + linkURLString;
      }
      else if (tile.creatorInfo.userName) {
        userTitleString = "@" + tile.creatorInfo.userName;
      }
      else if (tile.creatorInfo.firstName) {
        userTitleString = tile.creatorInfo.firstName;
      } 
      else {
        const protoMatch = /^(https?|ftp):\/\//;
        const cleanDomain = tile.URL.replace(protoMatch, '').split('/')[0].split('?')[0];
        const linkURLString = psl.parse(cleanDomain).domain.split('.')[0];
        avatar = <Avatar src={"/img/vendors/" + linkURLString + ".jpg"}/>;
        userTitleString = "@" + linkURLString;
      }
    }

    const { classes } = this.props;
    const color = "0px none #000";
    const hovered = tile.Id === this.state.hoverTileId;
    return (
      <ImageListItem id={"tile_" + index} key={"tile_" + index} draggable="true" 
        style={{border: color, cursor: "pointer"}}
        onMouseEnter={() => {
          this.setState({hoverTileId: tile.Id});
        }}
        onMouseLeave={() => {
          this.setState({hoverTileId: null});
        }}
        onClick={(e) => {
          console.log(tile);
          if (!this.state.explorerHintEnabled) {
            this.setState({itemSelected: tile, showProductPanel: true});
            analytics.track_item_info_click('explore page',
              tile.URL, tile.vendorName, tile.Depthtype);
          }
        }}>
        <div className={classes.itemTile} style={{backgroundColor: "#F5F5F5"}}>
          <div style={{display: hovered || (index === 0 && this.state.explorerHintEnabled)  ? "block" : "none", position: 'absolute',
            top: 0, right: 0, borderRadius: "10px"}}>
            <IconButton aria-label="favItem" style={{color: 'black'}} onClick={(e) =>  {
              e.stopPropagation();
              this.favItem(tile);
            }}>
              {
                tile.Id in this.state.userData.bookmarks.items 
                  ? <Icon style={{color: "#B77EFF"}}>favorite</Icon>
                  : <Icon>favorite_outlined</Icon>
              }
            </IconButton>
          </div>
          <div className={classes.imgContainer}>
            { !tile.imgUrl ?
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <CircularProgress disableShrink />
              </div> :
              <img className={classes.img} src={tile.imgUrl} alt={tile.Title} loading="lazy"/>
            }
          </div>
          <div className={classes.line}/>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <div className={classes.itemInfo}>
              <div className={classes.vendorInfo}>{tile.vendorName}</div>
              <div className={classes.itemTitle}>{tile.Title}</div>
              <div style={{fontWeight: 'bold'}}>${tile.Price}</div>
            </div>
            <div className={classes.creatorInfo}>
              {avatar}
              <div style={{paddingTop: 10, fontSize: 14}} className={classes.vendorInfo}>
                {userTitleString}
              </div>
            </div>
          </div>
        </div>
      </ImageListItem>
    );
  };

  handleClick = (event) => {
    analytics.track("Explore page filters clicked");
    this.setState({openFilters: !this.state.openFilters});
  };

  handleClose = () => {
    if (this.props.isMobile) {
      this.setState({openFilters: false});
    }
    this.exploreRef.current.scrollTop = 0;
  };

  componentDidMount() {
    this.exploreRef.current.addEventListener('scroll', this.handleScroll);
    this.setState({prevTriggerHeight: this.exploreRef.current.scrollTop});
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.isMobile !== prevProps.isMobile) {
      this.setState({openFilters: !this.props.isMobile});
    }
  }

  // load more books (scroll)
  handleScroll = () => {
    let triggerHeight = this.exploreRef.current.scrollTop + this.exploreRef.current.offsetHeight;
    if (triggerHeight >= this.exploreRef.current.scrollHeight && triggerHeight >= this.state.prevTriggerHeight) {
      console.log(this.state.prevTriggerHeight, triggerHeight);
      // trigger fetching of next page
      const key = this.state.selectedFilterKey;
      const prodType = key ? this.state.typeDict[key].type : null; 
      const lastVisibleIdx = this.state.products.length
        ? this.state.products[this.state.products.length - 1].Title : null;
      this.setState({fetchingNewPage: true});

      analytics.track("Explore page scrolled past page limit", {
        'productType': prodType,
        'pageNum': this.state.pageNum,
      });
      cloudFunctionsFirebase.getCuratedItems(prodType, lastVisibleIdx, this.pageLimit, this.state.pageNum, false,
        (itemsList, pageNum) => {
          if (pageNum !== this.state.pageNum) {
            this.setState({fetchingNewPage: false});
            return;
          }
          const newProducts = this.state.products.concat(itemsList);
          this.setState({products: newProducts, fetchingNewPage: false, pageNum: pageNum + 1});
        });
    }
    this.setState({prevTriggerHeight: triggerHeight});
  }

  getItemImageListCols = () => {
    if (this.props.isMobile) {
      return 1;
    }

    if (isWidthUp('xl', this.props.width)) {
      return 3;
    }

    if (isWidthUp('lg', this.props.width)) {
      return 3;
    }

    if (isWidthUp('md', this.props.width)) {
      return 2;
    }

    if (isWidthUp('sm', this.props.width)) {
      return 1;
    }

    return 1;
  }

  render() {
    const { classes } = this.props;
    const { products } = this.state;

    let productDoms = [];
    if ( products ) {
      productDoms = products.map(this.addExploreItem);
    }

    const openFilters = this.state.openFilters;

    const listItems = Object.keys(this.state.typeDict).map((key, index) => {
      return ( <>
        <ListItem key={index} button onClick={() => {
          const itemType = this.state.typeDict[key].type;
          analytics.track("Explore page filter selected", {
            'productType': itemType,
          });
          this.setState({pageNum: 0, loadingProducts: true});
          cloudFunctionsFirebase.getCuratedItems(itemType, null, this.pageLimit, 0, false, (itemsList, pageNum) => {
            this.setState({products: itemsList, pageNum: pageNum + 1, loadingProducts: false});
            this.exploreRef.current.scrollTop = 0;
          });
          
          const newTypeDict = {...this.state.typeDict}; 
          newTypeDict[key].selected = true;
          if (this.state.selectedFilterKey) {
            delete newTypeDict[this.state.selectedFilterKey]['selected'];
          }
          this.setState({selectedFilterKey: key, typeDict: newTypeDict});

          if (this.props.isMobile) {
            this.setState({openFilters: false});
          }
        }}>
          <ListItemText style={{whiteSpace: 'nowrap'}} primary={this.state.typeDict[key].name} />
          {
            this.state.typeDict[key].selected ?
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="comments" onClick={(e) =>{
                analytics.track("Explore page filter cancelled", {
                  'productType': this.state.selectedFilterKey,
                });
                this.setState({loadingProducts: true});
                cloudFunctionsFirebase.getCuratedItems(null, null, this.pageLimit, 0, false, (itemsList, pageNum) => {
                  this.setState({products: itemsList, pageNum: pageNum + 1, loadingProducts: false});
                });
                const newTypeDict = {...this.state.typeDict}; 
                if (this.state.selectedFilterKey) {
                  delete newTypeDict[this.state.selectedFilterKey]['selected'];
                }
                this.setState({
                  loadingProducts: true,
                  selectedFilterKey: null,
                  typeDict: newTypeDict,
                  prevTriggerHeight: this.exploreRef.current.scrollTop
                });
                this.handleClose();
              }}>
                <Icon>clear</Icon>
              </IconButton>
            </ListItemSecondaryAction> : null
          }
        </ListItem>
        <Divider key={index + "_divider"}/>
      </> );
    });

    const exploreDivWidth = this.props.isMobile ? window.screen.width * 0.9 : window.screen.width * 0.9 - 350;
    const itemGridCol = this.getItemImageListCols();
    const itemCellWidth = this.props.isMobile ? exploreDivWidth : exploreDivWidth / 3;
    const itemGridWidth = itemGridCol * itemCellWidth;
    return (
      <ThemeProvider theme={theme}>
        <div id="container" style={{height: "100vh"}}>
          <Navbar/>
          <div id="explorer" ref={this.exploreRef} 
            style={{width: '100%', height: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'center', overflowY: 'scroll'}}>
            <Drawer
              style={{width: this.props.isMobile ? 0 : 350}}
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}>
              <Toolbar disableGutters={true}/>
              <List className={clsx(classes.drawer, {
                [classes.drawerOpen]: openFilters,
                [classes.drawerClose]: !openFilters,
              })}>
                <ListItem key={"category_label"} button={false}>
                  <ListItemText style={{whiteSpace: 'nowrap'}} primary={<b>Categories</b>} />
                  { this.props.isMobile ?
                    <IconButton onClick={(e) => {
                      this.handleClose(); 
                    }}>
                      <Icon>arrow_back_ios</Icon>
                    </IconButton> : null }
                </ListItem>
                <Divider key={"category_label_divider"}/>
                {listItems}
              </List>
            </Drawer>
            <div style={{width: 'auto', height: "100%"}}>
              { this.props.isMobile
                ? <div style={{padding: 10}}>
                    <Button variant="contained"
                      style={{color: "white", backgroundColor: "black", borderRadius: "10px"}}
                      onClick={this.handleClick}
                      startIcon={
                        <Icon>
                          <img alt="filter-logo" src="/img/icons/filters.svg"/>
                        </Icon>
                      }>
                      Filters
                    </Button>
                  </div>
                : null
              }
              <div id="explore-grid-div" style={{width: itemGridWidth, height: "100%"}}>
                { !this.state.loadingProducts
                  ? <ImageList className={classes.grid} rowHeight={350} cols={itemGridCol} gap={10}
                      style={{margin: 0, paddingLeft: 5, paddingRight: 5}}>
                      {productDoms}
                      { this.state.fetchingNewPage 
                        ? <ImageListItem key="loading_tile" cols={3}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%"}}>
                              <CircularProgress disableShrink />
                            </div>
                          </ImageListItem>
                        : null
                      }
                    </ImageList>
                  : <div style={{
                      width: "100%", height: "100%", display: 'flex',
                      justifyContent: 'center', alignItems: 'center',
                      borderRadius: 10, backgroundColor: "white", flexDirection: 'column',
                    }}>
                      <h2>Finding the latest products...</h2>
                      <CircularProgress disableShrink />
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackBarOpen}
          onClose={this.handleSnackBarClose}
          autoHideDuration={3000}
          ContentProps={{
            classes: {
              root: classes.snackBar
            }
          }}
          message= {this.snackBarText}
        />
      </ThemeProvider>
    );
  }
}

export default withStyles(useStyles)(withMediaQuery()(Explorer));