import { Avatar, Typography, Button, Dialog, CircularProgress, Zoom, Icon, ImageListItem, ImageList } from "@material-ui/core";
import { green } from '@material-ui/core/colors';
import React from "react";
import MediaQuery from "react-responsive";
import { Redirect } from "react-router-dom";
import { createTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Navbar from '../layout/Navbar.js';
import analytics from '../analytics/Analytics';
import cloudFunctionsFirebase from "../CloudFunctionsFirebase.jsx";
import firebase from 'firebase/app';
import Swal from 'sweetalert2';
import path from 'path';
import psl from 'psl';

const db = firebase.firestore();
// if (window.location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }


const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'serif'
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      }
    },
    MuiImageList: {
      root: {
        display: 'flex',
        justifyContent: "center"
      }
    },
  }
});

const useStyles = theme => ({
  root: {
    display: "flex",
    width: "100%",
    height: "80vh",
    flexDirection: "column",
    justifyContent: "flex-start",
    margin: 70,
    alignItems: "center",
  },
  sharePrevDiv: {
    display: "flex",
    width: "90%",
    maxWidth: "650px",
    flexDirection: "column",
    justifyContent: "center",
  },
  sharePrevDivMobile: {
    display: "flex",
    width: "90%",
    flexDirection: "column",
    justifyContent: "center",
  },
  sharePrevHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  avatarDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: 10,
    margin: "10px 20px 10px 0px",
    borderRadius: 20,
    backgroundColor: "#F4F4F4",
    width: "fit-content",
  },
  itemTile: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    padding: '0px',
    borderRadius: '5px',
    backgroundColor: "white",
    justifyContent: 'space-between',
  },
  editButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "baseline"
  },
  profileInfoDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 10,
  },
  overlay: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sharePreviewUsername: {
    fontSize: '12px',
    color: '#000',
    fontWeight: 'bold'
  },
  sharePreviewUserBio: {
    fontSize: '12px',
    color: '#757575'
  },
  dabbleCTAButton: {
    width: "155px",
    height: "40px",
    color: "#767676",
    borderRadius: "10px",
    border: "1px solid #888888",
    backgroundColor: "#FFFFFF",
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: "#DDDDDD",
      color: "#000000",
      border: "1px solid #000000",
    }
  }
});


const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    borderRadius: "10px",
    boxShadow: "none",
    backgroundColor: "#9250FF",
    '&:hover': {
      backgroundColor: "#000000",
    }
  },
}))(Button);


class SharePreview extends React.Component {
  state = {overlayOpen: true, saving: false};

  constructor(props) {
    super(props);
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged);

    if (this.props.justCopy) {
      this.setState({overlayOpen: true});
      return;
    }

    db.collection("users/").doc(this.props.userId).get().then(async (doc) => {
      const userData = doc.data();
      if (!userData) {
        console.error("No user data available? Waiting for it to come in");
        return;
      }

      const { collectionId } = this.props;
      const bookmarks = userData.bookmarks;
      if ( !bookmarks || !(collectionId in bookmarks.collections) ) {
        console.log("collection is not in bookmark", collectionId);
      }

      if (userData.profilePic) {
        const profilePicUrl = await cloudFunctionsFirebase.getImageSignedUrl(userData.profilePic);
        userData.profilePicUrl = profilePicUrl.url;
      }

      let bookmarkId = bookmarks.moodboards[collectionId];
      console.log("bookmarkId", bookmarkId)
      if (!bookmarkId) {
        const colRef = db.collection("collections").doc(collectionId);
        const colDoc = await colRef.get();
        const colData = colDoc.data();
        if (colData && colData.BookmarkId) {
          bookmarkId = colData.BookmarkId + ".jpg";
        }
      }

      let colImgUrl = {url: null};
      if (bookmarkId) {
        if (bookmarkId.includes('jpg')) {
          colImgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`bookmarks/${bookmarkId}`);
        }
        else {
          colImgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`bookmarks/${bookmarkId}.png`);
        }
      }

      this.setState({
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        userName: userData.userName,
        description: userData.description,
        profilePicUrl: userData.profilePicUrl,
        collectionImgUrl: colImgUrl.url,
        overlayOpen: false,
      });
    });
  }

  // handle authentication state changed
  onAuthStateChanged = (user) => {
    if (user) {
      console.log("logged in");
      this.setState({user});
      db.collection("users/").doc(user.uid).onSnapshot(async (doc) => {
        const userData = doc.data();
        if (!userData) {
          return;
        }

        if (this.props.justCopy && !this.state.saving && !this.state.duplicated) {
          this.onSaveBoardClick(null);
        }
      });
    }
    else if (this.props.justCopy) {
      console.log("logged out");
      this.setState({redirectToLogin: true});
    }
  };



  onSaveBoardClick = (e) => {
    if (this.state.user) {
      // copy the collection
      this.setState({overlayOpen: true, saving: true});

      const origColId = this.props.collectionId
        ? this.props.collectionId
        : this.props.match.params.colId;
      const origUserId = this.props.userId
        ? this.props.userId
        : this.props.match.params.userId;
      cloudFunctionsFirebase.duplicateCollectionFirestore(origColId, (result) => {
        if (result) {
          // Analytics Event: new collection created
          analytics.track('Collection duplicated via sharing', {
            "from user id": origUserId,
            "to user id": this.state.user.uid,
            "original collection id": origColId,
            "new collection id": result.newCollectionId
          });

          // redirect
          this.setState({duplicated: true});
          setTimeout(() => {
            window.location.replace("/design/" + result.newCollectionId);
          }, 1500);
        } else {
          Swal.fire('Failed to duplicate new collection', '', 'error');
          this.setState({overlayOpen: false, saving: false});
        }
      });
    }
    else {
      this.setState({redirectToLogin: true});
    }
  }


  render() {
    const { classes } = this.props;
    if (this.state.redirectToLogin) {
      const origColId = this.props.collectionId
        ? this.props.collectionId
        : this.props.match.params.colId;
      analytics.track('Redirecting to Login from SharePreview', {
        "original collection id": origColId,
        "from /copy": this.props.justCopy,
      });
      return (
        <Redirect
          to={{
            pathname: "/Login",
            state: { loginRedirectPath: path.join("/design", origColId)},
          }}
        />
      );
    }

    const dialogDom = (
      ! this.state.duplicated
      ? <Zoom in={this.state.saving && !this.state.duplicated}>
          <div className={classes.overlay}>
            <h2>Saving board to your profile...</h2>
            <CircularProgress color="primary" />
          </div>
        </Zoom>
      : <Zoom in={this.state.duplicated} style={{transitionDelay: this.state.duplicated ? '500ms' : '0ms' }}>
          <div className={classes.overlay}>
            <h2>Saved! Have fun!</h2>
            <Icon style={{color: green[500], fontSize: 40}}>check_circle</Icon>
          </div>
        </Zoom>
    );

    if (this.props.justCopy) {
      return (
        <ThemeProvider theme={theme}>
          <Dialog fullScreen open={this.state.overlayOpen}>
            {dialogDom}
          </Dialog>
        </ThemeProvider>
      );
    }

    let avatar = <Avatar src={this.state.profilePicUrl}/>;
    let userTitleString = "";
    if (this.state.userName) {
      userTitleString = "@" + this.state.userName;
    } else if (this.state.firstName) {
      userTitleString = this.state.firstName;
    }

    let colItemDoms = null;
    if (this.props.collection) {
      const items = [];
      this.props.collection.items.forEach(item => {
        if (item.Id in this.props.structElems) {
          return;
        } 
        items.push(item);
      });

      if (this.props.wallPaper && this.props.wallPaper.item) {
        items.push(this.props.wallPaper.item);
      }

      colItemDoms = items.map((item) => {
        const protoMatch = /^(https?|ftp):\/\//;
        const cleanDomain = item.URL ? item.URL.replace(protoMatch, '').split('/')[0].split('?')[0] : "";
        const linkURLString = psl.parse(cleanDomain).domain;

        console.log("item url = " + linkURLString);

        let titleStr;
        if (item.Title && item.Title.length > 40) {
          titleStr = item.Title.substring(1, 40) + "...";
        }
        else {
          titleStr = item.Title;
        }

        return (
          <ImageListItem key={item.uuid}
            style={{borderRadius: 10, border: '1px solid #DDDDDD', margin: '5px', width: '80%'}}
          >
            <div className={classes.itemTile}>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: "40%", height: "100%"}}>
                <img src={item.imgUrl} loading="lazy" alt="item img" style={{objectFit: "contain", maxHeight: "90%", maxWidth: "80%"}}/>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: "40%", alignItems: 'start'}}>
                <Typography gutterBottom variant="caption">{item.Title? titleStr : " "}</Typography>
                <Typography gutterBottom variant="caption"><b>{item.Price? "$" + item.Price : " "}</b></Typography>
                <Button
                  target="_blank"
                  aria-label="link to shop"
                  variant="text"
                  style={{padding: 0, fontWeight: 'fontWeightRegular', fontSize: '12px', color: "#AAAAAA"}}
                  href={item.URL}>
                    {linkURLString}
                    <Icon style={{fontSize: 14}}>call_made</Icon>
                </Button>
              </div>
            </div>
          </ImageListItem>);
      });
    }

    const sharePrevDivDom = (
      <>
        <div className={classes.sharePrevHeader}>
          <div className={classes.avatarDiv}>
            {avatar}
            <div className={classes.profileInfoDiv}>
              <Typography className={classes.sharePreviewUsername}>{userTitleString}</Typography>
            </div>
          </div>
          <div className={classes.editButton}>
            <ColorButton
              label="editInDabble"
              variant="contained"
              style={{width: "150px", height: "40px"}}
              color="primary"
              onClick={()=> {
                analytics.track("Edit in Dabble button clicked")
                this.onSaveBoardClick(null)
              }}>
              Copy this Design
            </ColorButton>
          </div>
        </div>
        {
          this.state.collectionImgUrl
            ? <img alt="col_img" src={this.state.collectionImgUrl} loading="lazy"
                style={{borderRadius: 10, width: '100%', marginBottom: '30px'}}/>
            : <div style={{
                width: "100%", height: 670, display: 'flex',
                justifyContent: 'center', alignItems: 'center',
                borderRadius: 10, backgroundColor: "#F4F4F4", flexDirection: 'column',
              }}>
              <Typography variant="subtitle1">Oops! Design preview not found...</Typography>
              <Typography variant="subtitle1">Click Edit button to access the design!</Typography>
            </div>
        }

        <div style={{textAlign: "center", fontWeight: "700"}}>Product List</div>
        <div style={{display: 'flex', justifyContent: "center"}}>
          <ImageList rowHeight={120} cols={1} gap={10} style={{margin: "10px 5px 10px 5px"}}>
            {colItemDoms}
          </ImageList>
        </div>

        <div style={{position: "fixed", bottom: "20px", right: "20px"}}>
          <a className = {classes.dabbleCTAButton} href='/'>
            <img src="https://app.dabble.so/img/dabble_logo.svg" alt="desktop_logo" style={{width: "30px", paddingLeft: "5px"}}/>
            <div style={{width: "120px", paddingLeft: "5px", fontSize: "0.75rem", fontWeight: "400"}}>Design your own space with Dabble</div>
          </a>
        </div>
      </>
    );
    return(
      <ThemeProvider theme={theme}>
        <Dialog fullScreen open={this.state.overlayOpen}>
          {dialogDom}
        </Dialog>
        <Navbar/>
        <div className={classes.root}>
          <MediaQuery maxDeviceWidth={480}>
            <div className={classes.sharePrevDivMobile}>
              {sharePrevDivDom}
            </div>
          </MediaQuery>
          <MediaQuery minDeviceWidth={480}>
            <div className={classes.sharePrevDiv}>
              {sharePrevDivDom}
            </div>
          </MediaQuery>
        </div>
      </ThemeProvider>
    )
  }
}

export default withStyles(useStyles)(SharePreview)
