/**
 * rootReducer.js combines the different reducer files into one redux reducer that is used by index.js
 * to add a reducer file, import the file and add it to the reducer list with a name to access it by
 * e.g. If you call it auth, then when using mapStateToProps, it will be accessed as state.auth.<reduxState>
 * **/

import dashReducer from "./dashboard/dashReducer";

import { combineReducers } from "redux";
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

const rootReducer = combineReducers({
    dash: dashReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
});

export default rootReducer;

