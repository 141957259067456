/**
 * @file cloudFunctions.jsx
 * Javascript file that interfaces Cloud Functions with the Dev Portal
 *
 * All Cloud Functions that the Dev Portal uses are listed here.
 * The Dev Portal can invoke Cloud Functions by calling the correct function here.
 * Each Cloud Function has a proper completion and error callback setup.
 * Cloud Function Ajax request are made using @file common.jsx
 */

import common from './common';

const cloudFunctions = {
  baseUrl: 'https://us-central1-mixandmatch-287616.cloudfunctions.net/',
  // baseUrl: 'http://localhost:5001/mixandmatch-287616/us-central1/',

  setUserCreationDataUrl: () => `${cloudFunctions.baseUrl}setUserCreationData`,
  setUserStylesUrl: () => `${cloudFunctions.baseUrl}setUserStyles`,
  getUserStylesUrl: () => `${cloudFunctions.baseUrl}getUserStyles`,
  bookmarkCollectionUrl: () => `${cloudFunctions.baseUrl}bookmarkCollection`,
  bookmarkItemUrl: () => `${cloudFunctions.baseUrl}bookmarkItem`,
  removeBookmarkCollectionUrl: () => `${cloudFunctions.baseUrl}removeBookmarkCollection`,
  removeBookmarkItemUrl: () => `${cloudFunctions.baseUrl}removeBookmarkItem`,
  createNewCollectionUrl: () => `${cloudFunctions.baseUrl}createNewCollection`,
  getBookmarksUrl: () => `${cloudFunctions.baseUrl}getBookmarks`,
  getCollectionEcommerceLinksUrl: () => `${cloudFunctions.baseUrl}getCollectionEcommerceLinks`,
  getEcommerceImageUrl: () => `${cloudFunctions.baseUrl}getEcommerceImage`,
  getCollectionImageUrlUrl: () => `${cloudFunctions.baseUrl}getCollectionImageUrl`,
  recommendCollectionUrl: () => `${cloudFunctions.baseUrl}recommendCollection`,
  getAlternativeItemsUrl: () => `${cloudFunctions.baseUrl}getAlternativeItems`,

  setUserCreationData: (creationData, userCreationCallback) => {
    common.ajaxPostJson(cloudFunctions.setUserCreationDataUrl(), { creationData }).done((data) => {
      console.log("successfully set user data");
      userCreationCallback(data.result);
    }).fail((err) => {
      console.log(err);
      userCreationCallback(false);
    });
  },
  setUserStyles: (stylesData, setStylesCallback) => {
    common.ajaxPostJson(cloudFunctions.setUserStylesUrl(), { stylesData }).done((data) => {
      console.log("successfully set user styles");
      setStylesCallback(data.result);
    }).fail((err) => {
      console.log(err);
      setStylesCallback(false);
    });
  },
  getUserStyles: (getStylesCallback) => {
    common.ajaxPostJson(cloudFunctions.getUserStylesUrl(), {}).done((data) => {
      console.log("successfully get user styles");
      getStylesCallback(data.styles);
    }).fail((err) => {
      console.log(err);
      getStylesCallback(false);
    });
  },
  bookmarkCollection: (collectionId, wallColor, floorColor, bookmarkCollectionCallback) => {
    common.ajaxPostJson(cloudFunctions.bookmarkCollectionUrl(), { collectionId, wallColor, floorColor }).done((data) => {
      console.log("successfully bookmarked collection");
      bookmarkCollectionCallback(data.result);
    }).fail((err) => {
      console.log(err);
      bookmarkCollectionCallback(false);
    });
  },
  bookmarkItem: (itemId, bookmarkItemCallback) => {
    common.ajaxPostJson(cloudFunctions.bookmarkItemUrl(), { itemId }).done((data) => {
      console.log("successfully bookmarked item");
      bookmarkItemCallback(data.result);
    }).fail((err) => {
      console.log(err);
      bookmarkItemCallback(false);
    });
  },
  removeBookmarkCollection: (collectionId, removeBookmarkCollectionCallback) => {
    common.ajaxPostJson(cloudFunctions.removeBookmarkCollectionUrl(), { collectionId }).done((data) => {
      console.log("successfully removed collection bookmark");
      removeBookmarkCollectionCallback(data.result);
    }).fail((err) => {
      console.log(err);
      removeBookmarkCollectionCallback(false);
    });
  },
  removeBookmarkItem: (itemId, removeBookmarkItemCallback) => {
    common.ajaxPostJson(cloudFunctions.removeBookmarkItemUrl(), { itemId }).done((data) => {
      console.log("successfully removed bookmarked item");
      removeBookmarkItemCallback(data.result);
    }).fail((err) => {
      console.log(err);
      removeBookmarkItemCallback(false);
    });
  },
  createNewCollection: (collection, wallColor, floorColor,  createNewCollectionCallback) => {
    const inputDict = { collection, wallColor, floorColor };
    common.ajaxPostJson(cloudFunctions.createNewCollectionUrl(), inputDict).done((data) => {
      console.log("successfully created new collection: ", data.newCollectionId);
      createNewCollectionCallback(data);
    }).fail((err) => {
      console.log(err);
      createNewCollectionCallback(false);
      });
  },
  getBookmarks: (getBookmarksCallback) => {
    common.ajaxPostJson(cloudFunctions.getBookmarksUrl(), {}).done((data) => {
      console.log("successfully get user bookmarks");
      getBookmarksCallback(data.bookmarks);
    }).fail((err) => {
      console.log(err);
      getBookmarksCallback(false);
    });
  },
  getCollectionEcommerceLinks: (collectionId, collectionEcommerceLinksCallback) => {
    common.ajaxPostJson(cloudFunctions.getCollectionEcommerceLinksUrl(), { collectionId }).done((data) => {
      collectionEcommerceLinksCallback(data.result);
    }).fail((err) => {
      console.log(err);
      collectionEcommerceLinksCallback(false);
    });
  },
  getEcommerceImage: (getEcommerceImageUrlCallback) => {
    common.ajaxPostJson(cloudFunctions.getEcommerceImageUrl() ).done((data) => {
      getEcommerceImageUrlCallback(data);
    }).fail((err) => {
      console.log(err);
      getEcommerceImageUrlCallback(false);
    });
  },
  getCollectionImageUrl: (collectionId, getCollectionImageUrlCallback) => {
    common.ajaxPostJson(cloudFunctions.getCollectionImageUrlUrl(), { collectionId }).done((data) => {
      getCollectionImageUrlCallback(data.url);
    }).fail((err) => {
      console.log(err);
      getCollectionImageUrlCallback(false);
    });
  },
  recommendCollection: (recommendCollectionCallback) => {
    common.ajaxPostJson(cloudFunctions.recommendCollectionUrl()).done((data) => {
      console.log(data);
      recommendCollectionCallback(data.result);
    }).fail((err) => {
      console.log(err);
      recommendCollectionCallback(false);
    })
  },
  getAlternativeItems: (itemId, getAlternativeItemsCallback) => {
    common.ajaxPostJson(cloudFunctions.getAlternativeItemsUrl(), { itemId }).done((data) => {
      getAlternativeItemsCallback(data.result);
    }).fail((err) => {
      console.log(err);
      getAlternativeItemsCallback(false);
    })
  }
};
export default cloudFunctions;
window.vcmsCf = cloudFunctions;
