import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { reduxFirestore, getFirestore, createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase';
import './index.sass';
import * as serviceWorker from './serviceWorker';
import rootReducer from "./rootReducer";
import firebaseConfig from './firebase/firebaseConfig';
import firebase from 'firebase/app';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import Favourites from "./dashboard/Favourites.js";
import UserProfile from "./dashboard/UserProfile.js";
import SharePreview from "./dashboard/SharePreview.js";
import Explorer from "./dashboard/Explorer.js";
import UserPage from "./dashboard/UserPage.js";
import analytics from "./analytics/Analytics";
import Login from "./auth/Login.js";
import ImageCleanupApp from './labs/ImageCleanupApp';

//const db = firebase.firestore();
// if (window.location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }


// magic redux, thunk setup with firebase from youtube
const store = createStore(rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(firebase, firebaseConfig),
  )
);

const rrfProps = {
  firebase,
  config: firebaseConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

function onAuthStateChanged(user) {
  if (user) {
    // Identify user for Analytics and send login event
    analytics.identify(String(user.uid));
    analytics.register_once({"source": "webapp"});
    
    user.getIdToken().then((idToken) => {
      window.sessionStorage.token = idToken;
    });
  }
}

function onIdTokenChanged(user) {
  if (user) {
    user.getIdToken().then((idToken) => {
      window.sessionStorage.token = idToken;
    });
  } else {
    delete window.sessionStorage.token;
  }
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <BrowserRouter>
        <div className="App" id="App">
            <Switch>
              <Route exact path='/Login' component={Login} />
              <Route exact path='/Profile' component={UserProfile} />
              <Route exact path='/Explore' component={Explorer} />
              <Route path="/design/:colId" component={App} />
              <Route path="/user/:userId" component={UserPage} />
              <Route path="/roomcleaner" component={ImageCleanupApp} />
              <Route path="/copy/:userId/:colId" render={(props) => (
                <SharePreview {...props} justCopy={true} />
              )}/>
              <Route path="/Wishlist" render={(props) => (
                <Favourites {...props} wishlist={true} />
              )}/>
              <Route path="/" component={Favourites} />
            </Switch>
        </div>
      </BrowserRouter>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
firebase.auth().onAuthStateChanged(onAuthStateChanged);
firebase.auth().onIdTokenChanged(onIdTokenChanged);

