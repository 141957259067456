import React from 'react';
import { Rect } from 'react-konva';

class WallPaperRect extends React.Component {
  state = {image: null}

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }

  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad);
  }

  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.crossOrigin = "Anonymous";
    this.image.addEventListener('load', this.handleLoad);
  }

  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image
    });
  };

  render() {
    const {width, height, x, y, scale, onClick, onRef} = this.props;

    const actualScale = this.state.image ? scale * 0.6 * height / 2 / this.state.image.height : 1;
    return (
      <Rect
        ref={node => {
          if (onRef) {
            onRef(node);
          }
        }}
        x={x} y={y}
        onClick={(e) => onClick(e)}
        width={width}
        height={height}
        fillPatternScaleX={actualScale}
        fillPatternScaleY={actualScale}
        fillPatternImage={this.state.image}
      />
    );
  }
}

export default WallPaperRect;
