/* global gapi */
import React from "react";
import MediaQuery from 'react-responsive';
import { Toolbar, AppBar } from '@material-ui/core';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import '../index.sass';
import { Button, Icon, Avatar, Fade, Menu, MenuItem, IconButton } from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import firebase from "../firebase/firebaseConfig";
import analytics from "../analytics/Analytics";
import { withRouter } from 'react-router-dom';


const db = firebase.firestore();
// if (window.location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }


const useStyles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
    border: '1px solid #DDDDDD'
  },
  toolbar: {
    paddingLeft: '0px',
    paddingRight: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  navButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  button: {
    color: 'black',
    textTransform: 'none',
    marginLeft: '10px',
    marginRight: '0px',
  },
  iconButtonSelected: {
    color: 'white',
    backgroundColor: 'black',
    marginLeft: '10px',
    marginRight: '0px',
    borderRadius: '10px',
    padding: '5px',
  },
  iconButton: {
    marginLeft: '10px',
    marginRight: '0px',
    borderRadius: '10px',
    padding: '5px',
  },
  containedButton: {
    color: 'white',
    textTransform: 'none',
    backgroundColor: 'black',
    boxShadow: 'none',
    borderRadius: 10,
    marginLeft: '10px',
    marginRight: '0px',
  },
  smallAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginLeft: "10px",
    fontSize: 12,
    color: 'black',
    backgroundColor: 'pink',
    cursor: 'pointer',
    border: '1px solid #888888'
  },
  toolbarMobile: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: '1'
  },
  linkItem: {
    fontSize: 14,
    color: "#741ce5",
    paddingRight: "10px",
    paddingLeft: "10px",
    '&:hover': {
      color: "#000",
      backgroundColor: "#fff",
    },
    textTransform: 'none',
  },
  navBar: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white"
  },
  shareButton: {
    backgroundColor: '#741CE5',
    color: "#FFF",
    borderRadius: '5px',
    height: '35px',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginLeft: '10px',
    fontSize: '15px',
    '&:hover': {
      bottom: "1px",
      right: "1px",
      background: '#6616CC',
      boxShadow: '3px 3px 0px 0px #F9B1AA',
    },
    '&:active': {
      bottom: "0px",
      right: "0px",
      boxShadow: 'none',
      backgroundColor: '#741CE5'
    }
  },
  newDesignButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    height: '35px',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginLeft: '10px',
    fontSize: '15px',
    '&:hover': {
      bottom: "1px",
      right: "1px",
      backgroundColor: '#E7E7E7',
      boxShadow: '3px 3px 0px 0px #F9B1AA',
    },
  }
});

class Navbar extends React.Component {
  state = {anchorEl: null};

  constructor(props) {
    super(props);
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
  }

  // handle authentication state changed
  onAuthStateChanged = (user) => {
    if (user) {
      db.collection("users/").doc(user.uid).onSnapshot(async (doc) => {
        const userData = doc.data();
        if (!userData) {
          return;
        }

        if (userData.profilePic) {
          userData.profilePicUrl = "https://us-central1-mixandmatch-287616.cloudfunctions.net/app/profilepic/" + user.uid;
        }

        this.setState({
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          userName: userData.userName,
          description: userData.description,
          profilePicUrl: userData.profilePicUrl,
        });
      });
    }
    else {
      console.log("logged out");
    }
  };


  //Logout user and redirect to Login page
  onLogoutClick = () => {
    this.handleClose();
    let auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut();
    return firebase.auth().signOut().then(() => {
      this.props.history("/Login");
    }).catch((err) => {
      console.log("logout failed", err)
    });
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  render() {
    const { auth, classes } = this.props;

    const avatar = <Avatar className={classes.smallAvatar}
      src={this.state.profilePicUrl} onClick={(e) => {
      this.setState({anchorEl: e.currentTarget});
    }}/>;

    const atExplore = window.location.pathname.includes("Explore");
    const atWishlist = window.location.pathname === '/Wishlist';
    const atMyDesigns = window.location.pathname === '/';
    const wishlistButtonVariant = atWishlist ? "contained" : "text";
    const myDesignsButtonVariant = atMyDesigns ? "contained" : "text";
    const exploreButtonClass = atExplore ? classes.iconButtonSelected : classes.iconButton;
    const wishlistButtonClass = atWishlist ? classes.iconButtonSelected : classes.iconButton;
    const myDesignsButtonClass = atMyDesigns ? classes.iconButtonSelected : classes.iconButton;
    const showHomeButton = !auth.isEmpty && (window.location.pathname.includes("design") ||
      window.location.pathname.includes("Profile"));
    const showTutorialButton = false;
    const showLabsExperiment = false;
    const toolbarContent = (
      <>
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
          <MediaQuery minDeviceWidth={480}>
            <Button variant="text" href='/' className={classes.button} onClick={()=> {
              analytics.track("Home button clicked")
            }}
              startIcon={showHomeButton? <Icon style={{fontSize: "14px"}}>arrow_back_ios</Icon> : null}>
              {
                showHomeButton
                ? "Home"
                : <img src="/img/dabble_logo.svg" alt="desktop_logo" style={{width: "30px"}}/>
              }
            </Button>
            {/*
            <Button variant={exploreButtonVariant} href='/Explore'
              id="explore-button" className={classes.button}
              classes={{
                contained: classes.containedButton,
              }}
              onClick={()=> {
                analytics.track("Explore button clicked");
              }}
              startIcon={<Icon>search</Icon>}>
              Explore
            </Button>
            */}
            <Button variant={wishlistButtonVariant} href='/Wishlist'
              className={classes.button}
              classes={{
                contained: classes.containedButton,
              }}
              onClick={()=> {
                analytics.track("Wishlist button clicked");
              }}
              startIcon={<Icon>favorite_border</Icon>}>
              Saved Items
            </Button>
            <Button variant={myDesignsButtonVariant} href='/'
              className={classes.button}
              classes={{
                contained: classes.containedButton,
              }}
              onClick={()=> {
                analytics.track("My Designs button clicked");
              }}
              startIcon={<Icon>
                <img style={{width: "100%", filter: window.location.pathname === '/' ? "invert(1)" : "unset"}}
                  alt="designs-logo" src="/img/icons/designs.svg"/>
              </Icon>}>
              Designs
            </Button>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={480}>
            <IconButton href='/' onClick={()=> {
              analytics.track("Home button clicked")
            }}>
              <Icon>
                <img src="/img/dabble_logo.svg" alt="desktop_logo"/>
              </Icon>
            </IconButton>
            <IconButton href='/Explore' className={exploreButtonClass} id="explore-button" onClick={()=> {
              analytics.track("Explore button clicked");
            }}>
              <Icon>search</Icon>
            </IconButton>
            <IconButton href='/Wishlist' className={wishlistButtonClass} onClick={()=> {
              analytics.track("Wishlist button clicked");
            }}>
              <Icon>favorite_border</Icon>
            </IconButton>
            <IconButton href='/'  className={myDesignsButtonClass} onClick={()=> {
              analytics.track("My Designs button clicked");
            }}>
              <Icon style={{display: "flex", justifyContent: "center"}}>
                <img style={{width: "87%", filter: window.location.pathname === '/' ? "invert(1)" : "contrast(0.3)"}}
                  src="/img/icons/designs.svg" alt="designs-logo"/>
              </Icon>
            </IconButton>
          </MediaQuery>
        </div>
        <div className={classes.navButtons}>
          <div style={{display: 'flex', color: 'gray', width: 100, justifyContent: 'flex-end', marginRight: 10}}>
            { this.props.saveStatusMessage }
          </div>
          <div>
            { showTutorialButton
              ? <>
                  <Button variant='text' className={classes.linkItem} onClick={(e) => {
                    window.localStorage.needOnboarding = true;
                    if (this.props.onTutorialClicked) {
                      this.props.onTutorialClicked();
                    }
                  }}>
                    Tutorial
                  </Button>
                  <MediaQuery minDeviceWidth={480}>
                    <a className={classes.linkItem} target="_blank" rel="noopener noreferrer"
                      href="https://chrome.google.com/webstore/detail/dabble/lnlgccgpbhgpnlgjignoencljjpoleca">
                      Web Clipper
                    </a>
                  </MediaQuery>
                </>
              : null }
              { showLabsExperiment
              ? <>
                    <a className={classes.linkItem} target="_blank" rel="noopener noreferrer"
                      href="/roomcleaner">
                      <b>Room Cleaner Beta</b>
                    </a>
                </>
              : null }
            { this.props.onAddDesignClicked &&
              <Button className={classes.newDesignButton}
                id="add_design_button"
                onClick={()=> {
                  if (this.props.onAddDesignClicked) {
                    this.props.onAddDesignClicked();
                  }
                }}
                startIcon={<Icon>add</Icon>}>
                New Design
              </Button>
            }
            { this.props.onShareButtonClicked &&
              <Button className={classes.shareButton}
                id="share_button"
                onClick={()=> {
                  if (this.props.onShareButtonClicked) {
                    this.props.onShareButtonClicked();
                  }
                }}
                startIcon={<Icon>send</Icon>}>
                Share
              </Button>
            }
          </div>
          { auth.isEmpty ?
            null :
            avatar }
          <Menu
            id="share-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
            TransitionComponent={Fade}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem key={"profile"} selected={false} onClick={() => {
              this.props.history.push("/Profile");
            }}>
              Profile
            </MenuItem>
            <MenuItem key={"install-chrome-ext"} selected={false} onClick={() => {
              window.open("https://chrome.google.com/webstore/detail/dabble/lnlgccgpbhgpnlgjignoencljjpoleca", '_blank');
            }}>
              Get Chrome Extension
            </MenuItem>
            <MenuItem key={"logout"} selected={false} onClick={this.onLogoutClick}>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </>
    );

    return (
      <div id='navbar' className={classes.navBar}>
        <MediaQuery minDeviceWidth={480}>
          <AppBar elevation={0} position="static" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              {toolbarContent}
            </Toolbar>
          </AppBar>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={480}>
          <AppBar elevation={0} position="static" className={classes.appBar}>
            <Toolbar className={classes.toolbarMobile}>
              {toolbarContent}
            </Toolbar>
          </AppBar>
        </MediaQuery>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
};


export default withRouter(compose(connect(mapStateToProps), withStyles(useStyles))(Navbar));
