import React, {Component} from "react";
import {TextField, Button, IconButton, Icon, CircularProgress, Typography } from "@material-ui/core";
import { withStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import analytics from '../analytics/Analytics';
import cloudFunctionsFirebase from "../CloudFunctionsFirebase.jsx";
import Swal from 'sweetalert2';
import Dropzone from 'react-dropzone'
import ReactCrop from 'react-image-crop';
import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import '../index.sass';
import 'react-image-crop/lib/ReactCrop.scss';

const storage = firebase.storage();
const firebaseStorage = storage.ref();

/**
 * props expects: item object
 * **/
const useStyles = theme => ({
  img: {
    margin: 'auto',
    display: 'block',
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  formControl: {
    minWidth: 120,
  },
  grid: {
    borderRadius: '8px',
  },
  imgContainer: {
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
    borderRadius: 10,
  },
  itemTile: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0px',
    borderRadius: '10px',
    backgroundColor: "#FFFFFF",
    border: '0px solid #DDDDDD',
    borderColor: '#BBB9B9',
    boxSizing: 'border-box',
  },
  itemInfo: {
    display:'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: "10px",
    fontSize: '10px',
    color: "#808080"
  },
  vendorInfo: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '60%'
  },
  itemTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: "#000000"
  },
  ctrlButton: {
    borderRadius: 10,
    textTransform: 'none',
    height: 40,
  },
  line: {
    borderBottom: '0px solid',
    borderColor: '#FFFFFF',
    paddingTop: '0px',
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F0F0F0",
    opacity: "0.9",
    borderRadius: "8px"
  },
  addItemButton: {
    marginRight: '5px',
    height: '35px',
    borderRadius: '5px',
    backgroundColor: '#F0F0F0',
    whiteSpace: 'nowrap',
    fontSize: '15px'
  },
  root: {
    outline: 0,
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '10px',
    paddingBottom: '10px',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: "#FFF",
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButtonBar: {
    outline: 0,
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 4, 2),
  },
  paper: {
    outline: 0,
    padding: theme.spacing(0, 4, 2),
    overflowY:'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: '2px solid slategrey'
    }
  }
});

const ColorSaveButton = withStyles((theme) => ({
  root: {
    color: "white",
    borderRadius: "10px",
    boxShadow: "none",
    backgroundColor: "#9250FF",
    '&:hover': {
      backgroundColor: "#8473EE",
    }
  },
}))(Button);

const progressTheme = createTheme({
  palette: {
    primary: {
      main: '#741CE5',
    }
  },
});

// making the default disabled textfield darker
const ProductTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.7)" // (default alpha is 0.38)
    }
  }
})(TextField);


class ProductInfoPanel extends Component {
  state = {
    updating: false,
    deleting: false,
    cropParams: {
      unit: '%',
      width: 30,
      height: 30,
    },
    editImageLoaded: false,
    productInfoUpdated: false
  };

  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
  }

  loadPastedImage(item) {
    if (item.type.indexOf("image") === 0) {
      const blob = item.getAsFile();
      const reader = new FileReader();
      reader.onload = async (event) => {
        this.pastedImgDataUrl = event.target.result;
        this.setState({pasting: false, productInfoUpdated: true, origUploaded: false});
        if (this.props.imagePasted) {
          this.props.imagePasted(this.pastedImgDataUrl);
        }
      };

      this.setState({pasting: true});
      reader.readAsDataURL(blob);
    }
  }

  onImageLoaded = image => {
    this.setState ({editImageBackground: 'F0F0F0', editImageLoaded:true})
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (cropParams, percentCrop) => {
    // You could also use percentCrop:
    this.setState({ cropParams });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImgBlob = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.png'
      );
      this.setState({ croppedImgBlob });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.height = Math.ceil(crop.height * scaleY);
    canvas.width = Math.ceil(crop.width * scaleX);
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        resolve(blob);
      }, 'image/png');
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currItem && this.props.currItem && prevProps.currItem.Id !== this.props.currItem.Id) {
      this.setState({
        productURL: undefined,
        productName: undefined,
        updating: false,
        deleting: false,
        cropParams: {
          unit: '%',
          width: 30,
          height: 30,
        },
        editImageLoaded: false,
        productInfoUpdated: false,
      });
    }
  }

  componentDidMount() {
    window.addEventListener('paste', (event) => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      if (items.length > 0) {
        Object.values(items).forEach(item => {
          this.loadPastedImage(item);
        })
      }
    });
  }

  reset() {
    this.setState({updating: false, saving: false, removingBg: false, 
      productInfoUpdated: false, productURL: undefined, price: undefined,
      productName: undefined, editedImgUrl: null});
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  render() {
    const { classes, currItem, onClose } = this.props;
    const curated = !currItem ? false
      : currItem.Curated || (currItem.CreatedBy !== this.props.owner && currItem.CreatedBy);

    let itemImage = null;
    if (!currItem && !this.state.pasting) {
      itemImage = (
        <div className={classes.imgContainer}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center",
            alignItems: "center", height: "100%", width: "100%", backgroundColor: "#EFEFEF"}}>
            <Dropzone onDrop={acceptedFiles => {
              const item = acceptedFiles[0];
              if (item.type.indexOf("image") === 0) {
                const reader = new FileReader();
                reader.onload = async (event) => {
                  this.pastedImgDataUrl = event.target.result;
                  this.setState({pasting: false, productInfoUpdated: true, origUploaded: false});
                  if (this.props.imagePasted) {
                    this.props.imagePasted(this.pastedImgDataUrl);
                  }
                };

                this.setState({pasting: true});
                reader.readAsDataURL(item);
              }
            }}>
              {({getRootProps, getInputProps}) => (
                <div style={{display: "flex", justifyContent: "center", flexDirection: "column",
                  alignItems: "center", height: "100%", width: "100%", cursor: "pointer"}} {...getRootProps()}>
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center",
                   height: "80%", width: "100%", backgroundColor: "#E2E2E2", flexDirection: "column"}}>
                    <p style={{margin: 5}}>Paste an image with Ctrl + V</p>
                    <p style={{fontSize: 12, margin: 5}}>Cmd + V on Mac</p>
                  </div>
                  <input {...getInputProps()} />
                  <p style={{display: "flex"}}>
                    <Icon style={{marginRight: 20}}>upload</Icon>
                    Upload image or drop a file
                  </p>
                </div>
              )}
            </Dropzone>
          </div>
        </div>);
    } else if (this.state.cropping) {
      if (!currItem.selImgUrl) {
        currItem.selImgUrl = currItem.imgUrl
      }

      itemImage =
        <div style={{
          display: 'flex',
          overflow: 'hidden',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 10,
        }}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center",
            alignItems: "center", height: "100%", width: "100%", backgroundColor: "#EFEFEF"}}>
            <ReactCrop
              src={currItem.selImgUrl}
              crossorigin="anonymous"
              crop={this.state.cropParams}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}/>
          </div>
        </div>
    } else if (this.state.pasting) {
      itemImage =
        <div className={classes.imgContainer}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center",
            alignItems: "center", height: "100%", width: "100%", backgroundColor: "#EFEFEF"}}>
            <ThemeProvider theme={progressTheme}>
              <CircularProgress color="primary" style={{marginTop: "20px"}}/>
            </ThemeProvider>
          </div>
        </div>
    } else if (this.state.updating) {
      if (!currItem.selImgUrl && !this.state.pasting) {
        currItem.selImgUrl = currItem.imgUrl;
      }

      let imgUrl = currItem.newItem ? currItem.selImgUrl : currItem.imgUrl;
      if (this.state.editedImgUrl) {
        imgUrl = this.state.editedImgUrl;
      }

      let displayText = "";
      if (this.state.uploadingCropped) {
        displayText = "Finalizing crop...";
      } else if (this.state.removingBg) {
        displayText = "Removing background..."
      }
      
      itemImage =
      <div className={classes.imgContainer}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center",
          alignItems: "center", height: "100%", width: "100%", backgroundColor: "#EFEFEF"}}>
          <img className={classes.img} src={imgUrl} alt={currItem.Title} loading="lazy" style={{opacity: 0.25}}/>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", position: "absolute"}}>
            <Typography variant="h6" style={{position: "-10px", padding: "10px"}}>
              {displayText}
            </Typography>
            <ThemeProvider theme={progressTheme}>
              <CircularProgress color="primary"/>
            </ThemeProvider>
          </div>
        </div>
      </div>
    } else {
      if (!currItem.selImgUrl) {
        currItem.selImgUrl = currItem.imgUrl
      }

      let imgUrl = currItem.newItem ? currItem.selImgUrl : currItem.imgUrl;
      if (this.state.editedImgUrl) {
        imgUrl = this.state.editedImgUrl;
      }

      itemImage =
        <div className={classes.imgContainer}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center",
            alignItems: "center", height: "100%", width: "100%", backgroundColor: "#EFEFEF"}}>
            <img className={classes.img} src={imgUrl} alt={currItem.Title} loading="lazy"/>
          </div>
        </div>
    }

    let buttonsComps = null;
    if (currItem) {
      if (this.state.cropping) {
        buttonsComps =
          <div style={{display: "flex", flexDirection: "row",
            justifyContent: "space-between", width: "100%", marginTop: 20}}>
            <Button variant="outlined" onClick={(e) => {
              const { croppedImgBlob } = this.state;

              // upload blob to temp folder, and add code
              const croppedImgLoc = 'images/temp/' + uuidv4() + ".png";
              const croppedImgRef = firebaseStorage.child(croppedImgLoc);
              this.setState({uploadingCropped: true, updating: true, cropping: false});
              croppedImgRef.put(croppedImgBlob).then(async (snapshot) => {
                const uploadedCroppedImgUrl = await snapshot.ref.getDownloadURL();
                console.log("uploaded! signed url: ", uploadedCroppedImgUrl)
                this.setState({editedImgUrl: uploadedCroppedImgUrl,
                  uploadingCropped: false, updating: false, croppedImgBlog: null,
                  productInfoUpdated: true});

                // Analytics: image cropped
                analytics.track('Next clicked after cropping');
              });
            }}>
              Done
            </Button>
            <Button variant="outlined" onClick={(e) => {
              this.setState({cropping: false, croppedImgBlob: null});
                analytics.track('Crop cancelled');
            }}>
              Cancel
            </Button>
          </div>
      } else if (!curated) {
        buttonsComps =
          <div style={{display: "flex", flexDirection: "row", alignItems: "center",
            justifyContent: "space-between", width: "100%", marginTop: 20, paddingLeft: 5}}>
            <p style={{color: "rgba(0, 0, 0, 0.54)", fontSize: 16}}>Edit Image</p>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center",
              justifyContent: "space-between", width: "50%"}}>
              <IconButton className={classes.ctrlButton} title={"Crop"}
                onClick={(e) => {
                  this.setState({cropping: true});
                  analytics.track('Cropping started');
                }}>
                <Icon>
                  <img alt="crop-logo" src="/img/icons/crop.svg"/>
                </Icon>
              </IconButton>
              <IconButton className={classes.ctrlButton} title={"Remove Image Background"}
                onClick={async () => {
                  // Analytics
                  analytics.track('Remove bg button clicked');
  
                  this.setState({updating: true, removingBg: true});
                  let imgUrlToRmBg = this.state.editedImgUrl ? this.state.editedImgUrl : currItem.imgUrl;
  
                  // if imgUrl doesn't exist from previous step, this is a new item, switching to selImgUrl
                  if (!this.state.origUploaded && (!currItem || currItem.newItem)) {
                    const pastedImgLoc = 'images/temp/' + uuidv4() + ".png";
                    const pastedImgRef = firebase.storage().ref().child(pastedImgLoc);
                    const snapshot = await pastedImgRef.putString(this.pastedImgDataUrl, 'data_url');
                    const pastedImgUrl = await snapshot.ref.getDownloadURL();
                    console.log("uploaded! signed url: ", pastedImgUrl)
                    if (this.props.imagePasted) {
                      this.props.imagePasted(pastedImgUrl);
                    }
                    if (!imgUrlToRmBg) {
                      imgUrlToRmBg = pastedImgUrl;
                    }
                    this.setState({origUploaded: true});
                  }
  
                  cloudFunctionsFirebase.removeBackground(imgUrlToRmBg, (rmBgImgUrl, whiteBgUrl) => {
                    if (rmBgImgUrl) {
                      this.setState({removingBg: false, updating: false, editedImgUrl: rmBgImgUrl, whiteBgUrl, productInfoUpdated: true});
                      analytics.track('Remove bg success');
                    } else {
                      Swal.fire({
                        title: '',
                        text: 'We are unable to remove product image background...',
                        icon: 'warning',
                        confirmButtonColor: '#2F2CB3',
                        confirmButtonText: 'Ok',
                      });
  
                      analytics.track('Remove bg error and alert displayed');
                    }

                    this.setState({removingBg: false, updating: false});
                  });
                }}>
                <Icon>
                  <img alt="remove-bg-logo" src="/img/icons/remove-bg.svg"/>
                </Icon>
              </IconButton>
              <IconButton className={classes.ctrlButton} title={"Reset Image"} onClick={(e) => {
                analytics.track('Reset button clicked');
                this.setState({updating: true});
                cloudFunctionsFirebase.getImageSignedUrl(`ecommercelink/${currItem.Id}/image0.png`)
                  .then((result) => {
                    if (result.url) {
                      currItem.origImgUrl = result.url
                    }
                    this.setState({editedImgUrl: result.url, updating: false, productInfoUpdated: true});
                  });
                }}>
                <Icon>
                  <img alt="reset" src="/img/icons/reset.svg"/>
                </Icon>
              </IconButton>
            </div>
          </div>
      }
    }

    const chromeExtPrompt =  !currItem || currItem.newItem ?
      <div style={{width: "100%", height: 130, display: "flex", flexDirection: "column",
        alignItems: "center", justifyContent: "space-between", marginTop: 20}}>
        <Button style={{width: '100%', height: 67, borderRadius: 10, color: 'white', background: '#741CE5', lineHeight: "1.2"}}
          variant="contained" size='large'
          href={"https://chrome.google.com/webstore/detail/dabble/lnlgccgpbhgpnlgjignoencljjpoleca"}>
          <img alt="chrome-store-logo" style={{marginRight: 30, height: 55}} src="/img/icons/chrome-store.png"/>
          Get the Chrome Extension to clip products even faster
        </Button>
      </div> : null

    const urlTextValue = this.state.productURL !== undefined ?
      this.state.productURL : (currItem && currItem.URL !== undefined ? currItem.URL : "");
    const nameTextValue = this.state.productName !== undefined ?
      this.state.productName : (currItem && currItem.Title !== undefined? currItem.Title : "");
    const priceTextValue = this.state.price !== undefined ?
      this.state.price : (currItem && currItem.Price !== undefined ? currItem.Price : "");
    return (
      <div id="alternatives" style={{height: this.props.height}}>
        <div style={{width: "100%", height: "auto", display: "flex", justifyContent: "flex-end"}}>
          <IconButton aria-label="close"
            disabled={this.state.updating || this.state.deleting || this.state.pasting}
            onClick={(e) => {
              // turn on intro tour
              if (onClose) {
                onClose();
              }
              this.reset();
            }}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <div id="additionpanel" style={{overflowY: "auto", height: "100%", display: 'flex',
          flexDirection: "column", justifyContent: "flex-start", padding: 20}}>
          {itemImage}
          { currItem && !currItem.newItem ? buttonsComps : null}
          { currItem &&
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", width: "100%"}}>
              <form noValidate autoComplete="off"
                onSubmit={async (e) => {
                  e.preventDefault();
                  this.setState({updating: true, saving: true});
                  console.log("itemData", currItem);

                  // make cloud call to add a new product
                  const itemData = {selImgUrl: currItem.selImgUrl};

                  // if imgUrl doesn't exist from previous step, this is a new item, switching to selImgUrl
                  if (!this.state.origUploaded && (!currItem || currItem.newItem)) {
                    const pastedImgLoc = 'images/temp/' + uuidv4() + ".png";
                    const pastedImgRef = firebase.storage().ref().child(pastedImgLoc);
                    const snapshot = await pastedImgRef.putString(this.pastedImgDataUrl, 'data_url');
                    const pastedImgUrl = await snapshot.ref.getDownloadURL();
                    console.log("uploaded! signed url: ", pastedImgUrl)
                    if (this.props.imagePasted) {
                      this.props.imagePasted(pastedImgUrl);
                    }
                    itemData.selImgUrl = pastedImgUrl;
                    this.setState({origUploaded: true});
                  }

                  if (this.state.productName !== undefined) {
                    itemData.productName = this.state.productName;
                  }

                  if (this.state.productURL !== undefined) {
                    itemData.productURL = this.state.productURL;
                  }

                  if (this.state.price !== undefined) {
                    itemData.price = this.state.price;
                  }

                  if (currItem.newItem) {
                    if (this.state.editedImgUrl) {
                      itemData.imgUrl = this.state.editedImgUrl;
                    }

                    if (this.state.whiteBgUrl) {
                      itemData.whiteBgUrl = this.state.whiteBgUrl;
                    }
                  
                    cloudFunctionsFirebase.createNewItemFirestore(itemData, (result) => {
                      if (!result) {
                        Swal.fire('Oops', 'Failed to update item info', 'error');
                      } else {
                        analytics.track("New product added from product panel",
                          {"item id": currItem.Id});
                        if (this.props.updatedItemCallback) {
                          this.props.updatedItemCallback(currItem.Id, itemData);
                        }
                        if (onClose) {
                          onClose();
                        }
                      }
                      this.reset();
                    });
                  } else {
                    if (this.state.editedImgUrl) {
                      const imgFilename = "image1.png";
                      const itemStoragePath = 'images/ecommercelink/' + currItem.Id + '/' + imgFilename;
                      const res = await fetch(this.state.editedImgUrl);
                      let blob = await res.blob();
                      const fileRef = firebaseStorage.child(itemStoragePath);
                      const snapshot = await fileRef.put(blob);
                      currItem.imgUrl = await snapshot.ref.getDownloadURL();

                      itemData.imgUrl = currItem.imgUrl;
                    }

                    cloudFunctionsFirebase.updateItemInfo(currItem.Id, itemData, (result) => {
                      if (!result) {
                        Swal.fire('Oops', 'Failed to update item info', 'error');
                      } else {
                        analytics.track("Product info updated", {"item id": currItem.Id});
                        if (this.props.updatedItemCallback) {
                          this.props.updatedItemCallback(currItem.Id, itemData);
                        }
                      
                        if (onClose) {
                          onClose();
                        }
                      }

                      this.reset();
                    });
                  }
                }}>
                <div style={{display: "flex", width: "100%", paddingLeft: "10px",
                  flexDirection: "column", justifyContent: "space-between", paddingTop: 20}}>
                  <ProductTextField style={{height: "100%", fontSize: "12px"}}
                    id="produt-name" label={this.state.productName ? "Title" : "Add your title"}
                    value={nameTextValue} disabled={curated} fullWidth margin="dense"
                    onChange={(e) => {
                      this.setState({productName: e.target.value, productInfoUpdated: true});
                    }}/>
                  <div style={{display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between"}}>
                    <ProductTextField style={{height: "100%", fontSize: "12px"}} error={this.state.errProductURL} 
                      id="produt-url" label={this.state.productURL ? "Product link" : "Add your product link"}
                      value={urlTextValue} disabled={curated} fullWidth margin="dense"
                      helperText={this.state.errProductURL ? "Incorrect URL" : null}
                      onChange={(e) => {
                        this.setState({productURL: e.target.value});
                        if (this.validURL(e.target.value)) {
                          this.setState({productInfoUpdated: true, errProductURL: null});
                        } else {
                          this.setState({errProductURL: true});
                        }
                      }}/>
                    { currItem.URL ?
                      <IconButton target="_blank" href={currItem.URL}>
                        <Icon>link</Icon>
                      </IconButton> : null }
                  </div>
                  { currItem.Price || this.state.price !== undefined
                    ? <ProductTextField style={{height: "100%", fontSize: "12px"}} type="number"
                        id="produt-price" label="Add a product price" fullWidth margin="dense"
                        value={priceTextValue} disabled={curated}
                        onChange={(e) => {
                          this.setState({price: e.target.value, productInfoUpdated: true});
                        }}/>
                    : <div style={{display: "flex", justifyContent: "flex-start", paddingTop: 10}}>
                        <Button aria-label="addPrice" variant="contained"
                          className={classes.ctrlButton}
                          style={{boxShadow: "none", borderRadius: 10}}
                          onClick={(e) => {
                            this.setState({price: null})
                          }}>
                          Add a price
                        </Button>
                      </div>
                  }
                </div>
                <div style={{display: "flex", width: "100%", marginTop: "20px", flexDirection: "row",
                  justifyContent: currItem.newItem ? "flex-end" : "space-between"}}>
                  { this.props.handleFavItemClick && !currItem.newItem
                    ? <Button aria-label="favItem" className={classes.ctrlButton} variant="outlined" onClick={(e) => {
                        this.props.handleFavItemClick();
                        if (this.props.faved && onClose) {
                          this.reset();
                          onClose();
                        }
                      }}>
                        {
                          this.props.faved
                            ? <Icon style={{paddingRight: 10}}>delete_outlined</Icon>
                            : <Icon style={{paddingRight: 10}}>favorite_outlined</Icon>
                        }
                        {
                          this.props.faved
                            ? <p>Delete</p>
                            : <p>Wishlist</p>
                        }
                      </Button>
                    : null
                  }
                  { !curated ?
                    <ColorSaveButton
                      type="submit"
                      label="Save"
                      variant="contained"
                      style={{marginLeft: 10, paddingLeft: 10, paddingRight: 10, marginRight: 10}}
                      disabled={this.state.updating || this.state.cropping
                        || (!this.state.productInfoUpdated)}>
                      Save
                    </ColorSaveButton> : null
                  }
                  { !currItem.newItem ?
                    <ColorSaveButton
                      label="Add to Design"
                      variant="contained"
                      style={{marginLeft: 10, paddingLeft: 10, paddingRight: 10, marginRight: 10}}
                      disabled={this.state.updating || this.state.cropping
                        || this.state.productInfoUpdated}
                      onClick={(e) => {
                        if (typeof this.props.onAddToDesign === 'function') {
                          this.props.onAddToDesign(currItem);
                        }
                      }}>
                      Add to Design
                      <Icon>arrow_forward_ios</Icon>
                    </ColorSaveButton> : null
                  }
                </div>
              </form>
            </div>
          }
          {chromeExtPrompt}
        </div>
      </div>
    )
  }
}


export default withStyles(useStyles)(ProductInfoPanel);
