import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cloudFunctionsFirebase from "../CloudFunctionsFirebase.jsx";
import ProductInfoPanel from "./ProductInfoPanel.js";
import { ImageList, ImageListItem, ImageListItemBar, CircularProgress, Dialog, DialogContent,
  Button, Typography, Tab, Tabs, Icon, IconButton, Snackbar, Menu, MenuItem, Fade,
  Toolbar, Drawer } from "@material-ui/core";
import { isWidthUp } from "@material-ui/core/withWidth";
import { createTheme, useTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import '../index.sass';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/app';
import Navbar from "../layout/Navbar";
import Swal from 'sweetalert2';
import analytics from '../analytics/Analytics';
import clsx from 'clsx';

const db = firebase.firestore();
// if (window.location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }


const theme = createTheme({
  overrides: {
    MuiImageListItem: {
      item: {
        borderRadius: '10px',
        cursor: 'pointer',
        boxSizing: 'border-box',
      }
    },
    MuiImageListItemBar: {
      root: {
        backgroundColor: 'rgb(0, 0, 0, 0)',
      }
    },
    MuiTab: {
      root: {
        minWidth: 0,
        '@media (min-width: 600px)': {
          minWidth: 0,
        },
      },
      wrapper: {
        textTransform: 'none',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      }
    },
  }
});

const useStyles = theme => ({
  gridLabelDiv: {
    display: 'inline-flex',
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "10px",
  },
  overlay: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  favButton: {
    outline: 0,
  },
  icon: {
    color: 'rgba(255, 255, 255, 1.0)',
  },
  styleImg: {
    width: "100%",
    borderRadius: 10,
  },
  styleButton: {
    cursor: "pointer",
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'top',
    margin: 'auto',
    justifyContent: 'center',
    borderRadius: 10,
    width: '50%',
    maxWidth: '700px',
    minWidth: '600px'
  },
  modalButtonBar: {
    outline: 0,
    backgroundColor: "#fff",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  onboardDialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    width: "100%"
  },
  snackBar: {
    backgroundColor: "#CDCDCD",
    color: "#000"
  },
  paper: {
    outline: 0,
    padding: theme.spacing(0, 4, 2),
    overflowY:'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: '2px solid slategrey'
    }
  },
  addItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "220px",
    background: "#DDD",
    borderRadius: "10px",
    boxSizing: "border-box",
  },
  addCollection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    boxSizing: "border-box",
    background: "#DDD",
    borderRadius: "10px",
  },
  root: {
    outline: 0,
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '10px',
    paddingBottom: '10px',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: "#FFF",
  },
  chromeExtButton: {
    width: "100%",
    height: "50px",
    boxShadow: "none",
    color: "#FFFFFF",
    marginTop: "30px",
    backgroundColor: "#741CE5",
    '&:hover': {
      backgroundColor: '#5412A9'
    }
  },
  imgContainer: {
    flex: '1',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  img: {
    margin: 'auto',
    display: 'block',
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  itemTile: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0px',
    borderRadius: '12px',
    backgroundColor: "#FFFFFF",
    border: '1px solid',
    borderColor: '#BBB9B9',
    boxSizing: 'border-box',
  },
  itemInfo: {
    display:'flex',
    justifyContent: 'space-between',
    padding: "10px",
    fontSize: '12px',
  },
  drawer: {
    flexShrink: 0,
  },
  drawerOpen: {
    width: 420,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
  },
  line: {
    borderBottom: '1px solid',
    borderColor: '#BBB9B9',
    paddingTop: '5px',
  },
  tab: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  largeAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    color: 'black',
    backgroundColor: 'pink',
    fontSize: 24,
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}


function isIpadOS() {
  return navigator.maxTouchPoints &&
  navigator.maxTouchPoints > 2 //multi-touch usually means tablet 
}

const withMediaQuery = () => Component => props => {
  const isMobilePh = useMediaQuery('(max-width: 480px)');
  const isTabletOS = isIpadOS();

  const width = useWidth();
  return <Component isMobile={isMobilePh} isTablet={isTabletOS} width={width} {...props} />;
};

const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    borderRadius: "10px",
    boxShadow: "none",
    textTransform: "none",
    backgroundColor: "#741CE5",
    '&:hover': {
      backgroundColor: "#8473EE",
    }
  },
}))(Button);

class Favourites extends React.Component {
  state = {
    overlayOpen: true,
    showAddItemPanel: false,
    user: null,
    tabIndex: this.props.wishlist ? 0 : 1,
    snackBarOpen: false,
    showOnboarding: false,
    showResumeTut: false,
    showProductInfoPanel: false,
    onboardStep: 0,
  };

  constructor(props) {
    super(props);
    this.boardAspectRatio = 0.67;
    this.buttonRef = React.createRef();
    this.snackBarText = "";
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged);

    if (this.props.wishlist) {
      window.onscroll = this.handleScroll;
    }
  }

  getColImageListCols = () => {

    if (this.props.isMobile) {
      return 1;
    }

    if (this.props.isTablet) {
      return 4;
    }

    if (isWidthUp('xl', this.props.width)) {
      return 4;
    }

    if (isWidthUp('lg', this.props.width)) {
      return 3;
    }

    if (isWidthUp('md', this.props.width)) {
      return 3;
    }

    if (isWidthUp('sm', this.props.width)) {
      return 2;
    }

    return 1;
  }

  getItemImageListCols = () => {
    if (this.props.isMobile) {
      return 2;
    }

    if (this.props.isTablet) {
      return 4;
    }

    if (isWidthUp('xl', this.props.width)) {
      return 4;
    }

    if (isWidthUp('lg', this.props.width)) {
      return 4;
    }

    if (isWidthUp('md', this.props.width)) {
      return 4;
    }

    if (isWidthUp('sm', this.props.width)) {
      return 3;
    }

    return 2;
  }

  // handle authentication state changed
  onAuthStateChanged = (user) => {
    if (user) {
      this.setState({overlayOpen: false, user});

      const showOnboarding = window.localStorage.needOnboarding === "true";
      //const showResumeTut = window.localStorage.needOnboarding === "true" &&
      //  window.localStorage.sampleBoardId;
      const showResumeTut = false;
      if (showResumeTut) {
        const { sampleBoardId } = window.localStorage;

        // let's make sure that we actually have the same account to resume tutorial
        db.collection("collections/").doc(sampleBoardId).onSnapshot(async (doc) => {
          const sampleBoard = doc.data()
          const userDoc = await sampleBoard.User.get();
          if (userDoc.id !== user.uid) {
            window.localStorage.removeItem("needOnboarding");
            window.localStorage.removeItem("sampleBoardId");
          }
          else {
            this.setState({showResumeTut: true, showOnboarding: true})
            analytics.track("Turned on resume onboarding at home page");
          }
        });
      }
      else if (showOnboarding) {
        this.setState({showOnboarding: true})
        analytics.track("Turned on onboarding at home page");
      }

      db.collection("users/").doc(user.uid).onSnapshot(async (doc) => {
        const userData = doc.data();
        if (!userData) {
          return;
        }

        if (userData.profilePic) {
          userData.profilePicUrl = "https://us-central1-mixandmatch-287616.cloudfunctions.net/app/profilepic/" + user.uid;
        }

        this.setState({
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          userName: userData.userName,
          description: userData.description,
          profilePicUrl: userData.profilePicUrl,
        });
      });

      // add sub to db event, and update it when new items comes in
      db.collection("users/").doc(user.uid)
        .onSnapshot(async (doc) => {
          const userData = doc.data();
          if (!userData || !userData.bookmarks) {
            return;
          }

          this.setState({userData});

          // Adding user info to analytics to fix users who signed up before March 3, when the analytics fix was pushed.
          let userProfile = {
            "$email": userData.email,
            "$first_name": userData.firstName,
            "$last_name": userData.lastName,
            "USER_ID": user.uid
          };
          analytics.identify(String(user.uid));
          analytics.people.set(userProfile);

          const bookmarks = userData.bookmarks;
          Promise.all(Object.keys(bookmarks.items).map(async (itemId) => {
            let bmItem = db.collection('ecommercelink').doc(itemId);
            const ind = this.state.items? this.state.items.findIndex(item => item.Id === itemId) : -1;
            let newItem = null;
            if (ind < 0) {
              // new item found! Add that to the state
              newItem = (await bmItem.get()).data();
              if (!newItem) {
                console.log("item", itemId, " is no longer available");
                cloudFunctionsFirebase.removeBookmarkItemFirestore(itemId, () => {})
                return undefined;
              }
              newItem.Id = itemId;
              if (bookmarks.itemBookmarkDates && newItem.Id in bookmarks.itemBookmarkDates) {
                newItem.BookmarkedStamp = bookmarks.itemBookmarkDates[newItem.Id];
              }
              else {
                newItem.BookmarkedStamp = newItem.Created;
              }

              if (newItem.Vendor) {
                const vendorDoc = await newItem.Vendor.get();
                const vendorName = vendorDoc.data();
                if (vendorName && vendorName.Name !== "unsupported")  {
                  newItem.vendorName = vendorName.Name;
                }
                else {
                  newItem.vendorName = " ";
                }
              }
              else {
                newItem.vendorName = " ";
              }
            } else {
              newItem = this.state.items[ind];
            }

            if (!newItem.imgUrl) {
              const imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`ecommercelink/${newItem.Id}/image1.png`);
              if (!imgUrl.url) { //there's no bg removed image
                if (newItem.origImgUrl) { //if the original image exists, set it to that.
                  newItem.imgUrl = newItem.origImgUrl;
                }
                else { //no images available. show error image.
                  //Check imageprocessing status. Need to update from firebase because of weird firebase bug
                  const updatedItemListingRef = db.collection('ecommercelink').doc(itemId);
                  const updatedItemListing = (await updatedItemListingRef.get()).data();

                  if (updatedItemListing.ImageProcComplete === true) {
                    //image processing is complete but no images are available. show error.
                    newItem.imgUrl = '/img/noimg.jpg'
                    console.error ("No saved images found for id: " + newItem.Id + ". Please investigate!")
                  }
                }
              }
              else {
                newItem.imgUrl = imgUrl.url;
              }
            }

            return newItem;
          })).then((newItems) => {
            const filteredItems = newItems.filter(function (el) {
              return el !== undefined;
            });
            this.setState({items: filteredItems});
          });

          const colIds = Object.keys(bookmarks.collections);
          Promise.all(colIds.map(async (colId) => {
            const collection = {
              id: colId,
              bookmarkId: userData.bookmarks.moodboards[colId],
            };

            if (collection.bookmarkId) {
              let imgUrl;
              if (collection.bookmarkId.includes('jpg')) {
                imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`bookmarks/${collection.bookmarkId}`);
              }
              else {
                imgUrl = await cloudFunctionsFirebase.getImageSignedUrl(`bookmarks/${collection.bookmarkId}.png`);
              }
              collection.imgUrl = imgUrl.url;
            }

            return collection;
          })).then((collections) => {
            this.setState({collections});
          });
        });
    }
    else {
      const collections = [];
      const items = [];
      this.setState({
        collections,
        items,
        overlayOpen: false,
        user: null,
      });
      window.location.replace("/Login");
    }
  };

  handleScroll = () => {
    if (this.props.wishlist) {
      analytics.track("Wishlist scrolled at /Wishlist");
    }
  };

  handleProductPanelClose = (showsnackbar = false) => {
    analytics.track ("Product Panel Closed in Favorites")
    if (showsnackbar) {
      this.snackBarText = "Product changes saved!"
      this.setState({snackBarOpen: true});
    }

    this.setState({showProductInfoPanel: false, itemSelected: null});
  };

  handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackBarOpen: false
    })
  };

  handleAddItemPanelClose = () => {
    this.setState({showAddItemPanel : false});
  };

  onItemUpdated = (itemId, itemUpdatedData) => {
    const updatedItems = this.state.items.map((item) => {
      if (item.Id === itemId) {
        if (itemUpdatedData.productName !== undefined) {
          item.Title = itemUpdatedData.productName;
        }

        if (itemUpdatedData.productURL !== undefined) {
          item.URL = itemUpdatedData.productURL;
        }

        if (itemUpdatedData.price !== undefined) {
          item.Price = itemUpdatedData.price;
        }

        if (itemUpdatedData.type) {
          item.Depthtype = itemUpdatedData.type;
        }
      }
      return item;
    });
    this.setState({items: updatedItems});
  }

  onFavItemDeleted = (itemDeleted) => {
    const items = this.state.items.map((item => item));
    const itemIdx = this.state.items.findIndex(
      item => item.Id === itemDeleted.Id);
    if (itemIdx >= 0) {
      items.splice(itemIdx, 1);
    }
    this.setState({items, showProductInfoPanel: false});
  }

  onDuplicateCollection = () => {
    const collection = this.state.collectionForMenu;
    console.log("duplicating bookmarked collection: ", collection.id);
    cloudFunctionsFirebase.duplicateCollectionFirestore(collection.id,
      async (result) => {
        if (result) {
          // Analytics Event: new collection created
          analytics.track('Collection duplicated', {
            "original collection id": collection.id,
            "new collection id": result.newCollectionId
          });
        } else {
          Swal.fire('Failed to duplicate new collection', '', 'error');
        }
      }
    );
    this.setState({colMenuAnchor: null, collectionForMenu: null});
  }

  onDeleteCollection = () => {
    const colToDelete = this.state.collectionForMenu;
    this.setState({colMenuAnchor: null, collectionForMenu: null});
    Swal.fire({
      title: "Delete?",
      text: "Are you sure you want to delete this board?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2F2CB3',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("removing bookmarked collection: ", colToDelete.id);
        analytics.track('Deleted collection from home page', {
          "collection id": colToDelete.id,
        });
        const { collections } = this.state;
        const indToDelete = collections.findIndex(
          collection => collection.id === colToDelete.id);
        if (indToDelete < 0) {
          Swal.fire({title: "Oops!", icon: "error", text: "Collection doesn't exists??"});
          return;
        }

        collections.splice(indToDelete, 1);
        this.setState({collections});
        cloudFunctionsFirebase.removeBookmarkCollectionFirestore(
          colToDelete.id,
          (data) => {
            if (data) {
              console.log("removed bookmarked collection");
            }
          }
        );
      }
    });
  }

  handleFilterClose = () => {
    this.setState({anchorEl: null});
  };

  createSampleBoard(roomType) {
    const roomType2Items = {
      livingroom: [
        "oH2ky1Y0gI5UCKyj4qzn",
        "RVepF7Ty18tzsqgCSnG5",
        "3lKAp9sDwkELPAY9gdbn",
        "7q8r1AG2W65J5uhcj79J",
        "aim3lUpsWONmxjvRWJx9",
        "Of6UiU4VXUeTKii5wOsB",
        "i23bHYniFTZIKOBU2be1",
        "xG8Gv02fdcezJ0nsved6",
        "JSi71TU8ZAgMplK0Z0XM",
        "yuKaizxA0BlXoUhqVAiJ",
      ],
      bedroom: [
        "6jt00fyFys2Lo6hRxwUo",
        "TLuZwYBVFWRpgASUR8ZY",
        "uFx2aZE9jjknclyRtYpq",
        "PMfy2MpCi9KWy331E76e",
        "Kwh8GkvdBOBrSwGz0de9",
        "mk9EYceVmriQHQ73p8Uj",
      ],
      readingnook: [
        "NgylRurozslClLeuqKZk",
        "TLuZwYBVFWRpgASUR8ZY",
        "gRorY3AgN4O0xMzzmFDW",
        "Xyd2Mgwyg9iXXJev5NZv",
        "h8nCtdaWqLu60SXexwY5",
        "VBwDCk6dRYrke8IWsTNa",
        "Z3b0A5mDqBKB6OvpTaW1",
      ],
      office: [
        "oH2ky1Y0gI5UCKyj4qzn",
        "RVepF7Ty18tzsqgCSnG5",
        "TlXoHqcghVZvgwkstjql",
        "Tl50E8yBhn3Iw6oaYlDt",
        "XzeqWa8Wqd0ISM7AlypJ",
        "JSi71TU8ZAgMplK0Z0XM",
        "ZWGVrKXZC2ygsmhmpvkG",
      ],
    };

    const roomType2Samples = {
      livingroom: [
        "7q8r1AG2W65J5uhcj79J",
        "3lKAp9sDwkELPAY9gdbn",
      ],
      bedroom: [
        "PMfy2MpCi9KWy331E76e",
        "TLuZwYBVFWRpgASUR8ZY",
      ],
      readingnook: [
        "h8nCtdaWqLu60SXexwY5",
        "TLuZwYBVFWRpgASUR8ZY",
      ],
      office: [
        "Tl50E8yBhn3Iw6oaYlDt",
        "TlXoHqcghVZvgwkstjql",
      ],
    };

    if (!(roomType in roomType2Items)) {
      return;
    }

    const collection = {
      name: "First Design",
      type: roomType,
      items: [],
    };

    // add items to wishlist
    const itemsToAdd = roomType2Items[roomType];
    Promise.all(itemsToAdd.map(itemId => {
      try {
        const itemRef = db.collection('ecommercelink').doc(itemId);
        const updateDict = {};
        updateDict["bookmarks.items." + itemId] = itemRef;
        return db.collection('users').doc(this.state.user.uid).update(updateDict);
      } catch(err) {
        console.log("Error in bookmarkItem", err);
        return null;
      }
    })).then(() => {
      cloudFunctionsFirebase.createNewCollectionFirestore(collection,
        "FFFFFF", "hardwood_4", (result) => {
          if (result) {
            analytics.track("Created sampleboard in collection", {
              "roomType": roomType,
            });
            window.localStorage.sampleBoardId = result.newCollectionId;
            window.localStorage.sampleRoomType = roomType;
            window.localStorage.sampleItem1 = roomType2Samples[roomType][0];
            window.localStorage.sampleItem2 = roomType2Samples[roomType][1];
            this.props.history.push("/design/" + result.newCollectionId);
            this.setState({isOnboardingOpen: false});
          } else {
            Swal.fire('Failed to create new collection', '', 'error');
          }
        }
      );
    });
  }

  favItem (item) {
    if (!(item.Id in this.state.userData.bookmarks.items)) {
      analytics.track("Added product to wishlist", {
        'clicksource': 'home page',
        "URL": item.URL,
        "item vendor": item.vendorName,
        "item type": item.Depthtype
      });
      cloudFunctionsFirebase.bookmarkItemFirestore(item.Id, (result) => {
        if (!result) {
          Swal.fire('Failed to bookmark item', '', 'error');
        }
      })
      this.snackBarText = "Added to Wishlist!";
    } else {
      analytics.track("Removed product from wishlist", {
        'clicksource': 'home page',
        "URL": item.URL,
        "item vendor": item.vendorName,
        "item type": item.Depthtype
      });
      cloudFunctionsFirebase.removeBookmarkItemFirestore(item.Id, (result) => {
        if (!result) {
          Swal.fire('Failed to remove bookmark item', '', 'error');
        }
      })
      this.snackBarText = "Removed from Wishlist";
    }
  }

  render() {
    if (!this.state) {
      return null;
    }

    const { classes } = this.props;
    if (this.state.overlayOpen) {
      return (
        <Dialog fullScreen open={this.state.overlayOpen}>
          <div className={classes.overlay}>
            <img src="/img/dabble_logo.svg" height="40px" alt="desktop_logo"
              style={{objectFit: "contain", marginBottom: "20px"}}/>
            <CircularProgress color="inherit" />
          </div>
        </Dialog>
      );
    }

    if (!this.state.user) {
      return null;
    }

    const { collections, items } = this.state;

    //let avatar;
    //if (this.state.profilePicUrl) {
    //  avatar = <Avatar className={classes.largeAvatar} src={this.state.profilePicUrl}/>;
    //}
    //else if (this.state.email) {
    //  avatar = <Avatar className={classes.largeAvatar} src={'/img/default_avatar.jpg'}/>;
    //}
    //else {
    //  avatar = <Avatar className={classes.largeAvatar}>
    //    <CircularProgress disableShrink />
    //  </Avatar>;
    //}

    let favColDoms = []
    if (!collections) {
      favColDoms.push(
        <ImageListItem key="nofavitem" cols={5} style={{ height: 'auto' }}>
          <div className={classes.gridLabelDiv}>
            <Typography gutterBottom variant="body1">
              Loading...
            </Typography>
          </div>
        </ImageListItem>
      );
    }
    else if (collections.length >= 0) {
      collections.sort(function (a, b) {
        return ('' + a.id).localeCompare(b.id);
      });

      collections.forEach((collection, index) => {
        favColDoms.push(
          <ImageListItem key={collection.id} className={classes.favButton}
            onClick={() => {
              this.props.history.push("/design/" + collection.id);
              analytics.track('Collection opened from home page', {
                "collection id": collection.id,
              });
            }}>
            { !collection.imgUrl ?
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <CircularProgress disableShrink />
              </div> :
              <img src={collection.imgUrl} loading="lazy" alt="collection img"/>}
            <ImageListItemBar
              position="top"
              actionIcon={
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({colMenuAnchor: e.currentTarget, collectionForMenu: collection});
                  }}
                >
                  <Icon>more_vert</Icon>
                </IconButton>
              }
            />
          </ImageListItem>
        );
      })
    }

    let favItemsDoms = [];
    if (items) {
      items.sort(function (a, b) {
        return b.BookmarkedStamp - a.BookmarkedStamp;
      });

      let itemsToRender = items;
      if (this.state.selectedDepthType) {
        const currDepthType = this.state.selectedDepthType
        itemsToRender = itemsToRender.filter(function(e) {
          return (e.Depthtype === currDepthType)
        })
      }

      itemsToRender.forEach((item, index) => {
        favItemsDoms.push(
          <ImageListItem key={item.Id} className={classes.favButton}
            onClick={() => {
              this.setState({itemSelected: item, showProductInfoPanel: true});
              analytics.track_item_info_click('home page tab', item.URL, item.vendorName, item.Depthtype)
            }}
            onMouseEnter={(e) => {
              this.container.style.cursor = 'pointer';
            }}
            onMouseLeave={(e) => {
              this.container.style.cursor = 'default';
            }}>
            <div className={classes.itemTile}>
              <div className={classes.imgContainer}>
                { !item.imgUrl ?
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                    <CircularProgress disableShrink />
                  </div> :
                  <img className={classes.img} src={item.imgUrl} alt={item.Title} loading="lazy"/>
                }
              </div>
              <div className={classes.line}/>
              <div className={classes.itemInfo}>
                <div>{item.vendorName}</div>
                <div style={{fontWeight: 'bold'}}>${item.Price ? item.Price : "-"}</div>
              </div>
            </div>
          </ImageListItem>
        );
      })
    } else {
      favItemsDoms.push(
        <ImageListItem key="nofavitem" cols={6} style={{ height: 'auto' }}>
          <div className={classes.gridLabelDiv}>
            <Typography gutterBottom variant="body1">
              Loading...
            </Typography>
          </div>
        </ImageListItem>
      );
    }

    let onboardDialogContent = null;
    if (this.state.onboardStep === 0) {
      onboardDialogContent = (
        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
          <div style={{display: 'flex', width: '40%', justifyContent: 'flex-start', flexDirection: 'column',
            backgroundColor: "#741CE5", padding: 30}}>
            <h1 style={{color: "white", marginTop: 0, marginBottom: 0, fontSize: '45px'}}>Welcome to Dabble!</h1>
            <p style={{color: "white", marginTop: '20px', fontSize: '18px'}}>
              Ready to see how fun and easy it is to design a room with Dabble?
            </p>
          </div>
          <div style={{display: 'flex', width: '70%', justifyContent: 'space-between', flexDirection: 'column', padding: "0px 30px 30px 30px"}}>
            <img className={this.props.classes.styleImg}
              style={{marginTop: 30, marginBottom: 30}}
              src={'/img/livingRoom.jpg'}
              alt={'living_room_button_img'}/>
            <ColorButton onClick={() => this.setState({onboardStep: 1})}><span style={{fontSize: '18px'}}><strong> Let's Go! </strong></span></ColorButton>
          </div>
        </div>);
    } else if (this.state.onboardStep === 1) {
      onboardDialogContent = (
        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
          <div style={{display: 'flex', padding: 30, justifyContent: 'space-between', flexDirection: 'row'}}>
            <div style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', padding: 5}}>
              <h1 style={{marginTop: 0, marginBottom: 0}}>Let's start with an empty-ish living room</h1>
              <p style={{marginTop: "20px", fontSize: '18px'}}>
                You've just moved and all you have is a grey couch. Let's design a living room around it!
              </p>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', padding: 5}}>
              <img className={this.props.classes.styleImg}
                src={'/img/emptyroomwithwindow.jpg'}
                alt={'living_room_img'}/>
            </div>
          </div>
          <ColorButton style={{marginTop: 5, marginLeft: 30, marginRight: 30, marginBottom: 30}} onClick={() => this.createSampleBoard('livingroom')}>
            <span style={{fontSize: '18px'}}><strong>Next, Create a New Design</strong></span>
          </ColorButton>
        </div>);
    }

    if (this.state.showResumeTut) {
      onboardDialogContent = (
        <div style={{width: "90%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <h2 style={{marginTop: 0, marginBottom: 10}}>Resume your Dabble Tutorial?</h2>
          <ColorButton style={{marginTop: 10, marginBottom: 10}}
            href={"/design/" + window.localStorage.sampleBoardId}
            onClick={(e) => {
              analytics.track("Resumed tutorial from home page", {
                "sampleBoardId": window.localStorage.sampleBoardId,
              });
            }}>
            Resume
          </ColorButton>
        </div>
      );
    }

    let useWidth = true
    if (this.props.isTablet) { 
      if(window.innerHeight < window.innerWidth){ //landscape mode, use tablet height
        useWidth = false
      }
    }

    const collectionGridCol = this.getColImageListCols();
    const itemGridCol = this.getItemImageListCols();

    const screenWidth = useWidth ? window.screen.width : window.screen.height
    const colCellWidth = this.props.isMobile ? screenWidth * 0.7 : screenWidth * 0.7 / 4;
    const colGridWidth = colCellWidth * collectionGridCol;
    const colGridCellHeight = colCellWidth * this.boardAspectRatio;

    const itemCellWidth = this.props.isTablet? colCellWidth : (this.props.isMobile ? screenWidth * 0.4 : screenWidth * 0.7 / 6); //use collection cell width if its a tablet (looks better), otherwise check if mobile and set width accordingly
    const itemGridWidth = itemCellWidth * itemGridCol;

    return (
      <ThemeProvider theme={theme}>
        <div id="container" ref={node => {
          this.container = node;
        }}>
          <Navbar onTutorialClicked={() => {
              this.setState({showOnboarding: true});
            }}
          />
          <div style={{width: "80%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: this.state.showProductInfoPanel,
                [classes.drawerClose]: !this.state.showProductInfoPanel,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: this.state.showProductInfoPanel,
                  [classes.drawerClose]: !this.state.showProductInfoPanel,
                }),
              }}
              >
              <Toolbar disableGutters={true}/>
              { this.state.showProductInfoPanel ?
                <ProductInfoPanel onClose={() => this.handleProductPanelClose(false)}
                  handleFavItemClick={() => this.favItem(this.state.itemSelected)}
                  owner={this.state.user.uid}
                  faved={this.state.itemSelected ?
                    this.state.itemSelected.Id in this.state.userData.bookmarks.items : false}
                  updatedItemCallback={this.onItemUpdated}
                  imagePasted={(dataURL) => {
                    let { itemSelected } = this.state;

                    if (!itemSelected) {
                      itemSelected = {};
                    }

                    itemSelected.newItem = true;
                    itemSelected.selImgUrl = dataURL;
                    this.setState({itemSelected})
                  }}
                  currItem={this.state.itemSelected}/> : null
              }
            </Drawer>
            <Dialog fullScreen open={this.state.overlayOpen}>
              <div className={classes.overlay}>
                <img src="/img/dabble_logo.svg" height="40px" alt="desktop_logo" style={{objectFit: "contain", marginBottom: "20px"}}/>
                <CircularProgress color="inherit" />
              </div>
            </Dialog>
            <Dialog
              aria-labelledby="start-firstboard-dialog"
              classes={{paper: classes.modal}}
              open={this.state.showOnboarding}>
              <DialogContent classes={{root: classes.onboardDialog}} style={{paddingTop: 0}}>
                {onboardDialogContent}
              </DialogContent>
            </Dialog>
            {/*
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 20, height: 130}}>
              {avatar}
              {
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    { this.state.userName
                      ? <Typography variant="body2" style={{color: "#858585"}}>{"@" + this.state.userName}</Typography>
                      : null
                    }
                    <IconButton href='/Profile'>
                      <Icon style={{fontSize: 18}}>edit</Icon>
                    </IconButton>
                  </div>
                </div>
              }
            </div>
            */}
            <Tabs
              style={{display: 'none'}}
              value={this.state.tabIndex}
              onChange={(event, newValue) => {
                this.setState({tabIndex: newValue});
                if (newValue === 1) {
                  window.history.pushState({"pageTitle":""}, "", "/");
                }
                else if (newValue === 0) {
                  window.history.pushState({"pageTitle":""}, "", "/Wishlist");
                }
              }}
              indicatorColor="primary"
              centered
            >
              <Tab label="Wishlist"/>
              <Tab label="Designs"/>
            </Tabs>
            <TabPanel value={this.state.tabIndex} index={1} className={classes.tab}>
              <div style={{alignItems: "center", marginTop: 10,  display: 'flex',
                justifyContent: "flex-start", flexDirection: "row"}}>
                <h2 style={{marginRight: 20}}>Your Designs</h2>
                <Button variant="contained"
                  ref={this.buttonRef}
                  style={{borderRadius: 10, textTransform: 'none', boxShadow: 'none'}}
                  onClick={(e) => {
                    const collection = {
                      name: uuidv4(),
                      type: "livingroom",
                      items: [],
                    };
                    return cloudFunctionsFirebase.createNewCollectionFirestore(collection,
                      "FFFFFF", "hardwood_4", (result) => {
                        if (result) {
                          // Analytics Event: new collection created
                          analytics.track('New collection created', {
                            "collection id": result.newCollectionId
                          });

                          this.props.history.push("/design/" + result.newCollectionId);
                          return true;
                        } else {
                          Swal.fire('Failed to create new collection', '', 'error');
                        }
                      }
                    );
                  }}
                  startIcon={<Icon>add</Icon>}>
                    New Design
                </Button>
              </div>
              <ImageList style={{width: colGridWidth, overflowY: "unset", marginTop: "30px"}}
                rowHeight={colGridCellHeight} cols={collectionGridCol} gap={15}>
                {favColDoms}
              </ImageList>
            </TabPanel>
            <TabPanel value={this.state.tabIndex} index={0} className={classes.tab}>
              <div className="filterpanel" style={{alignItems: "center", marginTop: 10,  display: 'flex',
                justifyContent: "flex-start", flexDirection: "row"}}>
                <h2 style={{marginRight: 20}}>Your Saved Items</h2>
                <Button variant="contained"
                  ref={this.buttonRef}
                  style={{borderRadius: 10, textTransform: 'none', boxShadow: 'none'}}
                  onClick={(event) => {
                    analytics.track("Add Item Panel opened")
                    this.setState({showProductInfoPanel: true});
                  }}
                  startIcon={<Icon>add</Icon>}>
                    New Item
                </Button>
                {/*
                <Button variant="contained"
                  ref={this.buttonRef}
                  style={{color: "white", backgroundColor: "black", borderRadius: "10px", textTransform: 'none'}}
                  onClick={(event) => {
                    this.setState({anchorEl: this.buttonRef.current});
                  }}
                  startIcon={
                    <Icon>
                      <img src="/img/icons/filters.svg"/>
                    </Icon>
                  }>
                    Filters
                </Button>
                <Popover
                  open={Boolean(this.state.anchorEl)}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleFilterClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}>
                    <List>
                      {listItems}
                    </List>
                </Popover>
                */}
              </div>
              <ImageList style={{width: itemGridWidth, overflowY: "unset", marginTop: "30px"}}
                rowHeight={220} cols={itemGridCol} gap={15}>
                {favItemsDoms}
              </ImageList>
            </TabPanel>
            <Menu
              id="col-control-menu"
              anchorEl={this.state.colMenuAnchor}
              keepMounted
              open={Boolean(this.state.colMenuAnchor)}
              TransitionComponent={Fade}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={() => {this.setState({colMenuAnchor: null})}}
            >
              <MenuItem key={"duplicate"} selected={false} onClick={() => {
                this.onDuplicateCollection(this.state.collectionForMenu)}
              }>
                Duplicate
              </MenuItem>
              <MenuItem key={"delete"} selected={false} onClick={() => {
                this.onDeleteCollection(collections, this.state.collectionForMenu)}
              }>
                Delete
              </MenuItem>
            </Menu>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackBarOpen}
          onClose={this.handleSnackBarClose}
          autoHideDuration={3000}
          ContentProps={{
            classes: {
              root: classes.snackBar
            }
          }}
          message= {this.snackBarText}
        />
      </ThemeProvider>
    );
  }
}

export default withStyles(useStyles)(withMediaQuery()(Favourites));
